// export default {
//   path: 'commerce',
//   name: 'commerce',
//   component: () => import(/* webpackChunkName: "category-list" */ '@/pages/commerce/index'),
//   meta: {title:'电商绩效'}
// }

export default {
  path: 'ecp',
  name: 'ecp',
  component: () => import('@/pages/commerce/index'),
  meta: { title: '电商绩效' },
  children: [
    {
      path: 'list',
      name: 'ecp_list',
      component: () => import('@/pages/commerce/commerceList'),
      meta: { title: '电商绩效列表' }
    },
    {
      path: 'performance',
      name: 'ecp_performance',
      component: () => import('@/pages/commerce/performance/index'),
      meta: { title: '绩效目标一览' }
    },
    {
      path: 'bonus',
      name: 'ecp_bonus',
      component: () => import('@/pages/commerce/bonus/index'),
      meta: { title: '奖金分配' }
    },
    {
      path: 'kol_mapper',
      name: 'ecp_kol',
      component: () => import('@/pages/commerce/kol_mapper/KolMapperList'),
      meta: { title: '红人关联' }
    },
  ]
}

