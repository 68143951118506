export default {
    path: 'business',
    name: 'business',
    component: () => import('@/pages/business_data/Index'),
    meta: { title: '电商数据' },
    children: [
        {
            path: 'live_data',
            name: 'live_data',
            component: () => import('@/pages/live_data/LiveDataList'),
            meta: { title: '直播(达播)数据' },
        },
        {
            path: 'live_data_delegate',
            name: 'live_data_delegate',
            component: () => import('@/pages/live_data/LiveDelegateDataList'),
            meta: { title: '直播(日播)数据' },
        },
        // {
        //     path: 'shop_data',
        //     name: 'shop_data',
        //     component: () => import('@/pages/shop_data/ShopDataStatistics'),
        //     meta: { title: '店铺数据' },
        // },
        {
            path: 'day_summary',
            name: 'day_summary',
            component: () => import('@/pages/business_data/summary/DaySummary'),
            meta: { title: '每日汇总' },
        },
        {
            path: 'month_summary',
            name: 'month_summary',
            component: () => import('@/pages/business_data/summary/MonthSummary'),
            meta: { title: '月度汇总' },
        },
        {
            path: 'live_setting_department',
            name: 'live_setting_department',
            component: () => import('@/pages/live_data/LiveSetting.vue'),
            meta: { title: '设置' },
        },
    ]
}
