export default {
  path: 'live_funding',
  component: () => import('@/pages/live_funding/index'),
  meta: { title: '直播投放金' },
  children: [
    {
      path: ':id/live_funding',
      name: 'live_funding_import',
      component: () => import('@/pages/live_funding/importTable.vue'),
      meta: { title: '直播投放金导入列表' }
    }
  ]
}

