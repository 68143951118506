import * as request from '@/utils/request'

/**
 * 自动生成 api代码【模块名：库存】
 * 生成日期：2024/04/25 15:13:30
 * 生成路径: src/api/product_qty.js
 * 生成人：超级管理员
 * 需要在文件src/api/index.js 引入当前文件
 * import product_qty from './product_qty'
 * export default {
 * ...
 * // 库存
 *    ...product_qty,
 * }
 */

export default {
  /**
   * @description 获取列表
   * @param {*} data
   * @returns
   */
  getProductQtyList(data) {
    return request.post('/product_qty/list', data)
  },
  /**
   * @description 保存
   * @param {*} data
   * @returns
   */
  saveProductQty(data) {
    return request.post('/product_qty/store', data)
  },
  /**
   * @description 获取详情
   * @param {*} id
   * @returns
   */
  getProductQtyInfo(id) {
    let data = { id: id }
    return request.post('/product_qty/get_info', data)
  },
  /**
   * @description 根据id删除单条信息
   * @param {*} data
   * @returns
   */
  deleteProductQty(id) {
    let data = { id: id }
    return request.post('/product_qty/delete', data)
  },
  /**
   * 导入
   * @param data
   * @returns {Promise}
   */
  importProductQtys(data) {
    return request.post('/product_qty/import', data)
  },
  /**
   * 导出
   * @param data
   * @returns {Promise}
   */
  exportProductQtys(data) {
    return request.postBlob('/product_qty/export', data)
  },

}