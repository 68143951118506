import * as request from '@/utils/request'

export default {
    /**
     * @description 获取列表
     * @param {*} data
     * @returns
     */
    getUploadFileList(data) {
        return request.post('/import_record/list', data)
    },
    /**
     * 导出-文件下载
     * @returns {Promise}
     * @param fileId
     */
    exportUploadFileList(fileId) {
        let data = { id: fileId }
        return request.postBlob('/import_record/download', data)
    },

}
