import * as request from '@/utils/request'

export default {
  /**
   * @description 账号结余列表
   * @param {*} data
   * @returns
   */
  getBalanceList(data) {
    return request.post('/platform_balance/list', data)
  },
  /**
   *查看平台数据录入进度
   * @param data
   * @returns {Promise}
   */
  getBalanceCheckList(data) {
    return request.post('/platform_balance/check_list', data)
  },
  /**
   * 导出平台数据录入进度
   * @param data
   * @returns {Promise}
   */
  exportBalanceCheckList(data) {
    return request.postBlob('/platform_balance/export_check_list', data)
  },
  /**
   * 导出结余记录-excel
   * @param data
   * @returns {Promise}
   */
  exportBalanceExcel(data) {
    return request.postBlob('/platform_balance/export', data)
  },
  /**
   * @description 获取账号结余信息
   * @param id
   * @returns
   */
  getBalanceInfo(id) {
    let data = { id: id }
    return request.post('/platform_balance/get_info', data)
  },

  /**
   * @description 保存结余信息
   * @param {*} data
   * @returns
   */
  storeBalanceInfo(data) {
    return request.post('/platform_balance/store', data)
  },

  /**
   * @description 删除结余信息
   * @param id
   * @returns
   */
  delBalanceInfo(id) {
    let data = { id: id }
    return request.post('/platform_balance/delete', data)
  },

  /**
   * @description 恢复已软删结余信息记录
   * @param {*} ids
   * @returns
   */
  restoreBalanceInfo(ids) {
    let data = { ids: ids }
    return request.post('/platform_balance/restore', data)
  }
}