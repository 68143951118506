import * as request from '@/utils/request'

export default {
    /**
     * 获取红人
     * @returns
     */
    laborGetKolList(data) {
        return request.post('/kol_analy_labor_cost/list', data)
    },
    /**
     * 修改数据
     * @returns
     */
    laborEdit(data) {
        return request.post('/kol_analy_labor_cost/save', data)
    },
    /**
     * 修改数据
     * @returns
     */
    laborDel(data) {
        return request.post('/kol_analy_labor_cost/delete', data)
    },
    /**
     * 获取导入列表
     * @returns
     */
    laborgetImportList(data) {
        return request.post('/kol_analy_labor_cost/list', data)
    },
    /**
     * 撤回导入列表
     * @returns
     */
    laborDelImport(data) {
        return request.post('/kol_analy_labor_cost/delete_import', data)
    },

}
