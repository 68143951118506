import * as request from '@/utils/request'

export default {
  /**
   * @description 获取【涨粉】绩效数据
   * @param {*} data
   * @returns
   */
  getPerformanceTrendList(data) {
    return request.post('/performance_trend/list', data)
  },
  /**
   * @description 获取【涨粉】绩效目标一览季度数据
   * @param {*} data
   * @returns
   */
  getPerformanceTrendQuarterData(data) {
    return request.post('/performance_trend/get_quarter_data', data)
  },
  /**
   * @description 团队-获取【涨粉】绩效目标一览季度数据
   * @param {*} data
   * @returns
   */
  getPerformanceQuarterTrend(data) {
    return request.post('/performance_trend/quarter_trend_data', data)
  },
  /**
   * @description 团队-获取【涨粉情况一览】
   * @param {*} data
   * @returns
   */
  getPerformanceRrendGroupList(data) {
    return request.post('/trend_datum/trend_group_list', data)
  },
  /**
   * @description 团队-获取【涨粉情况一览-获取团队信息】
   * @param {*} data
   * @returns
   */
  trendDatumList(data) {
    return request.post('/trend_datum/group_list', data)
  },
  /**
   * @description 团队-获取【营收情况一览】
   * @param {*} data
   * @returns
   */
  getRevenueList(data) {
    return request.post('/revenue_item/revenue_list', data)
  },
  /**
   * @description 团队-获取【营收情况一览-获取团队信息】
   * @param {*} data
   * @returns
   */
  revenueDatumList(data) {
    return request.post('/revenue_item/group_list', data)
  },
  /**
   * @description 团队-获取【组内季度涨粉排名】
   * @param {*} data
   * @returns
   */
  getTrendQuarterArtist(data) {
    return request.post('/trend_datum/quarter_artist_trend', data)
  },
  /**
   * @description 团队-获取【组内季度营收排名】
   * @param {*} data
   * @returns
   */
  getRevenueQuarterArtist(data) {
    return request.post('/revenue_item/quarter_artist_revenue', data)
  },
  //
  /**
   * @description 获取【涨粉】绩效目标的操作日志
   * @param {*} data
   * @returns
   */
  getPerformanceTrendLog(data) {
    return request.post('/performance_trend/log_list', data)
  },
  /**
   * @description 获取【某个红人】【涨粉】绩效目标一览季度数据
   * @param {*} data
   * @returns
   */
  getPerformanceTrendArtistData(data) {
    return request.post('/performance_trend/get_origin_data', data)
  },

  /**
   * @description 保存：一条【涨粉】绩效目标一览季度数据
   * @param {*} data
   * @returns
   */
  saveAPerformanceTrend(data) {
    return request.post('/performance_trend/save_performance_trend', data)
  },
  /**
   * @description 保存：一条【涨粉】绩效目标一览季度数据
   * @param {*} data
   * @returns
   */
  publishPerformanceTrend(data) {
    return request.post('/performance_trend/publish', data)
  },
  /**
   * 导出(当前组)：涨粉绩效
   * @param data
   * @returns {Promise}
   */
  exportPerformanceBill(data) {
    return request.postBlob('/performance_trend/export', data)
  },
  /**
   * 导出（全部组）：涨粉绩效
   * @param data
   * @returns {Promise}
   */
  exportPerformanceBillAll(data) {
    return request.post('/performance_trend/export_all', data)
  },
  /**
   * @description 获取【营收】绩效目标一览季度数据
   * @param {*} data
   * @returns
   */
  getPerformanceRevenueList(data) {
    return request.post('/performance_revenue/list', data)
  },
  /**
   * @description 获取【营收】绩效目标一览季度数据
   * @param {*} data
   * @returns
   */
  getPerformanceRevenueQuarterData(data) {
    return request.post('/performance_revenue/get_quarter_data', data)
  },
  /**
   * @description 获取【营收】绩效目标的操作日志
   * @param {*} data
   * @returns
   */
  getPerformanceRevenueLog(data) {
    return request.post('/performance_revenue/log_list', data)
  },
  /**
   * @description 获取【某个红人】【营收】绩效目标一览季度数据
   * @param {*} data
   * @returns
   */
  getPerformanceRevenueArtistData(data) {
    return request.post('/performance_revenue/get_origin_data', data)
  },
  /**
   * @description 保存：一条【涨粉】绩效目标一览季度数据
   * @param {*} data
   * @returns
   */
  saveAPerformanceRevenue(data) {
    return request.post('/performance_revenue/save_performance_revenue', data)
  },
  /**
   * @description 保存：一条【涨粉】绩效目标一览季度数据
   * @param {*} data
   * @returns
   */
  publishPerformanceRevenue(data) {
    return request.post('/performance_revenue/publish', data)
  },
  /**
   * 导出：营收绩效
   * @param data
   * @returns {Promise}
   */
  exportPerformanceRevenueBill(data) {
    return request.postBlob('/performance_revenue/export', data)
  },
  /**
   * 导出（全部组）：营收绩效
   * @param data
   * @returns {Promise}
   */
  exportPerformanceRevenueBillAll(data) {
    return request.post('/performance_revenue/export_all', data)
  },
  /**
   * 营收的分组列表
   * @param data
   * @returns {Promise}
   */
  getGroupListOfPerformanceRevenue(data) {
    return request.post('/performance_revenue/group_list', data)
  },
  /**
   * 营收绩效-历史数据
   * @param data
   * @returns {Promise}
   */
  getHistoryListOfPerformanceRevenue(data) {
    return request.post('/performance_revenue/history_list', data)
  },
  /**
   * 季度月均营收值
   * @param data
   * @returns {Promise}
   */
  getQuarterAgvOfPerformanceRevenue(data) {
    return request.post('/performance_revenue/quarter_agv', data)
  },
  /**
   *初始化-奖金数据
   * @param data
   * @returns {Promise}
   */
  initPerformanceBonusData(data) {
    return request.post('/performance_bonus/init_data', data)
  },
  /**
   * 添加奖金分配的成员
   * @param data
   * @returns {Promise}
   */
  addMembersToBonusData(data) {
    return request.post('/performance_bonus/add_members', data)
  },
  /**
   * 删除奖金分配成员
   * @param id
   * @returns {Promise}
   */
  delMemberFromBonusData(id) {
    let data = { id: id }
    return request.post('/performance_bonus/del_member', data)
  },
  // /**
  //  * 获取奖金数据
  //  * @param data
  //  * @returns {Promise}
  //  */
  // getPerformanceBonusData(data) {
  //     return request.post('/performance_bonus/get_month_data', data)
  // },
  /**
   * @description 获取【奖金】月数据(分组)
   * @param {*} data
   * @returns
   */
  getPerformanceBonus(data) {
    return request.post('/performance_bonus/list', data)
  },
  /**
   * 奖金明细清单-查询
   * @returns {Promise}
   * @param data
   */
  getPerformanceBonusInfo(data) {
    // let data = {id: id}
    return request.post('/performance_bonus/get_info', data)
  },
  /**
   * 显示或隐藏记录
   * @param data
   * @returns {Promise}
   */
  changeBonusInfoShowStatus(data) {
    // let data = {id: id}
    return request.post('/performance_bonus/change_show_status', data)
  },

  /**
   * 保存奖金信息
   * @param data
   * @returns {Promise}
   */
  saveBonusBill(data) {
    return request.post('/performance_bonus/save_bill', data)

  },
  /**
   * 提交奖金分配明细 -》待总监审核
   * @param data
   * @returns {Promise}
   */
  commitBonusBill(data) {
    return request.post('/performance_bonus/commit_to_next', data)
  },
  /**
   * 保存团队奖金
   * @param data
   * @returns {Promise}
   */
  saveBonus(data) {
    return request.post('/performance_bonus/save_bonus', data)
  },

  /**
   * 审核：人资审核和总监审核
   * @param data
   * @returns {Promise}
   */
  checkBonusBill(data) {
    return request.post('/performance_bonus/check_bill', data)
  },

  revokeBonusBill(data) {
    return request.post('/performance_bonus/revoke_bill', data)
  },
  /**
   * 导出：奖金设置
   * @param data
   * @returns {Promise}
   */
  exportBonusBill(data) {
    return request.postBlob('/performance_bonus/export', data)
  },

  /**
   * @description 获取红人考核平台
   * @param {*} data
   * @returns
   */
  getPerformancePlatforms(data) {
    return request.post('/performance_platform/get_performance_platforms', data)
  },

  /**
   * @description 设置红人考核平台
   * @param {*} data
   * @returns
   */
  setPerformancePlatforms(data) {
    return request.post('/performance_platform/set_performance_platforms', data)
  },

  /**
   * @description 获取红人考核平台
   * @param {*} data
   * @returns
   */
  getPerformanceSetting(data) {
    return request.post('/performance_setting/get_performance_setting', data)
  },

  /**
   * @description 设置红人考核平台
   * @param {*} data
   * @returns
   */
  setPerformanceSetting(data) {
    return request.post('/performance_setting/set_performance_setting', data)
  },
  /**
   * @description 获取整组红人考核平台
   * @param {*} data
   * @returns
   */
  getGroupPerformancePlatforms(data) {
    return request.post('/performance_platform/get_by_group', data)
  },

  /**
   * @description 设置整组红人考核平台
   * @param {*} data
   * @returns
   */
  setGroupPerformancePlatforms(data) {
    return request.post('/performance_platform/set_by_group', data)
  },

  /**
   * 获取核算完成月份
   * @param data
   * @returns {Promise}
   */
  getPerformanceCheckedMonths(data) {
    return request.post('/performance_check/get_checked_months', data)
  },
  /**
   * 设置核算完成月份
   * @param data
   * @returns {Promise}
   */
  setPerformanceCheckedMonth(data) {
    return request.post('/performance_check/set_checked_month', data)
  },
  /**
   * 查询XX组XX年XX季度的绩效反馈与建议
   * @param data
   * @returns {Promise}
   */
  getPerformanceSuggestion(data){
    return request.post('/performance_suggestion/get_info_by_where', data)
  },
  savePerformanceSuggestion(data){
    return request.post('/performance_suggestion/store', data)
  }
}
