import * as request from '@/utils/request'

export default {
    /**
     * @description 获取列表
     * @param {*} data
     * @returns
     */
    getDownLoadFileList(data) {
        return request.post('/download_file/list', data)
    },
    /**
     * @description 获取配置
     * @param {*} data
     * @returns
     */
    getDownLoadFileOptions(data) {
        return request.post('/download_file/options', data)
    },
    /**
     * 导出
     * @returns {Promise}
     * @param fileId
     */
    exportDownLoadFile(fileId) {
        let data = { id: fileId }
        return request.postBlob('/download_file/export', data)
    },
}
