import * as request from '@/utils/request'

export default {
  /**
   * @description 获取列表
   * @returns promise
   */
  getSystemOperLogList(data) {
    return request.post('/system_oper_log/list', data)
  },
  /**
   * 删除日志
   * @param data
   * @returns {Promise}
   */
  deleteSystemOperLog(data) {
    return request.post('/system_oper_log/delete', data)
  },

  clearSystemOperLog(data) {
    return request.post('/system_oper_log/clear', data)
  },
  /**
   * 下拉框数据
   * @returns {Promise}
   */
  getSystemOperLogRouteOptions() {
    return request.post('/system_oper_log/get_options', {})
  },

  /**
   * 业务日志
   * @param data
   * @returns {Promise}
   */
  getOperationLogList(data) {
    return request.post('/operation_log/list', data)
  },
  getOperationLogOptions(data) {
    return request.post('/operation_log/options', data)
  }
}