const TokenKey = 'HYPERF_SESSION_ID'

export function getToken() {
    return localStorage.getItem(TokenKey)
}

export function setToken(token) {
    // 管理系统用sessionStorage更合适，同时配置信息及时获取
    return localStorage.setItem(TokenKey, token)
}

export function removeToken() {
    return localStorage.removeItem(TokenKey)
}


export function getCurrentUserInfo() {
    let user_info = localStorage.getItem('user_info')
    if (user_info) {
        if (user_info.indexOf('{') > -1) {
            user_info = JSON.parse(user_info)
            return user_info
        }
    }
    return null
}