export default {
    path: 'trend',
    name: 'trend',
    component: () => import(/* webpackChunkName: "trend-list" */ '@/pages/trend/Index'),
    meta: {title: '涨粉数据'},
    children: [
        {
            path: 'enter',
            name:'trend_enter',
            component: () => import('@/pages/trend/enter/TrendList'),
            meta: {title: '涨粉数据录入'},
        },
        {
            path: 'data',
            name:'trendList',
            component: () => import('@/pages/trend/data/TrendList'),
            meta: {title: '涨粉数据'},
        },
        {
            path: 'rank',
            name:'trend_rank',
            component: () => import('@/pages/trend/TrendRank'),
            meta: {title: '涨粉榜单'},
        }
    ]
}
