export default {
    path: 'performance',
    name: 'performance',
    component: () => import('@/pages/performance/Performance'),
    meta: { title: '绩效目标' },
    children: [
        {
            path: 'objectives',
            name: 'performance_objectives',
            component: () => import('@/pages/performance/PerformanceObjectives'),
            meta: { title: '绩效目标一览' },
        },
        {
            path: 'bonus',
            name: 'performance_bonus',
            component: () => import('@/pages/performance/bonus/BonusIndex'),
            meta: { title: '绩效奖金明细' },
        },
        {
            path: 'case_record',
            name: 'case_record',
            component: () => import('@/pages/case_record/TiktokData'),
            meta: { title: '抖音视频案例' },
        },
        {
            path: 'dept',
            name: 'performance_dept',
            component: () => import('@/pages/performance/dept/Index'),
            meta: { title: '总监绩效' },
        },
    ]
}
