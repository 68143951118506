import * as request from '@/utils/request'

export default {
  /**
   * @description 获取列表
   * @param {*} data
   * @returns
   */
  getEcpBonusMonthMemberList(data) {
    return request.post('/ecp_bonus_member/list', data)
  },

  /**
   * @description 保存
   * @param {*} data
   * @returns
   */
  saveEcpBonusMonthMember(data) {
    return request.post('/ecp_bonus_member/store', data)
  },
  /**
   * @description 保存
   * @param {*} data
   * @returns
   */
  batchAddEcpBonusMonthMember(data) {
    return request.post('/ecp_bonus_member/batch_store', data)
  },
  /**
   * @description 获取详情
   * @param {*} id
   * @returns
   */
  getEcpBonusMonthMemberInfo(id) {
    let data = { id: id }
    return request.post('/ecp_bonus_member/get_info', data)
  },
  /**
   * @description 根据id删除单条信息
   * @param {*} data
   * @returns
   */
  deleteEcpBonusMonthMember(id) {
    let data = { id: id }
    return request.post('/ecp_bonus_member/delete', data)
  },

}