export default {
  path: 'team_manage',
  name: 'team_manage',
  component: () => import('@/pages/team/DataList'),
  meta: { title: 'MCN团队' },
  children: [
    {
      path: 'team_member',
      name: 'teamMember',
      component: () => import('@/pages/team/member/TeamMemberList'),
      meta: { title: '团队成员异动记录' }
    },
    {
      path: 'kol_change',
      name: 'teamKolChange',
      component: () => import('@/pages/team/artist/TeamKolChangeList'),
      meta: { title: 'KOL异动记录' }
    }
    // {
    //     path: 'data',
    //     component: () => import('@/pages/trend/data/TrendList'),
    //     meta: {title: '列表'},
    // }
  ]
}
