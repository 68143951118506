export default {
    path: 'artist',
    name: 'artist',
    component: () => import('@/pages/artist/ArtistList'),
    meta: {title: '红人管理'},
    children: [
        {
            path: 'create',
            name: 'artist_create',
            component: () => import('@/pages/artist/CreateEdit'),
            meta: {title: '添加红人'},
        },
        {
            path: ':artist_id/edit',
            name:'artist-edit',
            component: () => import('@/pages/artist/CreateEdit'),
            meta: {title: '编辑红人'},
        },
        {
            path: ':artist_id/intro',
            name: 'artist_intro',
            component: () => import('@/pages/artist/intro/ArtistIntro'),
            meta: {title: '红人详情'},
        }
    ]
}
