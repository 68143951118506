import * as request from '@/utils/request'

export default {
  /**
   * @description 获取列表
   * @param {*} data
   * @returns 
   */
  getEcpQuarterList(data) {
    return request.post('/ecp_quarter/list', data)
  },

  /**
   * @description 保存
   * @param {*} data
   * @returns 
   */
  saveEcpQuarter(data) {
    return request.post('/ecp_quarter/store', data)
  },

  /**
   * @description 批量添加
   * @param {*} data
   * @returns
   */
  batchSaveEcpQuarter(data) {
    return request.post('/ecp_quarter/batch_store', data)
  },
  /**
   * @description 获取文章详情
   * @param {*} id
   * @returns 
   */
  getEcpQuarterInfo(id) {
    let data = {id:id}
    return request.post('/ecp_quarter/get_info', data)
  },
  /**
   * @description 根据id删除单条信息
   * @param {*} data
   * @returns
   */
  deleteEcpQuarter(data) {
    // let data = {id:id}
    return request.post('/ecp_quarter/delete',data)
  },

}