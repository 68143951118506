import * as request from '@/utils/request'

export default {
  /**
   * @description 获取MCN 部门奖金分配表
   * @returns promise
   */
  getDeptMcnBonusList(data) {
    return request.post('/dept/mcn_bonus_list', data)
  }
}