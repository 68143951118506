import * as request from '@/utils/request'

/**
 * 自动生成 api代码【模块名：库位】
 * 生成日期：2024/04/25 11:25:10
 * 生成路径: src/api/warehouse_location.js
 * 生成人：超级管理员
 * 需要在文件src/api/index.js 引入当前文件
 * import warehouse_location from './warehouse_location'
 * export default {
 * ...
 * // 库位
 *    ...warehouse_location,
 * }
 */

export default {
  /**
   * @description 获取列表
   * @param {*} data
   * @returns
   */
  getWarehouseLocationList(data) {
    return request.post('/warehouse_location/list', data)
  },
  /**
   * @description 保存
   * @param {*} data
   * @returns
   */
  saveWarehouseLocation(data) {
    return request.post('/warehouse_location/store', data)
  },
  /**
   * @description 获取详情
   * @param {*} id
   * @returns
   */
  getWarehouseLocationInfo(id) {
    let data = { id: id }
    return request.post('/warehouse_location/get_info', data)
  },
  /**
   * @description 根据id删除单条信息
   * @param {*} data
   * @returns
   */
  deleteWarehouseLocation(id) {
    let data = { id: id }
    return request.post('/warehouse_location/delete', data)
  },
  /**
   * 导入
   * @param data
   * @returns {Promise}
   */
  importWarehouseLocations(data) {
    return request.post('/warehouse_location/import', data)
  },
  /**
   * 导出
   * @param data
   * @returns {Promise}
   */
  exportWarehouseLocations(data) {
    return request.postBlob('/warehouse_location/export', data)
  },
  /**
   * 预览批量新增
   * @param data
   * @returns {Promise}
   */
  previewLocationBatchAdd(data) {
    return request.post('/warehouse_location/previewBatchAdd', data)
  },
  /**
   * 批量保存
   * @param data
   * @returns {Promise}
   */
  batchSaveWarehouseLocation(data) {
    return request.post('/warehouse_location/batchStore', data)
  }
}