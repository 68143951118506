export default {
  path: 'sys_notice',
  name: 'sys_notice',
  component: () => import('@/pages/notice/SysnoticeList'),
  meta: { title: '系统公告' },
  children: [
    // {
    //     path: 'create',
    //     component: () => import('@/pages/network_ip/CreateEdit'),
    //     meta: {title: '添加IP'},
    // },
    // {
    //     path: ':artist_id/edit',
    //     component: () => import('@/pages/network_ip/CreateEdit'),
    //     meta: {title: '编辑IP'},
    // }
  ],
}
