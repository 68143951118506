import * as request from '@/utils/request'

export default {
  /**
   * @description 获取访客数
   * @param {*} data
   * @returns
   */
  getVisitorData(data) {
    return request.post('/home/visitor_data', data)
  },
  /**
   * 用户统计
   * @param data
   * @returns {Promise}
   */
  statisticsUserUser(data) {
    return request.post('/home/statistics_user', data)
  },
  /**
   * 获取公告信息
   * @param data
   * @returns {Promise}
   */
  getNoticeData(data) {
    return request.post('/home/notice_data', data)
  },
  /**
   * 业务数据增量统计
   * @param data
   * @returns {Promise}
   */
  getBusinessDataGrowth(data) {
    return request.post('/home/business_data', data)
  }
}