import * as request from '@/utils/request'

export default {
    /**
     * 获取红人
     * @returns
     */
    rebateGetKolList(data) {
        return request.post('/kol_analy_rebate/list', data)
    },
    /**
     * 修改数据
     * @returns
     */
    rebateEdit(data) {
        return request.post('/kol_analy_rebate/save', data)
    },
    /**
     * 删除数据
     * @returns
     */
    rebateDel(data) {
        return request.post('/kol_analy_rebate/delete', data)
    },
    /**
     * 导出数据
     * @returns
     */
    exportRebateList(data) {
        return request.postBlob('/kol_analy_rebate/download', data)
    },
    /**
     * 获取导入列表
     * @returns
     */
    rebategetImportList(data) {
        return request.post('/kol_analy_labor_cost/list', data)
    },
    /**
     * 撤回导入列表
     * @returns
     */
    rebateDelImport(data) {
        return request.post('/kol_analy_rebate/delete_import', data)
    }

}
