import * as request from '@/utils/request'

export default {
  /**
   * @description 获取列表
   * @param {*} data
   * @returns 
   */
  getTeamKoLChangeList(data) {
    return request.post('/team_kol_change/list', data)
  },
}