export default {
    path: 'revenue_menu',
    component: () => import('@/pages/menus/RevenueMenu'),
    meta: {title:'营收'},
    children: [
        {
            path: 'revenue_statistics',
            name: 'revenue_statistics',
            component: () => import('@/pages/revenue/data/RevenueStatistics'),
            meta: { title: '总营收一览' },
        },
        {
            path: 'order_statistics',
            name: 'order_statistics',
            component: () => import('@/pages/revenue/order/StatisticsTable'),
            meta: { title: '实时订单排行' },
        },
        {
            path: 'growth_statistics',
            name: 'growth_statistics',
            component: () => import('@/pages/revenue/growth/GrowthIndex'),
            meta: { title: '孵化分析' },
        },
    ]
}
