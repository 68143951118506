import * as request from '@/utils/request'

export default {
  /**
   * @description 获取列表
   * @param {*} data
   * @returns
   */
  getEcpMonthList(data) {
    return request.post('/ecp_month/list', data)
  },

  /**
   * @description 保存
   * @param {*} data
   * @returns
   */
  saveEcpMonth(data) {
    return request.post('/ecp_month/store', data)
  },
  /**
   * @description 保存-月份项目组奖金
   * @param {*} data
   * @returns
   */
  saveEcpMonthBonus(data) {
    return request.post('/ecp_month/save_bonus', data)
  },
  /**
   * @description 保存-项目组一季度的奖金数据
   * @param {*} data
   * @returns
   */
  updateEcpBonus(data) {
    return request.post('/ecp_group/save', data)
  },
  /**
   * @description 读取-项目组一季度的奖金数据
   * @param {*} data
   * @returns
   */
  getEcpBonus(data) {
    return request.post('/ecp_group/list', data)
  },
  /**
   * 获取项目组一季度的奖金数据
   * @param data
   * @returns {Promise}
   */
  getEcpQuarterBonus(data) {
    return request.post('/ecp_month/quarter_bonus', data)
  },
  /**
   * @description 获取详情
   * @param {*} id
   * @returns
   */
  getEcpMonthInfo(id) {
    let data = { id: id }
    return request.post('/ecp_month/get_info', data)
  },
  /**
   * @description 根据id删除单条信息
   * @param {*} data
   * @returns
   */
  deleteEcpMonth(id) {
    let data = { id: id }
    return request.post('/ecp_month/delete', data)
  },
  /**
   * 发布奖金
   * @param id
   * @returns {Promise}
   */
  publishBonusEcpMonth(id) {
    let data = { id: id }
    return request.post('/ecp_month/publish_bonus', data)
  },
  /**
   * 下载绩效目标list
   * @param id
   * @returns {Promise}
   */
  downEcpMonthList(data) {
    return request.postBlob('/ecp_quarter/list', data)
  }

}
