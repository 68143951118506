import * as request from '@/utils/request'

export default {
    /**
     * @description 获取直播数据列表
     * @param {*} data
     * @returns
     */
    getLiveInfoList(data) {
        return request.post('/live_info/list', data)
    },
    /**
     * 直播数据汇总
     * @param data
     * @returns {Promise}
     */
    getLiveInfoSummary(data) {
        return request.post('/live_info/summary', data)
    },
    /**
     * 列设置
     * @returns {Promise}
     */
    getLiveInfoColSet(data) {
        return request.post('/live_info/cols_set', data)
    },
    /**
     * @description 保存
     * @param {*} data
     * @returns
     */
    addLiveInfo(data) {
        return request.post('/live_info/add', data)
    },
    /**
     * @description 修改
     * @param {*} data
     * @returns
     */
    updateLiveInfo(data) {
        return request.post('/live_info/update', data)
    },
    /**
     * @description 获取详情
     *
     * @param {*} id
     * @returns
     */
    getLiveInfo(id) {
        let data = { id: id }
        return request.post('/live_info/get_info', data)
    },

    /**
     * @description 根据id删除单条信息
     *
     * @returns
     */
    delLiveInfo(id) {
        let data = { id: id }
        return request.post('/live_info/delete', data)
    },

    /**
     * 导出
     * @param data
     * @returns {Promise}
     */
    exportLiveInfos(data) {
        return request.postBlob('/live_info/export', data)
    },
    /**
     * @description 操作日志
     * @param data
     * @returns {Promise}
     */
    getLiveInfoLog(data) {
        return request.post('/live_info/log', data)
    },
    /**
     * 图表数据
     * @param data
     * @returns {Promise}
     */
    getLiveInfoChartData(data) {
        return request.post('/live_info/chart_data', data)
    },

    /**
     * 直播分组（达、主播）设置列表
     */
    getLiveGroupList(data) {
        return request.post('/live_group/list', data)
    },
    /**
     *直播分组（达、主播）设置-保存
     * @param data
     * @returns {Promise}
     */
    saveLiveGroup(data) {
        return request.post('/live_group/store', data)
    },
    /**
     * @description 直播分组（达、主播）设置-获取详情
     *
     * @param {*} id
     * @returns
     */
    getLiveGroup(id) {
        let data = { id: id }
        return request.post('/live_group/get_info', data)
    },
    /**
     * @description 根据id删除单条信息-直播分组（达、主播）设置
     *
     * @returns
     */
    delLiveGroup(id) {
        let data = { id: id }
        return request.post('/live_group/delete', data)
    }
}
