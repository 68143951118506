/**
 * websocket处理封装
 */

class webSocket {
    /**
     * websocket 连接句柄
     * @var Websocket
     */
    ws = null

    /**
     * 服务器地址
     * @var string
     */
    serverUrl

    /**
     * 心跳配置
     * @var object
     */
    heartbeat = {
        timer: null, //心跳计时器
        openHeartbeat: true,    // 是否开启心跳
        interval: 10 * 1000     // 心跳发送间隔，默认10秒
    }

    /**
     * 重连配置
     * @var object
     */
    reconnectConfig = {
        isReconnect: true,    // 是否开启短线重连
        timer: null,          // 计时器对象
        interval: 5 * 1000,   // 重连间隔时间
        retry: 20            // 重连次数
    }

    /**
     * ws事件
     * @var Object
     */
    onEvent = {}

    /**
     * 原生 websocket 事件
     */
    events = {
        // eslint-disable-next-line no-unused-vars
        onOpen: e => {
        },
        // eslint-disable-next-line no-unused-vars
        onClose: e => {
        },
        // eslint-disable-next-line no-unused-vars
        onError: e => {
        }
    }

    /**
     * 构造函数
     * @param {string} url
     * @param {object} events
     */
    constructor(url, events) {
        this.serverUrl = url
        this.events = Object.assign(this.events, events)
    }

    /**
     * 绑定事件
     */
    on(ev, callback) {
        this.onEvent[ev] = callback
    }

    /**
     * 解绑事件
     * @param ev
     */
    rmOn(ev) {
        if (this.onEvent[ev]) {
            delete this.onEvent[ev]
        }
    }

    /**
     * 已绑定的事件
     * @returns {{}|{}}
     */
    getOnEvents() {
        return this.onEvent || {}
    }

    /**
     * 连接websocket
     */
    connection() {
        if (this.ws) {
            console.log('已连接，断开... 重新连接...')
            this.ws.close()
            this.ws = null
        }

        this.ws = new WebSocket(this.serverUrl)
        this.ws.onopen = this.onOpen.bind(this)
        this.ws.onerror = this.onError.bind(this)
        this.ws.onmessage = this.onMessage.bind(this)
        this.ws.onclose = this.onClose.bind(this)
    }

    /**
     * 解析数据
     *
     * @param {Object} evt Websocket 消息
     */
    onParseData(evt) {
        if (evt.data && evt.data.indexOf('{') > -1)
            return JSON.parse(evt.data)
        else
            return evt.data || null
    }

    /**
     * 打开连接
     *
     * @param {Object} evt Websocket 消息
     */
    onOpen(evt) {
        this.events.onOpen(evt)
        this.sendHeartbeat()
        // 连接成功后，设置为true，下次重连才能生效
        this.reconnectConfig.isReconnect = true
        //归还重试次数
        this.reconnectConfig.retry = 20
    }

    /**
     * 关闭连接
     *
     * @param {Object} evt Websocket 消息
     */
    onClose(evt) {
        clearInterval(this.heartbeat.timer)

        if (evt.code === 1006) {
            this.reconnect()
        }

        this.events.onClose(evt)
    }

    /**
     * 连接错误
     *
     * @param {Object} evt Websocket 消息
     */
    onError(evt) {
        this.events.onError(evt)
    }

    /**
     * 接收消息
     *
     * @param {Object} evt Websocket 消息
     */
    onMessage(evt) {
        // console.warn(`WebSocket 消息接收...`)
        let result = this.onParseData(evt)
        // 方案一：on 绑定事件
        // eslint-disable-next-line no-prototype-builtins
        this.onEvent.hasOwnProperty(result.event) && this.onEvent[result.event](result.message, result.data)
        // eslint-disable-next-line no-prototype-builtins
        // this.onEvent.hasOwnProperty(result.event) || console.warn(`WebSocket 消息事件[${result.event}]未绑定...`)

        // 方案二：自定义全局监听事件[https://juejin.cn/post/6844904039704985608]
        // window.dispatchEvent(new CustomEvent('onMessageWS', {
        //     detail: { data: evt.data }
        // }))
    }

    /**
     * 向服务器发送心跳数据包
     */
    sendHeartbeat() {
        if (this.heartbeat.openHeartbeat) {
            this.heartbeat.timer = setInterval(() => {
                if (this.ws && this.ws.readyState === this.ws.OPEN)
                    this.send({ event: 'PONG' })
            }, this.heartbeat.interval)
        }
    }

    /**
     * 短线重连
     */
    reconnect() {
        let reconnect = this.reconnectConfig
        if (!reconnect.isReconnect || reconnect.retry === 0) {
            return
        }

        this.reconnectConfig.isReconnect = false

        reconnect.timer && clearTimeout(reconnect.timer)

        // eslint-disable-next-line no-unused-vars
        this.reconnectConfig.timer = setTimeout(_ => {
            this.connection()
            this.reconnectConfig.isReconnect = false
            this.reconnectConfig.retry--
            console.log(`网络连接已断开，正在尝试重新连接(${this.reconnectConfig.retry})...`)
        }, reconnect.interval)
    }

    /**
     * 向服务器发送数据
     *
     * @param {Object} data
     */
    send(data) {
        if (this.ws && this.ws.readyState === this.ws.OPEN)
            this.ws && this.ws.send(JSON.stringify(data))
        else {
            console.log('ws 未开启，不能发生信息')
        }
    }

    /**
     * 关闭连接
     */
    close() {
        if (this.ws) {
            this.ws.close()
            this.ws = null
        }
    }
}

export default webSocket
 