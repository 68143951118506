export default {
    path: 'notice',
    name: "notice",
    component: () => import('@/pages/notice/NoticeList'),
    meta: {title: '公告管理'},
    children: [
        // {
        //     path: 'create',
        //     component: () => import('@/pages/network_ip/CreateEdit'),
        //     meta: {title: '添加IP'},
        // },
        // {
        //     path: ':artist_id/edit',
        //     component: () => import('@/pages/network_ip/CreateEdit'),
        //     meta: {title: '编辑IP'},
        // }
    ]
}
