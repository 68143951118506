import * as request from '@/utils/request'

export default {
  /**
   * 获取KOL归档记录列表
   * @param data
   * @returns {Promise}
   */
  getKolArchiveList(data) {
    return request.post('/kol_archive/get_list', data)
  },
  /**
   * 获取KOL归档记录
   * @param id
   * @returns {Promise}
   */
  getKolArchiveInfo(id) {
    return request.post('/kol_archive/get_info', { id: id })
  },
  /**
   * 工作簿内容
   * @returns {Promise}
   */
  getKolArchiveSheetContent(params) {
    return request.post('/kol_archive/get_sheet_content', params)
  },
  /**
   *
   * @param params
   * @returns {Promise}
   */
  getKolArchiveSheetCount(params) {
    return request.post('/kol_archive/get_sheet_count', params)
  },
  /**
   * 删除KOL归档记录
   * @param id
   * @returns {Promise}
   */
  delKolArchive(id) {
    return request.post('/kol_archive/delete', { id: id })
  },

  /**
   * 导出归档文件
   * @param id
   * @returns {Promise}
   */
  exportKolArchive(id) {
    return request.postBlob('/kol_archive/export', { id: id })
  }
}