// 跟级别的sate
const state = {
    isCollapse: true,
    menuList: [],
    routePath: null,
    userInfo: {},
    userPermissions: [],
    userDptInfo : {},//用户直属部门ID集合和用户直属部门及其下级部门ID集合
    // 错误响应弹窗标志，避免重复弹出
    responseErrorMessageBoxMark: false,
    // 按钮类型
    buttonType: process.env.VUE_APP_BUTTON_TYPE,
    // 全局的loading状态控制
    loadingStatus: false,
    //消息通知数：未读
    notifyTotal: 0
}

export default state
