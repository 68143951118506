export default {
    path: 'live_data',
    name: 'LiveDataIndex',
    component: () => import(/* webpackChunkName: "trend-list" */ '@/pages/live_data/Index'),
    meta: { title: '直播数据' },
    children: [
        {
            path: 'enter',
            name: 'LiveDataEnter',
            component: () => import('@/pages/live_data/LiveDataEnter'),
            meta: { title: '直播数据录入' },
        },
        {
            path: 'data',
            name: 'LiveDataList',
            component: () => import('@/pages/live_data/LiveDataList'),
            meta: { title: '直播数据列表' },
        }
    ]
}
