import api from '@/api'

const user = {
    state: {
        token: localStorage.getItem("HYPERF_SESSION_ID"),
        name: '',
        avatar: '',
        roles: [],
        permissions: []
    },

    mutations: {
        // SET_TOKEN: (state, token) => {
        //     state.token = token
        // },
        // SET_NAME: (state, name) => {
        //     state.name = name
        // },
        // SET_AVATAR: (state, avatar) => {
        //     state.avatar = avatar
        // },
        // SET_ROLES: (state, roles) => {
        //     state.roles = roles
        // },
        // SET_PERMISSIONS: (state, permissions) => {
        //     state.permissions = permissions
        // }
    },

    actions: {

        // 退出系统
        LogOut({ commit }) {
            api.submitLogout()
            localStorage.removeItem('delay_notified');//商务逾期：已提醒标记去除
            localStorage.removeItem('user_info')
            localStorage.removeItem('HYPERF_SESSION_ID')
            sessionStorage.setItem('delay_notified', 'N')
            // commit('SET_TOKEN', '')
            commit('handleMenuList', [])
            commit('handleUserDptInfo', [])
            commit('handleUserPermissions', [])
        },

        // 前端 登出
        FedLogOut({ commit }) {
            localStorage.removeItem('delay_notified');//商务逾期：已提醒标记去除
            localStorage.removeItem('user_info')
            localStorage.removeItem('HYPERF_SESSION_ID')
            sessionStorage.setItem('delay_notified', 'N')
            commit('handleMenuList', [])
            commit('handleUserDptInfo', [])
            commit('handleUserPermissions', [])
        }
    }
}

export default user