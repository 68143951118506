import * as request from '@/utils/request'

export default {
  /**
   * @description 获取站点配置信息
   * @author YM
   * @date 2019-03-18
   * @returns
   */
  getSettingSiteInfo() {
    return request.post('/setting/site_set')
  },

  /**
   * @description 备份列表
   * @author YQ
   * @date 2019-12-14
   * @param {*} data
   * @returns
   */
  getBackupList(data) {
    return request.post('/statistics_backup/backup_list', data)
  },
  /**
   * 红人模型配置设置
   * @returns
   */
  getModelSet(data) {
    return request.post('/kol_analy_setting/info', data)
  },
  /**
   * @description 保存站点配置信息
   * @author YM
   * @date 2019-03-18
   * @param {*} data
   * @returns
   */
  saveSettingSiteInfo(data) {
    return request.post('/setting/site_save', data)
  },

  /**
   * 钉钉消息通知记录查询
   * @param data
   * @returns {Promise}
   */
  getDingtalkLogList(data) {
    return request.post('/dingtalk_log/list', data)
  },
  getDingtalkNotifyReadStatus(data) {
    return request.post('/dingtalk_log/read_status', data)
  },
  /**
   * 站内消息通知模板查询
   * @param data
   * @returns {Promise}
   */
  getNotifySettingConfigList(data) {
    return request.post('/notify_setting_config/list', data)
  }
}
