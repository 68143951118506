import * as request from '@/utils/request'

export default {
  /**
   * 查询系统公告列表
   * @param data
   * @returns {*}
   */
  getSysNoticeList(data) {
    return request.post('/sys_notice/list', data)
  },

  /**
   *
   * @param id
   * @returns {Promise}
   */
  getSysNoticeInfo(id) {
    let data = {id:id}
    return request.post('/sys_notice/get_info', data)
  },

  /**
   *
   * @param userid 用户id articleid 文章id
   * @returns {Promise}
   */
  readSysNoticeInfo(userid,articleid) {
    let data = {user_id:userid,article_id:articleid}
    return request.post('/sys_notice/read', data)
  },
}
