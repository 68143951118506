import * as request from '@/utils/request'

export default {
  /**
   * @description 客户库列表
   * @author YM
   * @date 2019-03-04
   * @param {*} data
   * @returns
   */
  getCustomList(data) {
    return request.post('/brand_hub_customer/list', data)
  },
  /**
   * 客户详情
   * @param data
   * @returns {Promise}
   */
  getCustomInfo(id) {
    return request.post('/brand_hub_customer/info', { id: id })
  },
  /**
   * @description 新增客户
   * @author YM
   * @date 2019-03-04
   * @param {*} data
   * @returns
   */
  addCustom(data) {
    return request.post('/brand_hub_customer/create', data)
  },
  /**
   * @description 修改客户
   * @author YM
   * @date 2019-03-04
   * @param {*} data
   * @returns
   */
  updateCustom(data) {
    return request.post('/brand_hub_customer/update', data)
  },
  /**
   * @description 删除客户
   * @author YM
   * @date 2019-03-04
   * @param {*} data
   * @returns
   */
  delCustom(data) {
    return request.post('/brand_hub_customer/delete', data)
  },
  /**
   * @description 搜索-公司列表
   * @author YM
   * @date 2019-03-04
   * @param {*} data
   * @returns
   */
  selectCustomList() {
    return request.post('/brand_hub_customer/customer_list')
  },
  /**
   * @description 搜索-录入人列表
   * @author YM
   * @date 2019-03-04
   * @param {*} data
   * @returns
   */
  selectCustomSubmit(data) {
    return request.post('/brand_hub_customer/submit_list', data)
  },
  /**
   * @description 品牌库列表
   * @author YM
   * @date 2019-03-04
   * @param {*} data
   * @returns
   */
  getBrandList(data) {
    return request.post('/brand_hub_brand/list', data)
  },
  getBrandOptions(data) {
    return request.post('/brand_hub_brand/options', data)
  },
  /**
   * 品牌名重复检验
   * @param data
   * @returns {Promise}
   */
  checkBrandName(data) {
    return request.post('/brand_hub_brand/check_repeat', data)
  },
  /**
   * @description 删除品牌库列表
   * @author YM
   * @date 2019-03-04
   * @param {*} data
   * @returns
   */
  delBrandList(data) {
    return request.post('/brand_hub_brand/delete', data)
  },
  /**
   * @description 解绑品牌和公司关系
   * @author YM
   * @date 2019-03-04
   * @param {*} data
   * @returns
   */
  unbindingBrand(data) {
    return request.post('/brand_hub_brand/brand_customer_delete', data)
  },
  /**
   * @description 搜索-品牌名列表
   * @author YM
   * @date 2019-03-04
   * @param {*} data
   * @returns
   */

  selectBrand() {
    return request.post('/brand_hub_brand/brand_list')
  },
  /**
   * @description 搜索-合同名
   * @author YM
   * @date 2019-03-04
   * @param {*} data
   * @returns
   */

  selectContract(data) {
    return request.post('/brand_hub_contract/contract_list', data)
  },
  // contract
  /**
   * @description 搜索-公司id-搜公司联系人
   * @author YM
   * @date 2019-03-04
   * @param {*} data
   * @returns
   */

  selectContact(data) {
    return request.post('/brand_hub_customer/contact', data)
  },
  /**
   * @description 远程搜索录入人
   * @author YM
   * @date 2019-03-04
   * @param {*} data
   * @returns
   */

  selectSubmit(data) {
    return request.post('/brand_hub_brand/opreation_list', data)
  },
  /**
   * @description 新增品牌
   * @author YM
   * @date 2019-03-04
   * @param {*} data
   * @returns
   */
  addBrand(data) {
    return request.post('/brand_hub_brand/create', data)
  },
  /**
   * @description 修改品牌
   * @author YM
   * @date 2019-03-04
   * @param {*} data
   * @returns
   */
  updataBrand(data) {
    return request.post('/brand_hub_brand/update', data)
  },
  /**
   * @description 获取合同列表
   * @author YM
   * @date 2019-03-04
   * @param {*} data
   * @returns
   */
  getContract(data) {
    return request.post('/brand_hub_contract/list', data)
  },
  /**
   * @description 获取合同列表
   * @author YM
   * @date 2019-03-04
   * @param {*} data
   * @returns
   */
  addContract(data) {
    return request.post('/brand_hub_contract/create', data)
  },
  /**
   * @description 删除合同列表
   * @author YM
   * @date 2019-03-04
   * @param {*} data
   * @returns
   */
  delContract(data) {
    return request.post('/brand_hub_contract/delete', data)
  },
  /**
   * @description 搜索公司下品牌
   * @author YM
   * @date 2019-03-04
   * @param {*} data
   * @returns
   */
  delContractFile(data) {
    return request.post('/brand_hub_contract/attachment_delete', data)
  },
  /**
   * @description 获取合同列表
   * @author YM
   * @date 2019-03-04
   * @param {*} data
   * @returns
   */
  updateContract(data) {
    return request.post('/brand_hub_contract/update', data)
  },

  /**
   * @description 搜索公司下品牌
   * @author YM
   * @date 2019-03-04
   * @param {*} data
   * @returns
   */
  selectCustomerBrands(data) {
    return request.post('/brand_hub_customer/customer_brands', data)
  },
  /**
   * @description 品牌详情
   * @author YM
   * @date 2019-03-04
   * @param {*} data
   * @returns
   */
  infoBrandList(data) {
    return request.post('/brand_hub_brand/info', data)
  },
  /**
   * @description 品牌下公司列表
   * @author YM
   * @date 2019-03-04
   * @param {*} data
   * @returns
   */
  getBrandCustomer(data) {
    return request.post('/brand_hub_brand/brand_customer', data)
  },
  /**
   * @description 品牌下合同列表
   * @author YM
   * @date 2019-03-04
   * @param {*} data
   * @returns
   */
  getBrandContract(data) {
    return request.post('/brand_hub_brand/brand_contract', data)
  },
  /**
   * @description 品牌下产品列表
   * @author YM
   * @date 2019-03-04
   * @param {*} data
   * @returns
   */
  getBrandProduct(data) {
    return request.post('/brand_hub_brand/brand_product', data)
  },
  /**
   * @description 品牌下销售
   * @param data
   * @returns {*}
   */
  getBrandSale(data) {
    return request.post('/brand_hub_brand/brand_sale', data)
  },
  /**
   * @description 新增的品牌列表
   * @param {*} data
   * @returns
   */
  getNewBrandList(data) {
    return request.post('/brand_hub_brand/new_brand_list', data)
  }
}
