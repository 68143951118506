import * as request from '@/utils/request'

/**
 * 自动生成 api代码【模块名：】
 * 生成日期：2024/03/08 15:53:09
 * 生成路径: src/api/region.js
 * 生成人：超级管理员
 * 需要在文件src/api/index.js 引入当前文件
 * import region from './region'
 * export default {
 * ...
 * //物流信息订阅
 *    ...region,
 * }
 */

export default {
  /**
   * @description 获取列表
   * @param {*} data
   * @returns
   */
  getRegionList(data) {
    return request.post('/region/list', data)
  },
  /**
   * @description 保存
   * @param {*} data
   * @returns
   */
  saveRegion(data) {
    return request.post('/region/store', data)
  },
  /**
   * @description 获取详情
   * @param {*} id
   * @returns
   */
  getRegionInfo(id) {
    let data = { id: id }
    return request.post('/region/get_info', data)
  },
  /**
   * @description 根据id删除单条信息
   * @param {*} data
   * @returns
   */
  deleteRegion(id) {
    let data = { id: id }
    return request.post('/region/delete', data)
  },
  /**
   * 导入
   * @param data
   * @returns {Promise}
   */
  importRegions(data) {
    return request.post('/region/import', data)
  },
  /**
   * 导出
   * @param data
   * @returns {Promise}
   */
  exportRegions(data) {
    return request.postBlob('/region/export', data)
  }

}
