export default {
  path: 'plc_detail',
  name: 'plc_detail',
  component: () => import('@/pages/publication/Index'),
  meta: {title: 'KOL刊例'},
  children: [
    {
      path: 'item/:id',
      name: 'plc_detail_item',
      component: () => import('@/pages/publication/PlcDetail'),
      meta: {title: '刊例详情'},
    },
  ]
}
