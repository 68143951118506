import * as request from '@/utils/request'

export default {
  /**
   * @description 获取列表
   * @param {*} data
   * @returns
   */
  getDingTalkEventList(data) {
    return request.post('/ding_talk_event/list', data)
  },
  /**
   * @description 获取信息
   * @returns {Promise}
   */
  getDingTalkEventInfo(dept_id) {
    return request.post('/ding_talk_event/get_info', { dept_id: dept_id })
  },
  /**
   * 删除
   * @param id
   * @returns {Promise}
   */
  delDingTalkEventInfo(id) {
    return request.post('/ding_talk_event/delete', { id: id })
  },
  /**
   * 下拉框数据
   * @param id
   * @returns {Promise}
   */
  getDingTalkEventOptionMap() {
    return request.post('/ding_talk_event/option_map', {})
  }
}
