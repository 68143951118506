import * as request from '@/utils/request'

/**
 * 自动生成 api代码【模块名：物流信息订阅记录】
 * 生成日期：2024/03/04 11:47:42
 * 生成路径: src/api/logistic_subscribe.js
 * 生成人：超级管理员
 * 需要在文件src/api/index.js 引入当前文件
 * import logistic_subscribe from './logistic_subscribe'
 * export default {
 * ...
 * //物流信息订阅
 *    ...logistic_subscribe,
 * }
 */

export default {
    /**
     * @description 获取列表
     * @param {*} data
     * @returns
     */
    getLogisticSubscribeList(data) {
        return request.post('/logistic_subscribe/list', data)
    },
    /**
     * @description 保存
     * @param {*} data
     * @returns
     */
    saveLogisticSubscribe(data) {
        return request.post('/logistic_subscribe/store', data)
    },
    /**
     * @description 获取详情
     * @param {*} id
     * @returns
     */
    getLogisticSubscribeInfo(id) {
        let data = { id: id }
        return request.post('/logistic_subscribe/get_info', data)
    },
    /**
     * @description 根据id删除单条信息
     * @param {*} data
     * @returns
     */
    deleteLogisticSubscribe(id) {
        let data = { id: id }
        return request.post('/logistic_subscribe/delete', data)
    },
    /**
     * 导入
     * @param data
     * @returns {Promise}
     */
    importLogisticSubscribes(data) {
        return request.post('/logistic_subscribe/import', data)
    },
    /**
     * 导出
     * @param data
     * @returns {Promise}
     */
    exportLogisticSubscribes(data) {
        return request.postBlob('/logistic_subscribe/export', data)
    },

}