import * as request from '@/utils/request'

/**
 * 自动生成 api代码【模块名：个人设置】
 * 生成日期：2024/04/26 10:59:58
 * 生成路径: src/api/user_setting.js
 * 生成人：超级管理员
 * 需要在文件src/api/index.js 引入当前文件
 * import user_setting from './user_setting'
 * export default {
 * ...
 * // 个人设置
 *    ...user_setting,
 * }
 */

export default {
  /**
   * @description 获取列表
   * @param {*} data
   * @returns
   */
  getUserSettingList(data) {
    return request.post('/user_setting/list', data)
  },
  /**
   * @description 保存
   * @param {*} data
   * @returns
   */
  saveUserSetting(data) {
    return request.post('/user_setting/store', data)
  },
  /**
   * @description 获取详情
   * @param {*} id
   * @returns
   */
  getUserSettingInfo(id) {
    let data = { id: id }
    return request.post('/user_setting/get_info', data)
  },
  /**
   * @description 获取详情
   * @param  where
   * @returns
   */
  queryUserSettingInfo(where) {
    return request.post('/user_setting/query', where)
  },
  /**
   * @description 根据id删除单条信息
   * @param {*} data
   * @returns
   */
  deleteUserSetting(id) {
    let data = { id: id }
    return request.post('/user_setting/delete', data)
  },
  // /**
  //  * 导入
  //  * @param data
  //  * @returns {Promise}
  //  */
  // importUserSettings(data) {
  //   return request.post('/user_setting/import', data)
  // },
  // /**
  //  * 导出
  //  * @param data
  //  * @returns {Promise}
  //  */
  // exportUserSettings(data) {
  //   return request.postBlob('/user_setting/export', data)
  // },


  /**
   * 产品PPT设置
   * @description 保存
   * @returns
   */
  saveMySetting(data) {
    return request.post('/user_setting/save_by_type', data)
  },
  /**
   * 产品PPT设置
   * @description 保存
   * @returns
   */
  getMySetting(data) {
    return request.post('/user_setting/get_by_type', data)
  }

}
