export default {
  path: 'setting',
  name: 'SettingBase',
  component: () => import(/* webpackChunkName: "setting-base" */ '@/pages/setting/SettingBase'),
  meta: { title: '系统配置' },
  children: [
    {
      path: 'site',
      name: 'SettingSite',
      component: () => import(/* webpackChunkName: "setting-site" */ '@/pages/setting/SettingSite'),
      meta: { title: '站点设置' }
    },
    {
      path: 'artist_map',
      name: 'ArtistMap',
      component: () => import(/* webpackChunkName: "setting-site" */ '@/pages/setting/ArtistMap'),
      meta: { title: '红人对照' }
    },
    {
      path: 'notify_setting',
      name: 'NotifySetting',
      component: () => import(/* webpackChunkName: "setting-site" */ '@/pages/system/notify_setting/notifySettingList'),
      meta: { title: '站内消息模板' }
    },
    {
      path: 'notify_dingtalk',
      name: 'NotifyDingTalk',
      component: () => import(/* webpackChunkName: "setting-site" */ '@/pages/system/notify_dingtalk/notifyDingTalkList'),
      meta: { title: '钉钉消息1' }
    },
    {
      path: 'region_list',
      name: 'RegionList',
      component: () => import('@/pages/RegionList/list.vue'),
      meta: { title: '区域管理' }
    }
  ]
}
