export default {
    path: 'trend_data',
    name: 'trend_data',
    component: () => import(/* webpackChunkName: "trend-list" */ '@/pages/trend/data/Index'),
    meta: {title: '涨粉数据'},
    children: [
        {
            path: ':artist_id/trend',
            name: 'artist_trend_chart',
            component: () => import('@/pages/dashboard/trend/ArtistTrendChart'),
            meta: {title: '红人详情'},
        }
    ]
}
