/**
 * 自动生成 router代码【模块名：快递绑定库位】
 * 生成日期：2024/05/30 11:55:52
 * 生成路径: src/router/express_take_over.js
 * 生成人：超级管理员
 * 需要在文件 src/router/router.js 引入当前文件
 * import express_take_over from './express_take_over'
 // 路由详情
 const routes = [
 {
 path: '/',
 component: () => import('@/layouts/BasicLayout'),
 name: 'BasicLayout',
 meta: { title: '首页' },
 redirect: '/myNav',
 children: [
 ...
 express_take_over,//快递绑定库位
 ]
 */

export default  {
  path: 'express_take_over/list',
  name: 'express_take_over',
  component: () => import('@/pages/express_take_over/ExpressTakeOverList'),
  meta: { title: '快递绑定库位管理' },
}
