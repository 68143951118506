import * as request from '@/utils/request'

export default {
  /**
   * @description OA审批单列表
   * @author YM
   * @date 2019-01-10
   * @returns promise
   */
  getOaManageList(data) {
    return request.post('/kol_approve/list',data)
  },
  /**
   * @description OA作废
   * @author YM
   * @date 2019-01-10
   * @returns promise
   */
  // saveOaManage(data){
  //   return request.post('/kol_approve/save',data)
  //
  // },
  repealOaManage(data){
    return request.post('/kol_approve/repeal',data)
  },
  /**
   * @description 导出模板
   * @param {*} data
   * @returns
   */
  exportKolApprove() {
    return request.postBlob('/kol_approve/download')
  },
  /**
   * @description 导出列表清单
   * @param {*} data
   * @returns
   */
  exportKolOAList(data) {
    return request.postBlob('/kol_approve/list', data)
  },
  /**
   * @description 获取发起人列表
   * @param {*} data
   * @returns
   */
  OaOriginator() {
    return request.post('/kol_approve/originator_list')
  },
  /**
   * @description 撤销导入数据
   * @param {*} data
   * @returns
   */
  OaDeleteImport(data) {
    return request.post('/kol_approve/delete_import',data)
  },

// /kol_approve/delete_import
}
