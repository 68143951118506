import * as request from '@/utils/request'

export default {
  /**
   * 获取列表
   * @param data
   * @returns {Promise}
   */
  getSysDeptList(data) {
    return request.post('/sys_dept/list', data)
  },
  /**
   * 部门列表-不分页
   * @param data
   * @returns {Promise}
   */
  sysDeptList(data) {
    return request.post('/sys_dept/dept_list', data)
  },
  /**
   * 部门-排除指定部门
   * @param exclude_id
   * @returns {Promise}
   */
  listDeptExcludeChild(exclude_id) {
    return request.post('/sys_dept/dept_list', { exclude_id: exclude_id })
  },
  /**
   * 保存
   * @param data
   * @returns {Promise}
   */
  saveSysDept(data) {
    return request.post('/sys_dept/store', data)
  },
  /**
   * 获取部门信息
   * @param data
   * @returns {Promise}
   */
  getSysDept(data) {
    return request.post('/sys_dept/get_info', data)
  },
  /**
   * 删除岗位
   * @returns {Promise}
   * @param id
   */
  deleteSysDept(id) {
    return request.post('/sys_dept/delete', { id: id })
  }
}