export default {
  path: 'rebate',
  component: () => import('@/pages/rebate/index'),
  meta: { title: '返点费用' },
  children: [
    {
      path: ':id/rebate_import',
      name: 'rebate_import',
      component: () => import('@/pages/rebate/importTable.vue'),
      meta: { title: '返点费用导入列表' }
    }
  ]
}

