import * as request from '@/utils/request'

/**
 * 自动生成 api代码【模块名：快递订阅消息】
 * 生成日期：2024/03/05 10:28:19
 * 生成路径: src/api/logistic_subscribe_message.js
 * 生成人：超级管理员
 * 需要在文件src/api/index.js 引入当前文件
 * import logistic_subscribe_message from './logistic_subscribe_message'
 * export default {
 * ...
 * //物流信息订阅
 *    ...logistic_subscribe,
 * }
 */

export default {
    /**
     * @description 获取列表
     * @param {*} data
     * @returns
     */
    getLogisticSubscribeMessageList(data) {
        return request.post('/logistic_subscribe_message/list', data)
    },
    /**
     * @description 保存
     * @param {*} data
     * @returns
     */
    saveLogisticSubscribeMessage(data) {
        return request.post('/logistic_subscribe_message/store', data)
    },
    /**
     * @description 获取详情
     * @param {*} id
     * @returns
     */
    getLogisticSubscribeMessageInfo(id) {
        let data = { id: id }
        return request.post('/logistic_subscribe_message/get_info', data)
    },
    /**
     * @description 根据id删除单条信息
     * @param {*} data
     * @returns
     */
    deleteLogisticSubscribeMessage(id) {
        let data = { id: id }
        return request.post('/logistic_subscribe_message/delete', data)
    },
    /**
     * 导入
     * @param data
     * @returns {Promise}
     */
    importLogisticSubscribeMessages(data) {
        return request.post('/logistic_subscribe_message/import', data)
    },
    /**
     * 导出
     * @param data
     * @returns {Promise}
     */
    exportLogisticSubscribeMessages(data) {
        return request.postBlob('/logistic_subscribe_message/export', data)
    },

}