import * as request from '@/utils/request'

export default {
  /**
   * @description 红人分成模块
   * @date 2022-08-01
   * @param {*} data
   * @returns
   */
  getArtistDivideList(data) {
    return request.post('/artist_divide/list', data)
  },
  /**
   * @description 获取文章分类列表
   * @date 2022-08-01
   * @returns
   */
  getArtistDivide(id) {
    let data = { id: id }
    return request.post('/artist_divide/info', data)
  },
  /**
   * @description 详情修改
   * @date 2022-08-01
   * @returns
   */
  updateArtistDivide(data) {
    return request.post('/artist_divide/save', data)
  },
  /**
   * @description 删除订单详情
   * @date 2022-08-01
   * @returns
   */
  delOrder(id) {
    let data = { order_ids: id }
    return request.post('/artist_order/delete', data)
  },
  /**
   * @description 项目组确认
   * @date 2022-08-01
   * @returns
   */
  artistAudit(data) {
    return request.post('/artist_divide/status', data)
  },
  /**
   * @description 导出订单明细
   * @date 2022-08-01
   * @returns
   */
  importOrder() {
    return request.get('/artist_order/import')
  },

  //
  /**
   * @description 钉钉发送消息
   * @date 2022-08-01
   * @returns
   */
  dingTalkNotifyartistDivide(data) {
    return request.post('/artist_divide/notify', data)
  },
  //
  /**
   * @description 钉钉发送历史查询
   * @date 2022-08-01
   * @returns
   */
  dingTalkNotifyHistory(vale) {
    let data = { info_id: vale }
    return request.post('/artist_divide/task_list', data)
  },
  //
  /**
   * @description 下载模板接口
   * @date 2022-08-01
   * @returns
   */
  downLoadTemplateNotify(data) {
    return request.postBlob('/artist_divide/export/template', data)
  },
  //
  /**
   * @description 批量审核通过
   * @date 2022-08-01
   * @returns
   */
  batchartistAudit(data) {
    return request.post('/artist_divide/batch/check', data)
  },
  //
  /**
   * @description 批量通知信息
   * @date 2022-08-01
   * @returns
   */
  batchNotifysend(data) {
    return request.post('artist_divide/notify_group', data)
  },

  //
  /**
   * @description 获取操作记录
   * @date 2022-08-01
   * @returns
   */
  getArtistDividelog(data) {
    return request.post('/artist_divide_log/list', data)
  },

// /
}