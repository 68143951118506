import * as request from '@/utils/request'

/**
 * 自动生成 api代码【模块名：产品库位库存】
 * 生成日期：2024/05/14 20:25:33
 * 生成路径: src/api/product_whl_code_qty.js
 * 生成人：超级管理员
 * 需要在文件src/api/index.js 引入当前文件
 * import product_whl_code_qty from './product_whl_code_qty'
 * export default {
 * ...
 * // 产品库位库存
 *    ...product_whl_code_qty,
 * }
 */

export default {
  /**
   * @description 获取列表
   * @param {*} data
   * @returns
   */
  getProductWhlCodeQtyList(data) {
    return request.post('/product_whl_code_qty/list', data)
  },
  /**
   * @description 保存
   * @param {*} data
   * @returns
   */
  saveProductWhlCodeQty(data) {
    return request.post('/product_whl_code_qty/store', data)
  },
  /**
   * @description 获取详情
   * @param {*} id
   * @returns
   */
  getProductWhlCodeQtyInfo(id) {
    let data = { id: id }
    return request.post('/product_whl_code_qty/get_info', data)
  },
  /**
   * @description 获取详情
   * @param  where
   * @returns
   */
  queryUserSettingInfo(where) {
    return request.post('/user_setting/query', where)
  },
  /**
   * @description 根据id删除单条信息
   * @param {*} data
   * @returns
   */
  deleteProductWhlCodeQty(id) {
    let data = { id: id }
    return request.post('/product_whl_code_qty/delete', data)
  },
  /**
   * @description 根据id删除单条信息
   * @returns
   * @param id
   * @param num
   */
  checkProductWhlCodeQty(id, num) {
    let data = { id: id, num: num }
    return request.post('/product_whl_code_qty/checkQty', data)
  },

}