import * as request from '@/utils/request'

export default {
    /**
     * @description 获取年总营收一览表
     * @param {*} data
     * @returns
     */
    getStatisticsRevenue(data) {
        return request.post('/dashboard/statistics_revenue', data)
    },

    /**
     * 总营收各平台图表数据
     * @param data
     * @returns {Promise}
     */
    getChartDataRevenue(data) {
        return request.post('/dashboard/chart_data_revenue', data)
    },
    /**
     * 电商销售额情况一览:直播GMV、直播GMV
     * @param data
     * @returns {Promise}
     */
    getStatisticsBs(data) {
        return request.post('/dashboard/statistics_bs', data)
    },
    /**
     * 电商销售额情况一览
     * @param data
     * @returns {Promise}
     */
    getChartDataBs(data) {
        return request.post('/dashboard/chart_data_bs', data)
    },

    /**
     * @description 获取年涨粉一览表
     * @param {*} data
     * @returns
     */
    getStatisticsTrend(data) {
        return request.post('/dashboard/statistics_trend', data)
    },
    /**
     * @description 获取涨粉图表数据
     * @param {*} data
     * @returns
     */
    getChartDataTrend(data) {
        return request.post('/dashboard/chart_data_trend', data)
    },
    /**
     * 签约情况一览:本月新签约红人数、本月解约红人数、截止目前在约红人数、本年度新增红人数
     * @param data
     * @returns {Promise}
     */
    getStatisticsSign(data) {
        return request.post('/dashboard/statistics_sign', data)
    },

    /**
     *签约图表数据
     * @param data
     * @returns {Promise}
     */
    getChartDataSign(data) {
        return request.post('/dashboard/chart_data_sign', data)
    },
    /**
     * 组织情况一览:本年度组织营收情况-部门
     * @param data
     * @returns {Promise}
     */
    initStatisticsDept(data) {
        return request.post('/dashboard/init_statistics_dept', data)
    },
    /**
     * 组织情况一览:本年度组织营收情况-部门
     * @param data
     * @returns {Promise}
     */
    getStatisticsDept(data) {
        return request.post('/dashboard/statistics_dept', data)
    },
    /**
     * 组织情况一览:本年度组织营收情况-项目组
     * @param data
     * @returns {Promise}
     */
    getStatisticsGroup(data) {
        return request.post('/dashboard/statistics_group', data)
    },
    /**
     * 组织情况一览:本年度组织营收情况-成员一览
     * @param data
     * @returns {Promise}
     */
    getStatisticsMember(data) {
        return request.post('/dashboard/statistics_member', data)
    },
    /**
     * 电商数据：每日汇总
     * @param data
     * @returns {Promise}
     */
    getBsDailySummary(data) {
        return request.post('/dashboard/bs_daily_summary', data)
    },

    /**
     * 电商数据：月度汇总
     * @param data
     * @returns {Promise}
     */
    getBsMonthSummary(data) {
        return request.post('/dashboard/bs_month_summary', data)
    },
    /**
     * 导出：电商绩效-月度汇总
     * @param data
     * @returns {Promise}
     */
    exportBsMonthSummary(data) {
        return request.postBlob('/dashboard/bs_month_summary_export', data)
    },
    /**
     * 电商数据：月度汇总
     * @param data
     * @returns {Promise}
     */
    getBsMonthSummaryItems(data) {
        return request.post('/dashboard/bs_month_summary_items', data)
    },

    /**
     * @description 获取年推广支付一览表
     * @param {*} data
     * @returns
     */
    getStatisticsPromotion(data) {
        return request.post('/dashboard/statistics_promotion', data)
    },
    /**
     * 推广支付图表数据
     * @param data
     * @returns {Promise}
     */
    getChartDataPromotion(data) {
        return request.post('/dashboard/chart_data_promotion', data)
    },
}
