/**
 * 自动生成 router代码【模块名：库位】
 * 生成日期：2024/04/25 11:20:49
 * 生成路径: src/router/warehouse_location.js
 * 生成人：超级管理员
 * 需要在文件 src/router/router.js 引入当前文件
 * import warehouse_location from './warehouse_location'
 // 路由详情
 const routes = [
 {
 path: '/',
 component: () => import('@/layouts/BasicLayout'),
 name: 'BasicLayout',
 meta: { title: '首页' },
 redirect: '/myNav',
 children: [
 ...
 warehouse_location,//库位
 ]
 */

export default  {
  path: 'warehouse_location/list',
  name: 'warehouse_location',
  component: () => import('@/pages/warehouse_location/WarehouseLocationList'),
  meta: { title: '库位管理' },
}
