/**
 * 自动生成 router代码【模块名：物流信息订阅记录】
 * 生成日期：2024/03/04 13:48:17
 * 生成路径: src/router/logistic_subscribe.js
 * 生成人：超级管理员
 * 需要在文件 src/router/router.js 引入当前文件
 * import logistic_subscribe from './logistic_subscribe'
 // 路由详情
 const routes = [
 {
     path: '/',
     component: () => import('@/layouts/BasicLayout'),
     name: 'BasicLayout',
     meta: { title: '首页' },
     redirect: '/myNav',
     children: [
     ...
      logistic_subscribe,//物流信息订阅记录
     ]
 */

export default  {
    path: 'logistic_subscribe/list',
    name: 'logistic_subscribe',
    component: () => import('@/pages/logistic_subscribe/LogisticSubscribeList'),
    meta: { title: '物流信息订阅记录管理' },
}
