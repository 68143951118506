/**
 * 自动生成 router代码【模块名：个人设置】
 * 生成日期：2024/04/26 11:07:09
 * 生成路径: src/router/user_setting.js
 * 生成人：超级管理员
 * 需要在文件 src/router/router.js 引入当前文件
 * import user_setting from './user_setting'
 // 路由详情
 const routes = [
 {
     path: '/',
     component: () => import('@/layouts/BasicLayout'),
     name: 'BasicLayout',
     meta: { title: '首页' },
     redirect: '/myNav',
     children: [
     ...
      user_setting,//个人设置
     ]
 */

export default  {
  path: 'user_setting/list',
  name: 'user_setting',
  component: () => import('@/pages/user_setting/UserSettingList'),
  meta: { title: '个人设置管理' },
}
