import * as request from '@/utils/request'

export default {
  /**
   * @description 获取表迁移list
   * @returns promise
   */
  getMigrationList(data) {
    return request.post('/migration/list', data)
  }
}