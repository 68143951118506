export default {
  path: 'mcn_promotion',
  component: () => import('@/pages/mcn_promotion/index'),
  meta: { title: 'MCN推广' },
  children: [
    {
      path: ':id/mcn_promotion',
      name: 'mcn_promotion_import',
      component: () => import('@/pages/mcn_promotion/importTable.vue'),
      meta: { title: 'MCN推广费导入列表' }
    }
  ]
}


