// 对要添加水波纹效果的元素添加ripple类

let eles = document.getElementsByClassName("ripple_content");
// let ripple = document.getElementsByClassName("ripple_");

setTimeout(()=>{
  for (let index = 0; index < eles.length; index++) {
    const element = eles[index];
    // 添加点击事件
    element.addEventListener("click", (e) => {
      let createDiv = document.createElement("div");
      createDiv.className = "ripple-div rippling";
      let maxHW = Math.max(e.target.offsetWidth, e.target.offsetHeight);
      createDiv.style.width = `${maxHW}px`;
      createDiv.style.height = `${maxHW}px`;
      // 动态设置动画时间，容器越大，动画时间越长
      createDiv.style.animation = `rippleAnima ${maxHW / 200}s linear`;
      // 重新计算水波纹的中心位置
      createDiv.style.top = `${e.offsetY - maxHW / 2}px`;
      createDiv.style.left = `${e.offsetX - maxHW / 2}px`;
      // 追加到元素中
      element.appendChild(createDiv);
      setTimeout(() => {
        // 删除元素
        element.removeChild(createDiv);
      }, 600);
    });
  }

},800)
