export default {
  path: 'team',
  name: 'team',
  component: () => import(/* webpackChunkName: "trend-list" */ '@/pages/team/Index'),
  meta: { title: 'MCN团队' },
  children: [
    // {
    //   path: '/',
    //   name: 'team_data',
    //   component: () => import('@/pages/team/Index'),
    //   meta: { title: '团队数据' }
    // },
    // {
    //   path: 'kol_change',
    //   name: 'team_kol_change',
    //   component: () => import('@/pages/team/artist/TeamKolChangeList'),
    //   meta: { title: 'KOL异动记录' }
    // }
    // {
    //     path: 'data',
    //     component: () => import('@/pages/trend/data/TrendList'),
    //     meta: {title: '列表'},
    // }
  ]
}