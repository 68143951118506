export default {
    path: 'departments',
    name: 'departments',
    component: () => import(/* webpackChunkName: "permissions-list" */ '@/pages/departments/Index'),
    meta: { title: '部门管理' },
    children: [
        {
            path: ':dpt_id/manage-members',
            name: 'manage_members',
            component: () => import(/* webpackChunkName: "manage-members" */ '@/pages/departments/ManageMembers'),
            meta: { title: '成员管理' },
        },
        {
            path: ':id/detail',
            name: 'dept_detail',
            component: () => import(/* webpackChunkName: "manage-members" */ '@/pages/departments/DeptDetail'),
            meta: { title: '部门详情' },
        }
    ]
}
