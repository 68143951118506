import * as request from '@/utils/request'

/**
 * 自动生成 api代码【模块名：库存记录】
 * 生成日期：2024/04/25 15:50:00
 * 生成路径: src/api/product_qty_record.js
 * 生成人：超级管理员
 * 需要在文件src/api/index.js 引入当前文件
 * import product_qty_record from './product_qty_record'
 * export default {
 * ...
 * // 库存记录
 *    ...product_qty_record,
 * }
 */

export default {
  /**
   * @description 获取列表
   * @param {*} data
   * @returns
   */
  getProductQtyRecordList(data) {
    return request.post('/product_qty_record/list', data)
  },
  /**
   * @description 保存
   * @param {*} data
   * @returns
   */
  saveProductQtyRecord(data) {
    return request.post('/product_qty_record/store', data)
  },
  /**
   * @description 获取详情
   * @param {*} id
   * @returns
   */
  getProductQtyRecordInfo(id) {
    let data = { id: id }
    return request.post('/product_qty_record/get_info', data)
  },
  /**
   * @description 根据id删除单条信息
   * @param {*} data
   * @returns
   */
  deleteProductQtyRecord(id) {
    let data = { id: id }
    return request.post('/product_qty_record/delete', data)
  },
  /**
   * 导入
   * @param data
   * @returns {Promise}
   */
  importProductQtyRecords(data) {
    return request.post('/product_qty_record/import', data)
  },
  /**
   * 导出
   * @param data
   * @returns {Promise}
   */
  exportProductQtyRecords(data) {
    return request.postBlob('/product_qty_record/export', data)
  },
  /**
   * @description 按产品获取列表
   * @param {*} data
   * @returns
   */
  getProductQtyRecordListByProduct(data) {
    return request.post('/product_qty_record/listByProduct', data)
  },
}