export default {
  path: 'plc_check',
  name: 'plc_check',
  component: () => import('@/pages/publication/Index'),
  meta: {title: 'KOL刊例'},
  children: [
    {
      path: ':id/item',
      name: 'plc_item',
      component: () => import('@/pages/publication/PlcItemDetail'),
      meta: {title: '刊例审核'},
    },
  ]
}
