import * as request from '@/utils/request'

export default {
    /**
     * @description 获取数据列表
     * @param {*} data
     * @returns
     */
    getNetworkIpList(data) {
        return request.post('/network_ip/list', data)
    },

    /**
     * @description 保存
     * @param {*} data
     * @returns
     */
    saveNetworkIp(data) {
        return request.post('/network_ip/store', data)
    },
    /**
     * @description 获取详情
     *
     * @param {*} id
     * @returns
     */
    getNetworkIp(id) {
        let data = { id: id }
        return request.post('/network_ip/get_info', data)
    },

    /**
     * @description 根据id删除单条信息
     *
     * @returns
     */
    delNetworkIp(id) {
        let data = { id: id }
        return request.post('/network_ip/delete', data)
    },
    /**
     * 多行删除
     * @param ids
     * @returns {Promise}
     */
    delMultipleNetworkIp(ids) {
        let data = { ids: ids }
        return request.post('/network_ip/multiple_delete', data)
    },

}
