export default {
  path: 'user',
  name: 'UserList',
  component: () => import('@/pages/user/UserList'),
  meta: { title: '用户管理' },
  children: [
    {
      path: 'log',
      name: 'LoginLog',
      component: () => import('@/pages/user/LoginLog'),
      meta: { title: '登录日志', hideInnerMenu: true }
    },
    {
      path: 'purview',
      name: 'purview',
      component: () => import('@/pages/user/UserPurview'),
      meta: { title: '用户权限', hideInnerMenu: true }
    },
    {
      path: 'profile',
      name: 'UserProfile',
      component: () => import('@/pages/user/profile/Index'),
      meta: { title: '个人中心' }
    },

    {
      path: 'profile_edit',
      name: 'UserProfileEdit',
      component: () => import('@/pages/user/profile/UserProfile'),
      meta: { title: '资料修改' }
    },
    {
      path: 'online',
      name: 'UserOnline',
      component: () => import('@/pages/user/OnLine'),
      meta: { title: '在线用户', hideInnerMenu: true }
    },
    {
      path: ':user_id/detail',
      name: 'UserDetail',
      component: () => import('@/pages/user/UserDetail'),
      meta: { title: '用户详情', hideInnerMenu: true }
    },
    {
      path: 'depart',
      name: 'UserDepart',
      component: () => import('@/pages/user/DepartList'),
      meta: { title: '离职列表', hideInnerMenu: true }
    },
    {
      path: 'ding_user_info',
      name: 'dingUserInfo',
      component: () => import('@/pages/user/ding_talk/DingUserInfoList'),
      meta: { title: '钉钉成员信息', hideInnerMenu: true }
    },
    {
      path: 'user_wechat',
      name: 'userWechat',
      component: () => import('@/pages/user/WechatUserList'),
      meta: { title: '微信用户（招商小程序）', hideInnerMenu: true }
    },
    {
      path: 'member_list',
      name: 'memberList',
      component: () => import('@/pages/team/member/MemberList'),
      meta: { title: '组织成员异动一览', hideInnerMenu: true }
    }
  ]
}
