import * as request from '@/utils/request'

export default {
    /**
     * @description 获取列表
     * @param {*} data
     * @returns
     */
    getRevenueItemList(data) {
        return request.post('/revenue_item/list', data)
    },
    /**
     * 红人营收汇总
     * @param data
     * @returns {Promise}
     */
    getRevenueItemStatistics(data) {
        return request.post('/revenue_item/statistics_data', data)
    },
    /**
     * 柱状图数据
     * @param data
     * @returns {Promise}
     */
    getRevenueItemChartData(data) {
        return request.post('/revenue_item/chart_data', data)
    },
    /**
     * 饼图数据
     * @param data
     * @returns {Promise}
     */
    getRevenueItemPieChartData(data) {
        return request.post('/revenue_item/pie_chart_options', data)
    },
}
