/**
 * 自动生成 router代码【模块名：角色申请】
 * 生成日期：2024/04/12 19:09:32
 * 生成路径: src/router/role_apply_record.js
 * 生成人：超级管理员
 * 需要在文件 src/router/router.js 引入当前文件
 * import role_apply_record from './role_apply_record'
 // 路由详情
 const routes = [
 {
     path: '/',
     component: () => import('@/layouts/BasicLayout'),
     name: 'BasicLayout',
     meta: { title: '首页' },
     redirect: '/myNav',
     children: [
     ...
      role_apply_record,//角色申请
     ]
 */

export default  {
  path: 'role_apply_record/list',
  name: 'role_apply_record',
  component: () => import('@/pages/role_apply_record/RoleApplyRecordList'),
  meta: { title: '角色申请管理' },
}
