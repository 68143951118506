export default {
    path: 'approval',
    name: 'approval',
    meta: { title: '申请' },
    component: () => import('@/pages/approval/permission.vue'),
    children: [
        {
            path: 'permission/list',
            name: 'permissionList',
            component: () => import('@/pages/approval/permission.vue'),
            meta: { title: '权限审批' }
        }
    ]
}
