import * as request from '@/utils/request'

export default {
  /**
   * @description 获取系统日志列表
   * @author YM
   * @date 2020-02-16
   * @param {*} data
   * @returns
   */
  getSysLogList(data) {
    return request.post('/sys_log/list', data)
  },
  /**
   * 清空系统日志
   * @param data
   * @returns {*}
   */
  clearSysLog() {
    return request.post('/sys_log/clear', {})
  },

}