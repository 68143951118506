export default {
    path: 'system',
    name: 'system',
    component: () => import('@/pages/system/Index'),
    meta: {title: '系统监控'},
    children: [
        {
            path: 'monitor/server',
            name: "MonitorServer",
            component: () => import('@/pages/system/server/index'),
            meta: {title: '服务监控'},
        },
        {
            path: 'monitor/cache',
            name: "MonitorCache",
            component: () => import('@/pages/system/cache/index'),
            meta: {title: '缓存监控'},
        },
        {
            path: 'monitor/crontab',
            name: "MonitorCrontab",
            component: () => import('@/pages/system/crontab/CrontabIndex'),
            meta: {title: '定时任务'},
        },
        {
            path: 'oper_log',
            name: "MonitorOperLog",
            component: () => import('@/pages/system/oper_log/SystemOperLogList'),
            meta: {title: '操作日志'},
        },
        {
            path: 'db',
            name: "db",
            component: () => import('@/pages/system/db/index'),
            meta: {title: '数据仓库'},
        }
    ]
}
