import * as request from '@/utils/request'

export default {

  /**
   * 必填筛选条件
   * @description 列表:服饰选品列表
   * @param {*} data
   * @returns
   */
  getLbDressSelectionList(data) {
    return request.post('/lb_dress_selection/list', data)
  },
  /**
   * 添加/修改：服饰选品信息
   * @param data
   * @returns {Promise}
   */
  saveLbDressSelectionInfo(data) {
    return request.post('/lb_dress_selection/store', data)
  },
  cellEditDressSelectionInfo(id, field, val) {
    let data = { id: id, field: field, val: val }
    return request.post('/lb_dress_selection/cell_edit', data)
  },
  /**
   * 查询（根据）：服饰选品信息
   * @param id
   * @returns {Promise}
   */
  getLbDressSelectionInfo(id) {
    return request.post('/lb_dress_selection/get_info', { id: id })
  },

  /**
   *
   * @param id
   * @returns {Promise}
   */
  deleteLbDressSelectionInfo(id) {
    return request.post('/lb_dress_selection/delete', { id: id })
  },
  /**
   * 查询条件
   * @param param
   * @returns {Promise}
   */
  queryLbDressSelectionInfo(param) {
    return request.post('/lb_dress_selection/query_info', param)
  },
  //标品库选品
  /**
   * 标品库选品
   * @param lb_id 排期ID
   * @param product_ids 标品ID
   * @returns {Promise}
   */
  appendLbDressSelection(lb_id, product_ids) {
    return request.post('/lb_dress_selection/append_selections', { lb_id: lb_id, product_ids: product_ids })
  },
  //历史选品库选品
  /**
   * 历史选品库选品
   * @param lb_id 排期ID
   * @param ids 选品ID
   * @returns {Promise}
   */
  appendLbDressSelectionByHistory(lb_id, ids) {
    return request.post('/lb_dress_selection/append_selection_history', { lb_id: lb_id, sids: ids })
  },
  //红人意见
  /**
   * 红人意见
   * @param id 选品ID
   * @param state 红人意见（同上播状态）
   * @returns {Promise}
   */
  updateLbDressSelectionState(id, state) {
    return request.post('/lb_dress_selection/update_state', { ids: id, state: state })
  },
  /**
   * 检验当前用户是否有权限进入服饰选品页面
   * @param lb_id
   * @returns {Promise}
   */
  checkLbDressSelectionView(lb_id) {
    return request.post('/lb_dress_selection/check_view', { lb_id: lb_id })
  },

  /**
   * 日志查看接口
   * @param data
   * @returns {Promise}
   */
  getLbDressSelectionLog(data) {
    return request.post('/lb_dress_selection/log', data)
  },
  /**
   * 机制确认
   * @returns {Promise}
   * @param id
   * @param confirm_status
   */
  changeConfirmLbDressSelection(id, confirm_status) {
    return request.post('/lb_dress_selection/change_confirm', { ids: id, confirm_status: confirm_status })
  },
  /**
   * 导出服饰选品列表
   * @param data
   * @returns {Promise}
   */
  exportDressSelectList(data) {
    return request.postBlob('/lb_dress_selection/export', data)
  },
  /**
   * 【导入批量】保存数据
   * @param data

   * @returns {Promise}
   */
  importCreatePressSelectionInfo(data, lb_id) {
    return request.post('/lb_dress_selection/import_create', { rows: data, lb_id: lb_id })
  },
  /**
   * 获取预览数据列
   * @param data
   * @returns {Promise}
   */
  getImportCreatePressSelectionCols() {
    return request.post('/lb_dress_selection/import_cols', {})
  },
  /**
   *
   * @description 获取数据列表：有已选择过得
   * @param {*} data
   * @returns
   */
  getProductDressSelectList(data) {
    return request.post('/lb_dress_selection/get_product_dress', data)
  },
  /**
   * 保存-确认选品排序
   */
  saveDressOrder(data) {
    return request.post('/lb_dress_selection/order', data)
  },
  // 反向需求接口
  //单元格数据保存
  cellSaveLbDressScheduleDemandItemInfo(data) {
    return request.post('/lb_schedule_dress/single_save_demand', data)
  },
  //需求明细CURD
  saveLbDressScheduleDemandItem(data) {
    return request.post('/lb_schedule_dress/save_demand_item', data)
  },
  /**
   *
   * @param id
   * @returns {Promise}
   */
  delLbDressScheduleDemandItem(id) {
    let data = { id: id }
    return request.post('/lb_schedule_dress/del_demand_item', data)
  },
  getLbDressScheduleDemandItemList(data) {
    return request.post('/lb_schedule_dress/list_demand_item', data)
  },
  /**
   * 导出红人选品会明细
   * @param data
   * @returns {Promise}
   */
  exportLbDressScheduleDemandItem(data) {
    return request.postBlob('/lb_schedule_dress/export_demand_item', data)
  },
  /**
   *批量删除
   * @param ids
   * @returns {Promise}
   */
  delBatchLbDressScheduleDemandItem(ids) {
    let data = { ids: ids }
    return request.post('/lb_schedule_dress/del_batch_demand_item', data)
  },
  /**
   * 服饰规则选品文章获取
   * @returns {Promise}
   */
  getLbDressScheduleRuleArticle() {
    let data = {}
    return request.post('/lb_schedule_dress/get_rule_article', data)
  },
}
