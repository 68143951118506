export default {
    path: 'revenue',
    name: 'revenue',
    component: () => import(/* webpackChunkName: "revenue-list" */ '@/pages/revenue/Index'),
    meta: { title: '营收数据' },
    children: [
        {
            path: 'enter',
            name: 'revenue_enter',
            component: () => import('@/pages/revenue/enter/RevenueList'),
            meta: { title: '营收数据录入' },
        },
        {
            path: 'data',
            name: 'revenue_data',
            component: () => import('@/pages/revenue/data/RevenueList'),
            meta: { title: '营收数据列表' },
        },
        {
            path: 'index',
            name: 'revenue_index',
            component: () => import('@/pages/revenue/data/Index'),
            meta: { title: '汇总' },
        },
        {
            path: 'statistics',
            name: 'revenue_order_statistics',
            component: () => import('@/pages/revenue/order/OrderStatistics'),
            meta: { title: '实时订单排行' },
        }
    ]
}
