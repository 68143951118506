import * as request from '@/utils/request'

/**
 * 自动生成 api代码【模块名：品牌对接记录】
 * 生成日期：2024/08/21 11:33:50
 * 生成路径: src/api/brand_join_record.js
 * 生成人：超级管理员
 * 需要在文件src/api/index.js 引入当前文件
 * import brand_join_record from './brand_join_record'
 * export default {
 * ...
 * // 品牌对接记录
 *    ...brand_join_record,
 * }
 */

export default {
  /**
   * @description 获取列表
   * @param {*} data
   * @returns
   */
  getBrandJoinRecordList(data) {
    return request.post('/brand_join_record/list', data)
  },
  /**
   * @description 保存
   * @param {*} data
   * @returns
   */
  saveBrandJoinRecord(data) {
    return request.post('/brand_join_record/store', data)
  },
  /**
   * @description 获取详情
   * @param {*} id
   * @returns
   */
  getBrandJoinRecordInfo(id) {
    let data = { id: id }
    return request.post('/brand_join_record/get_info', data)
  },
  /**
   * @description 获取详情
   * @param  where
   * @returns
   */
  queryUserSettingInfo(where) {
    return request.post('/user_setting/query', where)
  },
  /**
   * @description 根据id删除单条信息
   * @param {*} data
   * @returns
   */
  deleteBrandJoinRecord(id) {
    let data = { id: id }
    return request.post('/brand_join_record/delete', data)
  },
  /**
   * 导入
   * @param data
   * @returns {Promise}
   */
  importBrandJoinRecords(data) {
    return request.post('/brand_join_record/import', data)
  },
  /**
   * 导出
   * @param data
   * @returns {Promise}
   */
  exportBrandJoinRecords(data) {
    return request.postBlob('/brand_join_record/export', data)
  },

}
