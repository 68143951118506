import * as request from '@/utils/request'

export default {
    /**
     * @description 获取列表
     * @param {*} data
     * @returns
     */
    getLogisticsList(data) {
        return request.post('/logistic/list', data)
    },
    /**
     * @description 保存-修改-物公司接口
     * @param {*} data
     * @returns
     */
    saveLogisticsCompany(data) {
        return request.post('/logistic/store', data)
    },
    /**
     * @description 删除-物公司接口
     * @param {*} data
     * @returns
     */
    deleteLogisticsCompany(data) {
        return request.post('/logistic/delete', data)
    },
    /**
     * @description 获取物流信息列表
     * @param {*} data
     * @returns
     */
    getLogisticsInfoList(data) {
        return request.post('/logistic_info/list', data)
    },
    /**
     * @description 获取物流信息列表-用单号搜索
     * @param {*} data
     * @returns
     */
    searchLogisticsInfoList(data) {
        return this.syncQueryLogisticsInfo(data)
    },

    /**
     * 查询（并同步快递100物流信息）
     * @param data
     * @returns {Promise}
     */
    syncQueryLogisticsInfo(data) {
        if (['shunfengkuaiyun', 'shunfeng'].indexOf(data.com) > -1) {
            this.$notify.warning('顺丰物流信息查询，收件人或寄件人的联系方式[phone]，必填！！！')
            return false
        }
        return request.post('/logistic_info/syn_query', data)
    }
}
