import * as request from '@/utils/request'

export default {
  /**
   * @description 获取列表
   * @param {*} data
   * @returns
   */
  getEcpMerchantMonthList(data) {
    return request.post('/ecp_merchant_month/list', data)
  },

  /**
   * @description 保存
   * @param {*} data
   * @returns
   */
  saveEcpMerchantMonth(data) {
    return request.post('/ecp_merchant_month/store', data)
  },
  /**
   * @description 获取详情
   * @author YM
   * @date 2019-02-11
   * @param {*} id
   * @returns
   */
  getEcpMerchantMonthInfo(id) {
    let data = { id: id }
    return request.post('/ecp_merchant_month/get_info', data)
  },
  /**
   * @description 根据id删除单条信息
   * @author YM
   * @date 2019-01-19
   * @param {*} data
   * @returns
   */
  deleteEcpMerchantMonth(data) {
    // let data = {id:id}
    return request.post('/ecp_merchant_month/delete', data)
  },
  //部门的专员月度奖金总额
  getEcpMerchantTotalBonus(data) {
    return request.post('/ecp_merchant_month/total_bonus', data)
  },
  //团队完成系数-总监奖金计算
  getEcpMerchantTeamQuotient(data) {
    return request.post('/ecp_merchant_month/team_quotient', data)
  }

}