import * as request from '@/utils/request'

export default {
  /**
   * @description 获取列表
   * @param {*} data
   * @returns
   */
  getDressBrandList(data) {
    return request.post('/dress_brand/list', data)
  },
  /**
   * 获取服饰品牌信息
   * @param data
   * @returns {Promise}
   */
  getDressBrandInfo(data) {
    return request.post('/dress_brand/get_info', data)
  },

  /**
   * 保存服饰品牌信息
   * @param data
   * @returns {Promise}
   */
  saveDressBrandInfo(data) {
    return request.post('/dress_brand/store', data)
  },
  /**
   * 删除服饰品牌库数据
   * @param id
   * @returns {Promise}
   */
  deleteDressBrandInfo(id) {
    return request.post('/dress_brand/delete', { id: id })
  },
  /**
   * 导入服饰品牌信息
   * @param data
   * @returns {Promise}
   */
  importDressBrand(data) {
    return request.post('/dress_brand/import', data)
  },
  /**
   * 导出服饰品牌信息
   * @param data
   * @returns {Promise}
   */
  exportDressBrand(data) {
    return request.postBlob('/dress_brand/export', data)
  },
  /**
   * 设置查看权限
   * @returns {Promise}
   * @param ids
   * @param dept_ids
   */
  setDressBrandViewRange(ids, dept_ids) {
    return request.post('/dress_brand/set_view_range', { ids: ids, dept_ids: dept_ids })
  },
  /**
   * 获取查看权限
   * @param id
   * @returns {Promise}
   */
  getDressBrandViewRange(id) {
    return request.post('/dress_brand/get_view_range', { id: id })
  },
  /**
   * 日志查看接口
   * @param data
   * @returns {Promise}
   */
  getDressBrandLog(data) {
    return request.post('/dress_brand/log', data)
  },

}
