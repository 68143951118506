import * as request from '@/utils/request'

export default {
    /**
     *
     * @param data
     * @returns {Promise}
     */
    getSystemDbInfo(data) {
        return request.post('/db/get_info', data)
    },
    /**
     * @description 获取表格列表
     * @returns promise
     */
    getSystemDbList(data) {
        return request.post('/db/list', data)
    },
    /**
     * @description 获取表格列信息
     * @param data
     * @returns {Promise}
     */
    getSystemDbCols(data) {
        return request.post('/db/table_cols', data)
    },
    /**
     * 表碎片优化
     * @param data
     * @returns {Promise}
     */
    optimizeSystemDbTable(data) {
        return request.post('/db/optimize', data)
    },
    /**
     *
     * @param data
     * @returns {Promise}
     */
    getSystemDbTableChartData(data) {
        return request.post('/db/chart_data', data)
    },
    /**
     * 后台管理数据库-常规代码预览
     * @param table_name
     * @returns {Promise}
     */
    getSystemDbGenPreview(table_name) {

        return request.post('/db/gen_preview', { table_name: table_name })
    }
}