import * as request from '@/utils/request'

export default {
    /**
     * 获取红人
     * @returns
     */
    mcnPromotionGetKolList(data) {
        return request.post('/kol_analy_mcn/list', data)
    },
    /**
     * 修改数据
     * @returns
     */
    mcnPromotionEdit(data) {
        return request.post('/kol_analy_mcn/save', data)
    },
    /**
     * 刪除數據
     * @returns
     */
    mcnPromotionDel(data) {
        return request.post('/kol_analy_mcn/delete', data)
    },
    /**
     * 获取导入列表
     * @returns
     */
    mcnPromotiongetImportList(data) {
        return request.post('/kol_analy_mcn/list', data)
    },
    /**
     * 撤回导入列表
     * @returns
     */
    mcnPromotionDelImport(data) {
        return request.post('/kol_analy_mcn/delete_import', data)
    },
    /**
     * @description 导出MCN推广模板
     * @param {*} data
     * @returns
     */
    exportMcnPromotionTemplate() {
        return request.postBlob('/kol_analy_mcn/download')
    }
}
