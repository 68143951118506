import * as request from '@/utils/request'

export default {

  /**
   * 必填筛选条件
   * @description 获取产品库数据列表
   * @param {*} data
   * @returns
   */
  getProductList(data) {
    return request.post('/product/list', data)
  },

  getProductLog(data) {
    return request.post('/product/log', data)
  },

  /**
   * 保存
   * @description 保存产品库数据
   * @param {*} data
   * @returns
   */
  saveProduct(data) {
    return request.post('/product/store', data)
  },
  /**
   * 保存产品信息-分段
   * @param formName
   * @param data
   * @returns {Promise}
   */
  saveProductPart(formName, data) {
    return request.post('/product/save', { form_name: formName, data: data })
  },
  /**
   * 获取产品库
   * @param {*} id
   * @returns {Promise}
   */
  getProductInfo(id) {
    let data = { id: id }
    return request.post('/product/get_info', data)
  },
  /**
   * 产品寄样记录
   * {id:1,current_page:1,page_size:10}
   * console.log('寄样记录')
   * await this.$api.getProductSpecimenRecord({ id: 253 })
   * @param data
   * @returns {Promise}
   */
  getProductSpecimenRecord(data) {
    if (!data.product_id) {
      // this.$notify.warning('产品ID必传')
      return false
    }
    return request.post('/product/specimen_list', data)
  },
  getProductLbRecord(data) {
    if (!data.product_id) {
      // this.$notify.warning('产品ID必传')
      return false
    }
    return request.post('/product/get_lb_record', data)
  },
  /**
   * 删除产品库
   * @param id
   * @returns {Promise}
   */
  delProduct(id) {
    let data = { id: id }
    return request.post('/product/delete', data)
  },

  /**
   * 恢复删除的商品
   * @param ids
   * @returns {*}
   */
  unDelProduct(ids) {
    return request.post('/product/un_delete', { ids })
  },

  /**
   * 转移商品
   * @param data
   * @returns {*}
   */
  transferProduct(data) {
    return request.post('/product/transfer', data)
  },

  /**
   * 批量设置服饰为标品
   * @param data
   * @returns {*}
   */
  batchSetStandardGoods(data) {
    return request.post('/product_dress/batch_set_standard_goods', data)
  },

  /**
   * 导出
   * @param data
   * @returns {Promise}
   */
  exportProduct(data) {
    return request.postBlob('/product/export', data)
  },
  /**
   * 异步导出：返回任务ID
   * 根据任务可下载生成的文件
   * @param data
   * @returns {Promise}
   */
  asyncExportProduct(data) {
    return request.post('/product/async_export', data)
  },
  /**
   * 产品手卡
   * @param data
   * @returns {Promise}
   */
  exportProductPpt(data) {
    return request.postBlob('/product/export_ppt', data)
  },
  /**
   * 下载导入模板
   * @param data
   * @returns {Promise}
   */
  downloadImportTpl(data) {
    return request.postBlob('/product/import_tpl', data)
  },
  /**
   * 产品负责人
   * @returns {Promise}
   */
  productLeaderOptions(type) {
    return request.post('/product/leader_options', { type: type })
  },
  /**
   * 选品负责人
   * @returns {Promise}
   */
  productSelectionLeaderOptions() {
    return request.post('/product/leader_options', { type: 'selection' })
  },
  /**
   * 产品交接
   * @param data
   * @returns {Promise}
   */
  productHandOver(data) {
    return request.post('/product/hand_over', data)
  },

  /**
   * 产品负责人关联
   * @param data
   * @returns {Promise}
   */
  productLeaderAbout(data) {
    return request.post('/product/leader_about', data)
  },

  //商品选品列表
  getLbProductSelectionList(data) {
    return request.post('/product/selection_list', data)
  },
  //商品选品数量（未处理）
  getLbProductSelectionCount(data) {
    return request.post('/product/selection_count', data)
  },
  //修改选品的处理状态
  changeLbProductSelectionStatus(id, is_done) {
    return request.post('/product/change_selection_status', { id: id, is_done: is_done })
  },
  //新增产品寄样信息
  addLbProductSpecimenCommit(data) {
    return request.post('/product_specimen/commit', data)
  },
  //通过单号查询快递公司
  getLbProductSpecimenNuCom(data) {
    return request.post('/product_specimen/get_nu_com', data)
  },
  //删除
  deleteLbProductSpecimenCommit(data) {
    return request.post('/product_specimen/delete', data)
  },
  // 获取产品版本列表
  getProductVerList(data) {
    return request.post('/product_version/list', data)
  },
  // 获取版本详情
  getProductVerDetail(data) {
    return request.post('/product_version/detail', data)
  },
  // 获取产品排期
  getProductSchedules(data) {
    return request.post('/product_version/schedules_list', data)
  },
  // 同步排期内产品
  syncScheduleProduct(data) {
    return request.post('/product_version/sync_schedule_product', data)
  }
}
