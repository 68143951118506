import * as request from '@/utils/request'

export default {
  /**
   * @description 获取红人关联列表
   * @param {*} data
   * @returns
   */
  getKolMapperList(data) {
    return request.post('/ecp_kol_mapper/list', data)
  },

  /**
   * @description 保存
   * @param {*} data
   * @returns
   */
  saveKolMapper(data) {
    return request.post('/ecp_kol_mapper/store', data)
  },
  /**
   * @description 获取文章详情
   * @author YM
   * @date 2019-02-11
   * @param {*} id
   * @returns
   */
  getKolMapperInfo(id) {
    let data = {id:id}
    return request.post('/ecp_kol_mapper/get_info', data)
  },
  /**
   * @description 根据id删除单条信息
   * @author YM
   * @date 2019-01-19
   * @param {*} data
   * @returns
   */
  deleteKolMapper(id) {
    let data = { id: id }
    return request.post('/ecp_kol_mapper/delete', data)
  },
  /**
   * @description 根据id删除单条信息
   * @author YM
   * @date 2019-01-19
   * @param {*} data
   * @returns
   */
  getKolMapperSearch(data) {

    return request.post('/ecp_kol_mapper/artist_list', data)
  }

// /ecp_kol_mapper/artist_list

}
