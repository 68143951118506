import * as request from '@/utils/request'

export default {
  /**
   * @description 获取列表
   * @param {*} data
   * @returns
   */
  getDingUserInfoList(data) {
    return request.post('/ding_user_info/list', data)
  },

  /**
   * @description 获取信息
   * @returns {Promise}
   */
  getDingUserInfoInfo(dept_id) {
    return request.post('/ding_user_info/get_info', { dept_id: dept_id })
  },
  /**
   * 获取钉钉实际人数
   * @param dept_id
   * @returns {Promise}
   */
  getDingUserRealCount() {
    return request.post('/ding_user_info/real_count', {})
  },

  /**
   * 获取在职无账号人员
   * @returns {Promise}
   */
  getNoAccountUsers() {
    return request.post('/ding_user_info/get_no_account_users', {})
  },
  getNoAccountUserCount() {
    return request.post('/ding_user_info/get_no_account_user_count', {})
  },
  /**
   * 注册已同步当时未注册的用户
   * @returns {Promise}
   */
  registerAccountByDingUserInfo() {
    return request.post('/ding_user_info/register', {})
  },
  /**
   * 成员离职记录
   * @param data
   * @returns {Promise}
   */
  getUserLeaveList(data) {
    return request.post('/ding_user_leave_info/list', data)
  },
  /**
   * 离职成员记录删除
   * @returns {Promise}
   */
  getDimissionUserInfo(userid) {
    return request.post('/ding_user_leave_info/get_dimission_user_info', { userid: userid })
  },
  /**
   * 重新计算离职状态
   * @param userid
   * @returns {Promise}
   */
  calcLeaveStatus(userid) {
    return request.post('/ding_user_leave_info/calc_leave_status', { userid: userid })
  },

  delLeaveInfo(id) {
    return request.post('/ding_user_leave_info/delete', { id: id })
  },
  /**
   * 删除用户的钉钉信息
   * @param id
   * @returns {Promise}
   */
  deleteDingTalkUserInfo(id) {
    return request.post('/ding_user_info/delete', { id: id })
  }
}
