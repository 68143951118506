import * as request from '@/utils/request'

export default {
  /**
   * @description 获取微信用户列表
   * @returns promise
   */
  getWxUserList(data) {
    return request.post('/wx_user/list', data)
  },
  getWxUserChartData(data) {
    return request.post('/wx_user/get_chart_data', data)
  },
}