import * as request from '@/utils/request'

export default {
  /**
   * @description 获取分组列表
   * @returns promise
   */
  getMcnGroupList(data) {
    return request.post('/mcn_group/list', data)
  },
  /**
   * @description 获取分组列表
   * @returns promise
   */
  getMcnGroupTreeList() {
    return request.post('/mcn_group/tree_list')
  },
  /**
   * @description 保存，新建、编辑的保存都走此方法，区别是有没有主键id
   * @param {*} data
   * @returns
   */
  saveMcnGroup(data) {
    return request.post('/mcn_group/store', data)
  },

  /**
   * @description 根据id获取单条信息，编辑使用
   * @param {*} data
   * @returns
   */
  getMcnGroupInfo(id) {
    let data = { id: id }
    return request.post('/mcn_group/get_info', data)
  },
  /**
   * @description 根据id删除单条信息
   * @param {*} data
   * @returns
   */
  deleteMcnGroup(data) {
    return request.post('/mcn_group/delete', data)
  },
  /**
   * @description 根据id删除单条信息
   * @param {*} data
   * @returns
   */
  deleteMcnGroupByDptIds(data) {
    return request.post('/mcn_group/delete_by_dpt_ids', data)
  },

  /**
   * 部门信息
   * @returns {Promise}
   */
  getDepartmentsData() {
    return request.post('/mcn_group/departments')
  },
  /**
   * 保存MCN分组，穿梭框
   * @param data
   * @returns {Promise}
   */
  saveMcnGroupByDptIds(data) {
    return request.post('/mcn_group/store_by_dpt_ids', data)
  },
  /**
   * 我的MCN分组-绑定分组
   * @param type ['enter','data']
   * @returns {Promise}
   */
  getMyUserMcnGroup(type) {
    let data = { type: type }
    return request.post('/mcn_group/my_user_groups', data)
  },
  /**
   * 设置MCN分组
   * @returns {Promise}
   */
  saveDepthMcnGroup(data) {
    return request.post('/mcn_group/save_depth', data)
  },
  //获取MCN项目组的联动数据
  getMcnGroupDeptOption(type) {
    let data = { type: type }
    return request.post('/mcn_group/option_data', data)
  },
  /**
   *
   * @param type
   * @returns {Promise}
   */
  getMcnGroupDepts(data) {
    return request.post('/mcn_group/get_mcn_depts', data)
  },
}
