import * as request from '@/utils/request'

export default {
  /**
   * @description 获取列表
   * @param {*} data
   * @returns
   */
  getexternKolList(data) {
    return request.post('/kol_info/list', data)
  },

  /**
   * @description 批量添加红人提报
   * @param {*} data
   * @returns
   */
  saveKolProject(data) {
    return request.post('/kol_project/save', data)
  },

  /**
   * @description 新增红人资源库
   * @param {*} data
   * @returns
   */
  saveKol(data) {
    return request.post('/kol_info/save', data)
  },
  /**
   * @description 批量导入红人信息
   * @param {*} data
   * @returns
   */
  importKol(data) {
    return request.post('/kol_info/import', data)
  },
  /**
   * @description 删除KOL
   * @param {*} data
   * @returns
   */
  deleteKol(data) {
    return request.post('/kol_info/delete', data)
  },
  /**
   * @description 导出红人模板
   * @param {*} data
   * @returns
   */
  exportKolTemplate(data) {
    return request.postBlob('/kol_info/export-template',data)
  },

  /**
   * @description 删除红人资源库
   * @param {*} data
   * @returns
   */
  delKol(data) {
    return request.post('/kol_info/delete', data)
  },
  /**
   * @description 红人资料库
   * @param {*} data
   * @returns
   */
  resourceList(data) {
    return request.post('/kol_info/resource_list', data)
  },
  /**
   * @description 获取红人资源库详情
   * @param {*} data
   * @returns
   */
  getKoldetails(data) {
    return request.post('/kol_info/info', data)
  },
  /**
   * @description 获取红人资源库详情-平台
   * @param {*} data
   * @returns
   */
  getKolplatform(data) {
    return request.post('/kol_platform/list', data)
  },
  /**
   * @description 获取红人资源库详情-合作记录
   * @param {*} data
   * @returns
   */
  getKolhistory(data) {
    return request.post('/kol_info/co_history', data)
  },
  /**
   * @description 新增红人平台数据
   * @param {*} data
   * @returns
   */
  saveKolplatform(data) {
    return request.post('/kol_platform/save', data)
  },
  /**
   * @description 删除红人平台数据
   * @param {*} data
   * @returns
   */
  delKolplatform(data) {
    return request.post('/kol_platform/delete', data)
  },
//
  /**
   * 导出提报
   * @param data
   * @returns {Promise}
   */
  downloadKolProject(data) {
    return request.postBlob('/kol_project/export', data)
  },
  /**
   * @description 红人资源库-项目列表
   * @param {*} data
   * @returns
   */
  KolProjectList(data) {
    return request.post('/kol_project/list', data)
  },
  /**
   * @description 红人资源库-项目列表
   * @param {*} data
   * @returns
   */
  KolProjectInfo(data) {
    return request.post('/kol_project/info', data)
  },
  /**
   * @description 红人资源库-项目详情
   * @param {*} data
   * @returns
   */
  KolProjectDetails(data) {
    return request.post('kol_project/details', data)
  },
  //
  /**
   * @description 地址选择器-省份
   * @param {*} data
   * @returns
   */
  getRegion() {
    return request.post('/region/province')
  },
  /**
   * @description 地址选择器-市区
   * @param {*} data
   * @returns
   */
  region_children(data) {
    return request.post('/region/children', data)
  },
  /**
   * @description 网红开通平台下拉
   * @param {*} data
   * @returns
   */
  kol_plates() {
    return request.post('/platform/kol_plates')
  },
  /**
   * @description 项目详情操作记录
   * @param {*} data
   * @returns
   */
  kol_project_log(data) {
    return request.post('/kol_project_log/list',data)
  },
}