import * as request from '@/utils/request'

export default {
  /**
   * @description 获取团队异动一览:分页
   * @param {*} data
   * @returns
   */
  getTeamChangeList(data) {
    return request.post('/team_change/list', data)
  },
  /**
   * 异动数据集合：一整年数据量：12*3
   * @param data
   * @returns {Promise}
   */
  getTeamChangeAll(data) {
    return request.post('/team_change/all', data)
  },

  /**
   * 团队异动情况详情-指定月份
   * @param params
   * @returns {Promise}
   */
  getTeamChangeViewDetail(params) {

    return request.post('team_change/view_detail', {
      year: params['year'],
      month: params['month'],
      dept_id: params['dept_id']
    })
  }

}