export default {
  path: 'artist_commission',
  name: 'artist_commission',
  component: () => import('@/pages/artist_commission/CommissionList'),
  meta: { title: '红人分成' },
  children: [
    {
      path: 'detail/:id',
      name: 'artist_commission_detail',
      component: () => import('@/pages/artist_commission/CommissionDetail'),
      meta: { title: '详情' },
    },

  ],
}
