import * as request from '@/utils/request'

export default {
  /**
   * @description 获取列表
   * @param {*} data
   * @returns
   */
  getSettlementList(data) {
    return request.post('/settle_project/list', data)
  },
  /**
   * 下拉选择
   * @param data
   * @returns {Promise}
   */
  optionsSettleSearch(data) {
    return request.post('/settle_project/serarch_like', data)
  },
  /**
   * @description 保存项目
   * @param data
   * @param cmd
   * @returns {Promise}
   */
  storeSettlement(data, cmd = 'create') {
    return request.post(`/settle_project/${cmd}`, data)
  },
  /**
   * @description 项目详情
   * @param data
   * @returns {Promise}
   */
  detailSettlement(data) {
    return request.post('/settle_project/info', data)
  },
  /**
   * @description 进度表单行保存
   * @param data
   * @returns {Promise}
   */
  settlementPlanSave(data) {
    return request.post('/settle_proj_detail/create', data)
  },
  /**
   * @description 日志
   * @param data
   * @returns {Promise}
   */
  settlementPlanLog(data) {
    return request.post('/settle_log/list', data)
  },
  /**
   * 列表：未匹配的微信用户提交的信息
   * @param data
   * @returns {*}
   */
  settlementForMatch(data) {
    return request.post('/settle_proj_register/list', data)
  },
  /**
   * 绑定微信用户提交的信息
   * @param data
   * @returns {*}
   */
  settlementDoMatch(data) {
    return request.post('/settle_proj_register/must_bind', data)
  },
  /**
   * 修改
   * @param data
   * @returns {Promise}
   */
  settlementPlanUpdate(data) {
    return request.post('/settle_proj_detail/update', data)
  },
  /**
   * @description 进度表批量打款完成
   * @param data
   * @returns {Promise}
   */
  settlementPlanStatus(data) {
    return request.post('/settle_proj_detail/status', data)
  },
  /**
   * @description 项目完成
   * @param data
   * @returns {Promise}
   */
  settlementStatus(data) {
    return request.post('/settle_project/status', data)
  },
  /**
   * @description 进度表单行删除
   * @param data
   * @returns {Promise}
   */
  settlementPlanDelete(data) {
    return request.post('/settle_proj_detail/delete', data)
  },
  /**
   *
   * @param data
   * @returns {Promise}
   */
  settlementPlanUnbind(data) {
    return request.post('/settle_proj_detail/unbind', data)
  },
  /**
   * 撤销打款
   * @param data
   * @returns {Promise}
   */
  settlementPlanUnPay(data) {
    return request.post('/settle_proj_detail/un_pay', data)
  },
  /**
   * 导入后插入接口
   * @param data
   * @returns {Promise}
   */
  settlementPlanSaveImport(data) {
    return request.post('/settle_proj_detail/save_import', data)
  },
  /**
   *
   * @param data
   * @returns {Promise}
   */
  settlementPlanList(data) {
    return request.post('/settle_proj_detail/list', data)
  },
  /**
   * @description 删除一列
   * @param {*} data
   * @returns
   */
  delSettlement(data) {
    return request.post('/settle_project/delete', data)
  },
  /**
   * @returns {Promise}
   */
  downLoadFile(data, path) {
    return request.postBlob(path, data)
  }
}
