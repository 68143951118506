import * as request from '@/utils/request'

export default {
  /**
   * 必填筛选条件
   * @description 获取数据列表
   * @param {*} data
   * @returns
   */
  getProductDressList(data) {
    return request.post('/product_dress/list', data)
  },
  
  /**
   * 获取
   * @param id
   * @returns {Promise}
   */
  getProductDressInfo(id) {
    return request.post('/product_dress/get_info', { id: id })
  },
  /**
   * 保存
   * @param data
   * @returns {Promise}
   */
  saveProductDressInfo(data) {
    return request.post('/product_dress/save', data)
  },
  /**
   * 【导入批量】保存数据
   * @param data

   * @returns {Promise}
   */
  importCreateProductDressInfo(data) {
    return request.post('/product_dress/import_create', { rows: data })
  },
  /**
   * 【选品、定品】批量添加数据
   * @param data
   * @param opt_name 选品会、直播定品
   * @returns {Promise}
   */
  batchCreateProductDressInfo(data, opt_name) {
    return request.post('/product_dress/batch_create', { rows: data, opt_name: opt_name })
  },
  /**
   * 删除
   * @param id
   * @returns {Promise}
   */
  deleteProductDressInfo(id) {
    return request.post('/product_dress/delete', { id: id })
  },

  /**
   * 导出
   * @param data
   * @returns {Promise}
   */
  exportProductDress(data) {
    return request.postBlob('/product_dress/export', data)
  },
  /**
   *
   * @param data
   * @returns {Promise}
   */
  getProductDressLog(data) {
    return request.post('/product_dress/log', data)
  }
}
