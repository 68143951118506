export default {
  path: 'sys_auth',
  name: 'sys_auth',
  component: () => import('@/pages/sys_auth/Index'),
  meta: { title: '系统' },
  children: [
    {
      path: 'apply_role',
      name: 'apply_role',
      component: () => import('@/pages/sys_auth/ApplyRole'),
      meta: { title: '角色申请' }
    }
  ]

}
