export default {
    path: 'shop_data',
    name: 'shopDataIndex',
    component: () => import(/* webpackChunkName: "trend-list" */ '@/pages/shop_data/Index'),
    meta: { title: '店铺数据' },
    children: [
        {
            path: 'enter',
            name: "ShopDataEnter",
            component: () => import('@/pages/shop_data/ShopDataEnter'),
            meta: { title: '店铺数据录入' },
        },
        {
            path: 'data',
            name: "ShopDataList",
            component: () => import('@/pages/shop_data/ShopDataList'),
            meta: { title: '店铺数据列表' },
        }
    ]
}
