import * as request from '@/utils/request'

export default {
  /**
   * @description 创建/修改通用付款申请
   * @author YM
   * @date 2019-01-10
   * @returns promise
   */
  normarlPayment(data) {
    return request.post('/kol_normarl_payment/save_template', data)
  },
  /**
   * @description 创建/修改通用付款申请
   * @author YM
   * @date 2019-01-10
   * @returns promise
   */
  normarlReimbursement(data) {
    return request.post('/kol_normarl_reimbursement/save_template', data)
  },
  /**
   * @description 创建/修改备用金申请
   * @author YM
   * @date 2019-01-10
   * @returns promise
   */
  pettyCash(data) {
    return request.post('/kol_petty_cash/save_template', data)
  },
  /**
   * @description 创建/修改推广投放付款申请

   * @author YM
   * @date 2019-01-10
   * @returns promise
   */
  kolApprove(data) {
    return request.post('/kol-approve/save_template', data)
  },

  /**
   * @description 创建/修改红人费用报销申请
   * @author YM
   * @date 2019-01-10
   * @returns promise
   */
  kolExpense(data) {
    return request.post('/kol-expense/save_template', data)
  },
  /**
   * @description 创建/修改差旅报销申请
   * @author YM
   * @date 2019-01-10
   * @returns promise
   */
  saveTravel(data) {
    return request.post('/kol_travel/save_template', data)
  },
  /**
   * @description Oa模板列表
   * @author YM
   * @date 2019-01-10
   * @returns promise
   */
  oaTemplateList(data) {
    return request.post('/kol_template/list', data)
  },
  /**
   * @description Oa模板统计申请
   * @author YM
   * @date 2019-01-10
   * @returns promise
   */
  countTemplateList(data) {
    return request.post('/kol_template/tpl_apply_count', data)
  },
  /**
   * @description Oa模板設置
   * @author YM
   * @date 2019-01-10
   * @returns promise
   */
  oaTemplateGetInfo(data) {
    return request.post('/process_instance_settings/get_info', data)
  },
  /**
   * @description Oa模板設置
   * @author YM
   * @date 2019-01-10
   * @returns promise
   */
  oaTemplateSaveInfo(data) {
    return request.post('/process_instance_settings/store', data)
  },
  /**
   *
   * @param data
   * @returns {Promise}
   */
  oaTemplateProcessSetting(data) {
    return request.post('/process_instance_settings/get_process_setting', data)
  },
  /**
   *
   * @param data 删除
   * @returns {Promise}
   */
  oaTemplatedelSetting(id) {
    return request.post('/process_instance_settings/delete',{id:id})
  }
// /process_instance_settings/store
}
