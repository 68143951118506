import * as request from '@/utils/request'

export default {
    /**
     * 获取分页列表
     * @returns
     */
    getCrontabPageList(params = {}) {
        return request.post('setting_crontab/list', params)
    },

    /**
     * 获取任务日志列表
     * @returns
     */
    getCrontabLogPageList(params = {}) {
        return request.post('setting_crontab/logPageList', params)
    },

    /**
     * 删除定时任务日志
     * @returns
     */
    deleteCrontabLog(ids) {
        return request.post('setting_crontab/deleteCrontabLog', {ids: ids})
    },

    /**
     * 立刻执行一次定时任务
     * @returns
     */
    runCrontab(params = {}) {
        return request.post('setting_crontab/run', params)
    },

    /**
     * 添加
     * @returns
     */
    saveCrontab(params = {}) {
        return request.post('setting_crontab/save', params)
    },

    /**
     * 删除
     * @returns
     */
    deleteCrontab(id) {
        return request.post('setting_crontab/delete', {
            id: id
        })
    },

    /**
     * 更新数据
     * @returns
     */
    updateCrontab(id, params = {}) {
        return request.post('setting_crontab/update', {
            id: id,
            data: params
        })
    }

}