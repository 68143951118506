import * as request from '@/utils/request'

export default {
    /**
     * @description 获取列表
     * @param {*} data
     * @returns
     */
    getBusinessPlanList(data) {
        return request.post('/business_plan/list', data)
    },
    /**
     *
     * @param {*} id
     * @returns {Promise}
     */
    getBusinessPlan(id) {
        let data = { id: id }
        return request.post('/business_plan/get_info', data)
    },
    /**
     * 导出
     * @param data
     * @returns {Promise}
     */
    exportBusinessPlans(data) {
        return request.postBlob('/business_plan/export', data)
    },
    /**
     * @description 保存
     * @param {*} data
     * @returns
     */
    saveBusinessPlan(data) {
        return request.post('/business_plan/store', data)
    },
    /**
     * @description 删除
     * @param {*} data
     * @returns
     */
    delBusinessPlan(id) {
        let data = { id: id }
        return request.post('/business_plan/delete', data)
    },
    /**
     * 删除对应的广告信息
     * @param ad_id
     * @returns {Promise}
     */
    delBusinessPlanAd(ad_id) {
        let data = { id: ad_id }
        return request.post('/business_plan/delete_ad', data)
    },
    /**
     * @description 延期
     * @param {*} data
     * @returns
     */
    delayBusinessPlan(data) {
        return request.post('/business_plan/delay', data)
    },
    /**
     * 拒单
     * @param id
     * @returns {Promise}
     */
    rejBusinessPlan(id) {
        let data = { id: id }
        return request.post('/business_plan/rej', data)
    },
    /**
     * @description 操作日志
     * @param data
     * @returns {Promise}
     */
    getBusinessPlanLog(data) {
        return request.post('/business_plan/log', data)
    },
    /**
     * 保存执行进度
     * @param data
     * @returns {*}
     */
    saveBusinessPlanStep(data) {
        return request.post('/business_plan/save_step', data)
    },
    /**
     * 获取执行进度详情
     * @param data
     * @returns {*}
     */
    getBusinessPlanStep(data) {
        return request.post('/business_plan/get_steps', data)
    },
    /**
     *逾期统计
     */
    getBusinessPlanDelayStatistic(data) {
        return request.post('/business_plan/delay_statistic', data)
    }, 
    /**
     *逾期列表
     */
    getBusinessPlanDelayList(data) {
        return request.post('/business_plan/delay_list', data)
    },
    /**
     *设置今日是否提醒-延期商务
     */
    setBusinessPlanDelayNotifyTag(data) {
        return request.post('/business_plan/set_delay_notify_tag', data)
    },
    /**
     *获取今日是否提醒-延期商务
     */
    getBusinessPlanDelayNotifyTag(data) {
        return request.post('/business_plan/get_delay_notify_tag', data)
    }
}
