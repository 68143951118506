import * as request from '@/utils/request'

export default {
  /**
   * @description 获取列表
   * @param {*} data
   * @returns
   */
  getEcpBonusMonthList(data) {
    return request.post('/ecp_bonus_month/list', data)
  },

  /**
   * @description 保存
   * @param {*} data
   * @returns
   */
  saveEcpBonusMonth(data) {
    return request.post('/ecp_bonus_month/store', data)
  },
  /**
   * @description 获取详情
   * @param {*} id
   * @returns
   */
  getEcpBonusMonthInfo(id) {
    let data = { id: id }
    return request.post('/ecp_bonus_month/get_info', data)
  },
  /**
   * @description 根据id删除单条信息
   * @param {*} data
   * @returns
   */
  deleteEcpBonusMonth(data) {
    // let data = { id: id }
    return request.post('/ecp_bonus_month/delete', data)
  },
  /**
   * @description 根据id删除单条信息
   * @param {*} data
   * @returns
   */
  saveEcpBonusGroup(data) {
    // let data = { id: id }
    return request.post('/ecp_bonus_month/save', data)
  },
  cancelCheckEcpBonusGroup(data) {
    // let data = { id: id }
    return request.post('/ecp_bonus_month/cancel_check', data)
  },
  /**
   * 下载奖金分配list
   * @param id
   * @returns {Promise}
   */
  downEcpBonusList(data) {
    return request.postBlob('/ecp_bonus_member/export', data)
  },
  /**
   * @description 奖金分配 部门组 列表
   * @param {*} data
   * @returns
   */
  optionEcpBonusMember(data) {
    return request.post('/ecp_bonus_member/option_data', data)
  },
  /**
   * @description 保存奖金分配 人换部门
   * @param {*} data
   * @returns
   */
  saveEcpBonusMember(data) {
    return request.post('/ecp_bonus_member/add_members', data)
  },
  /**
   * @description 初始化奖金分配列表
   * @param {*} data
   * @returns
   */
  initEcpBonusMember(data) {
    return request.post('/ecp_bonus_member/init_data', data)
  },
//   /ecp_bonus_member/option_data
}
