/**
 * 自动生成 router代码【模块名：快递订阅消息】
 * 生成日期：2024/03/05 10:30:13
 * 生成路径: src/router/logistic_subscribe_message.js
 * 生成人：超级管理员
 * 需要在文件 src/router/router.js 引入当前文件
 * import logistic_subscribe_message from './logistic_subscribe_message'
 // 路由详情
 const routes = [
 {
     path: '/',
     component: () => import('@/layouts/BasicLayout'),
     name: 'BasicLayout',
     meta: { title: '首页' },
     redirect: 'logistic_subscribe_message/list',
     children: [
     ...
      logistic_subscribe,//快递订阅消息
     ]
 */

export default  {
    path: 'logistic_subscribe_message/list',
    name: 'logistic_subscribe_message',
    component: () => import('@/pages/logistic_subscribe_message/LogisticSubscribeMessageList'),
    meta: { title: '快递订阅消息管理' },
}
