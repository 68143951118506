export default {
    path: 'performance_data',
    component: () => import('@/pages/performance/PerformanceData'),
    meta: { title: '绩效数据' },
    children: [
        {
            path: 'trend',
            component: () => import('@/pages/performance/PerformanceTrendList'),
            meta: { title: '涨粉绩效' },
        },
        {
            path: 'revenue',
            component: () => import('@/pages/performance/PerformanceRevenueList'),
            meta: { title: '营收绩效' },
        },
    ]
}
