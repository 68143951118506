import * as request from '@/utils/request'

export default {
    /**
     * @description 获取列表
     * @param {*} data
     * @returns
     */
    getCaseRecordList(data) {
        return request.post('/case_record/list', data)
    },
    /**
     * 导出
     * @param data
     * @returns {Promise}
     */
    exportCaseRecord(data) {
        return request.postBlob('/case_record/export', data)
    },
    /**
     * @description 保存
     * @param {*} data
     * @returns
     */
    saveCaseRecord(data) {
        return request.post('/case_record/store', data)
    },
    /**
     * @description 商品
     * @param {*} data
     * @returns
     */
    delCaseRecord(id) {
        let data = { id: id }
        return request.post('/case_record/delete', data)
    },
    /**
     * @description 操作日志
     * @param data
     * @returns {Promise}
     */
    getCaseRecordLog(data) {
        return request.post('/case_record/log', data)
    },
}
