import * as request from '@/utils/request'

/**
 * 自动生成 api代码【模块名：寄样表】
 * 生成日期：2024/02/28 10:15:33
 * 生成路径: src/api/product_specimen.js
 * 生成人：超级管理员
 * 需要在文件src/api/index.js 引入当前文件
 */

export default {
    /**
     * @description 获取列表【寄样记录】
     * @param {*} data
     * @returns
     */
    getProductSpecimenList(data) {
        return request.post('/product_specimen/list', data)
    },
    /**
     * @description 寄样管理列表查询（产品维度）
     * @param data
     * @returns {Promise}
     */
    queryProductSpecimenList(data) {
        return request.post('/product_specimen/product_query', data)
    },
    /**
     * @description 保存
     * @param {*} data
     * @returns
     */
    saveProductSpecimen(data) {
        return request.post('/product_specimen/store', data)
    },
    /**
     * @description 获取详情
     * @param {*} id
     * @returns
     */
    getProductSpecimenInfo(id) {
        let data = { id: id }
        return request.post('/product_specimen/get_info', data)
    },
    /**
     * @description 根据id删除单条信息
     * @param {*} data
     * @returns
     */
    deleteProductSpecimen(id) {
        let data = { id: id }
        return request.post('/product_specimen/delete', data)
    },
    /**
     *  @description 获取数据行数统计
     * @param data
     * @returns {Promise}
     */
    getProductSpecimenCount(data) {
        return request.post('/product_specimen/count', { leader_id: data.leader_id })
    },



}