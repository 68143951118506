import * as request from '@/utils/request'

export default {

  /**
   * 必填筛选条件
   * @description 获取直播排期数据列表
   * @param {*} data
   * @returns
   */
  getLbNotifyTaskList(data) {
    return request.post('/lb_notify_task/list', data)
  },
  /**
   *
   * @param data
   * @returns {Promise}
   */
  saveLbNotifyTaskInfo(data) {
    return request.post('/lb_notify_task/store', data)
  },
  /**
   *
   * @param id
   * @returns {Promise}
   */
  getLbNotifyTaskInfo(id) {
    return request.post('/lb_notify_task/get_info', { id: id })
  },

  /**
   *
   * @param id
   * @returns {Promise}
   */
  deleteLbNotifyTaskInfo(id) {
    return request.post('/lb_notify_task/delete', { id: id })
  },
  /**
   * 查询排期的定时任务
   * @param lb_id
   * @returns {Promise}
   */
  queryLbNotifyTaskInfo(lb_id) {
    return request.post('/lb_notify_task/query_info', { lb_id: lb_id })
  }
}