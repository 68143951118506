import * as request from '@/utils/request'

export default {
    /**
     * @description 获取列表
     * @param {*} data
     * @returns
     */
    getTrendDatumList(data) {
        return request.post('/trend_datum/list', data)
    },
    /**
     * @description 获取列表
     * @param {*} data
     * @returns
     */
    getTrendDatumMonthList(data) {
        return request.post('/trend_datum/month_data', data)
    },
    /**
     * @description 导出
     * @param {*} data
     * @returns
     */
    exportTrendDatumMonthList(data) {
        return request.postBlob('/trend_datum/export_month_data', data)
    },

    /**
     * @description 保存
     * @author YM
     * @date 2019-02-06
     * @param {*} data
     * @returns
     */
    saveTrendDatum(data) {
        return request.post('/trend_datum/store', data)
    },
    /**
     * @description 获取详情
     *
     * @param {*} id
     * @returns
     */
    getTrendDatumInfo(id) {
        let data = { id: id }
        return request.post('/trend_datum/get_info', data)
    },
    /**
     * @description 根据id删除单条信息
     * @param {*} data
     * @returns
     */
    deleteTrendDatum(id) {
        let data = { id: id }
        return request.post('/trend_datum/delete', data)
    },

    /**
     * @description 记录操作日志
     * @param data
     * @returns {Promise}
     */
    getTrendDataLog(data) {
        return request.post('/trend_datum/log', data)
    },

    /**
     * 榜单数据
     * @param data
     * @returns {Promise}
     */
    getTrendDatumRankData(data) {
        return request.post('/trend_datum/rank_data', data)
    },

    /**
     * 导出
     * @param data
     * @returns {Promise}
     */
    downloadTrendDatum(data) {
        return request.postBlob('/trend_datum/download', data)
    },

    /**
     * @description 仪表盘-》涨粉数据-》红人详情
     * @param {*} data
     * @returns
     */
    getTrendDatumArtistData(data) {
        return request.post('/trend_datum/artist_trend_data', data)
    },

    /**
     * @description 仪表盘-》涨粉数据-》红人详情
     * @param {*} data
     * @returns
     */
    getTrendDatumArtistMonthsData(data) {
        return request.post('/trend_datum/artist_trend_months_data', data)
    },
    /**
     * @description 仪表盘-》涨粉数据-》红人详情 -指定年份的涨粉数据
     * @param {*} data
     * @returns
     */
    getTrendDatumYearChartData(data) {
        return request.post('/trend_datum/year_data_chart', data)
    },
    /**
     * @description 仪表盘-》涨粉数据-》红人详情-》图表
     * @param {*} data
     * @returns
     */
    getTrendDatumArtistDataChartData(data) {
        return request.post('/trend_datum/artist_trend_data_chart', data)
    },
    /**
     * 导出：仪表盘-》涨粉数据-》红人详情
     * @param data
     * @returns {Promise}
     */
    downloadTrendDatumArtistData(data) {
        return request.postBlob('/trend_datum/export_artist_trend_data', data)
    },
}
