import * as request from '@/utils/request'

export default {
    /**
     * @description 提交登录信息，进行登录
     * @author YM
     * @date 2019-02-12
     * @returns
     */
    submitLoginInfo(data) {
        return request.post('/passport/login', data)
    },
    /**
     * @description 提交退出登录
     * @author YM
     * @date 2019-02-13
     * @returns
     */
    submitLogout() {
        return request.post('/passport/logout')
    },
    /**
     * 登录方式二：钉钉扫码登录
     * @param data
     * @returns {*}
     */
    dingLogin(data) {
        return request.post('/passport/dingLogin', data)
    },
    /**
     * 登录方式二：钉钉扫码联合登录
     * @param data
     * @returns {*}
     */
    dingUniteLogin(data) {
        return request.post('/passport/login_by_auth_code', data)
    },
    // login_by_auth_code
    /**
     * 钉钉绑定用户，并登录（作废，后台直接绑定， 不需要自己绑定）
     * @param data
     * @returns {*}
     */
    // dingBindUser(data) {
    //     return request.post('/passport/dingBindUser', data)
    // },
    /**
     * 钉钉免登录：授权码登录
     * @param data
     * @returns {Promise}
     */
    dingLoginByCode(data) {
        return request.post('/passport/login_by_code', data)
    },

    /**
     * 登录者-个人信息
     * @returns {Promise}
     */
    getUserProfile() {
        return request.post('/passport/profile', {})
    },
    /**
     * 修改用户信息
     * @returns {Promise}
     */
    updateUserProfile(data) {
        return request.post('/passport/update_profile', data)
    },
    updateUserPwd(oldPassword, newPassword) {
        const data = {
            oldPassword,
            newPassword
        }
        return request.post('/passport/update_pwd', data)
    },
    /**
     * 判断用户是否有密码
     * @returns {Promise}
     */
    checkUserPwd() {
        return request.post('/passport/check_pwd', {})
    },
}
