/**
 * 自动生成 router代码【模块名：库存】
 * 生成日期：2024/04/25 15:10:40
 * 生成路径: src/router/product_qty.js
 * 生成人：超级管理员
 * 需要在文件 src/router/router.js 引入当前文件
 * import product_qty from './product_qty'
 // 路由详情
 const routes = [
 {
 path: '/',
 component: () => import('@/layouts/BasicLayout'),
 name: 'BasicLayout',
 meta: { title: '首页' },
 redirect: '/myNav',
 children: [
 ...
 product_qty,//库存
 ]
 */

export default  {
  path: 'product_qty/list',
  name: 'product_qty',
  component: () => import('@/pages/product_qty/ProductQtyList'),
  meta: { title: '库存查询' },
}
