import * as request from '@/utils/request'

export default {
    /**
     * @description 获取直播数据列表
     * @param {*} data
     * @returns
     */
    getShopInfoList(data) {
        return request.post('/shop_info/list', data)
    },
    /**
     * @description 获取直播数据汇总
     * @param {*} data
     * @returns
     */
    getShopInfoStatistics(data) {
        return request.post('/shop_info/statistics', data)
    },
    /**
     * @description 获取列表
     * @param {*} data
     * @returns
     */
    getShopInfoMonthData(data) {
        return request.post('/shop_info/month_data', data)
    },

    /**
     * @description 保存
     * @param {*} data
     * @returns
     */
    addShopInfo(data) {
        return request.post('/shop_info/add', data)
    },
    /**
     * @description 修改
     * @param {*} data
     * @returns
     */
    updateShopInfo(data) {
        return request.post('/shop_info/update', data)
    },
    /**
     * @description 获取详情
     *
     * @param {*} id
     * @returns
     */
    getShopInfo(id) {
        let data = { id: id }
        return request.post('/shop_info/get_info', data)
    },

    // /**
    //  * @description 根据id删除单条信息
    //  *
    //  * @returns
    //  */
    // delShopInfo(id) {
    //     let data = { id: id }
    //     return request.post('/shop_info/delete', data)
    // },

    /**
     * 导出
     * @param data
     * @returns {Promise}
     */
    exportShopInfos(data) {
        return request.postBlob('/shop_info/export', data)
    },
    /**
     * 导出汇总数据
     * @param data
     * @returns {Promise}
     */
    exportShopInfosStatistics(data) {
        return request.postBlob('/shop_info/export_statistics', data)
    },

    /**
     * @description 操作日志
     * @param data
     * @returns {Promise}
     */
    getShopInfoLog(data) {
        return request.post('/shop_info/log', data)
    },
    /**
     * 店铺数据-配置
     * @returns {*}
     */
    getShopInfoSettings() {
        return request.post('/shop_info/settings', {})
    },
}
