import * as request from '@/utils/request'

export default {
  /**
   * 招商列表
   * @returns
   */
  investmentGetList(data) {
    return request.post('/investment/list', data)
  }
}
