export default {
    path: 'network_ip',
    name: "NetworkIpList",
    component: () => import('@/pages/network_ip/NetworkIpList'),
    meta: {title: 'IP管理'},
    children: [
        // {
        //     path: 'create',
        //     component: () => import('@/pages/network_ip/CreateEdit'),
        //     meta: {title: '添加IP'},
        // },
        // {
        //     path: ':artist_id/edit',
        //     component: () => import('@/pages/network_ip/CreateEdit'),
        //     meta: {title: '编辑IP'},
        // }
    ]
}
