import * as request from '@/utils/request'

export default {
    /**
     * @description 消息通知列表
     *
     * @returns
     */
    getNotifyList(data) {
        return request.post('/notify/list', data)
    },

    /**
     * 消息通知总数
     * @param data
     * @returns {Promise}
     */
    getNotifyTotal(data) {
        return request.post('/notify/total', data)
    },

    /**
     * 消息通知总数
     * @param data
     * @returns {Promise}
     */
    readNotify(data) {
        return request.post('/notify/read', data)
    }

}
