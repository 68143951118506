export default {
    path: 's_menus',
    component: () => import('@/pages/menus/SMenu'),
    meta: {title:'档期'},
    children: [
        {
            path: 'artist_schedule',
            name: 'artistSchedule',
            component: () => import('@/pages/artist/schedule/Schedule'),
            meta: { title: '档期管理' },
        },
        {
            path: 'business_manage',
            name: 'BusinessList',
            component: () => import('@/pages/business/List'),
            meta: { title: '商务管理' },
        },
        {
            path: 'task_schedule',
            name: 'taskSchedule',
            component: () => import('@/pages/task/index'),
            meta: {title: '工作排期'}
        },
    ]
}
