import {numberFormat, parseTime} from "./utils";

export class RegisterFilter {
  /**
   * @param Vue
   */
  constructor(Vue) {
    Vue.filter('numberFormat', function (value, decimals = 0) {
      return numberFormat(value, decimals)
    })

    Vue.filter('numberFormatW', function (value) {
      if (value >= 1e4) {
        const num = ((parseFloat(value) / 1e4)).toFixed(2)
        return numberFormat(num, 2) + 'w'
      } else {
        return numberFormat(value)
      }
    })

    Vue.filter('parseTime', function (time, cFormat = '{y}/{m}/{d}') {
      return parseTime(time, cFormat)
    })
  }
}
