import * as request from '@/utils/request'

export default {
  /**
   * @description 获取用户菜单list，用于左侧导航
   * @author YM
   * @date 2019-01-10
   * @returns promise
   */
  getUserMenuList() {
    return request.post('/menu/user_menu')
  },
  /**
   * @description 获取用户权限list，用于页面按钮相关的控制
   * @author YM
   * @date 2019-01-10
   * @returns promise
   */
  getUserPermissionsList() {
    return request.post('/permissions/user_permissions')
  },
  /**
   * 我的归属部门
   * @returns {Promise}
   */
  getUserPermissionsDeptList() {
    return request.post('/permissions/user_dept_list')
  },
  /**
   * getOssToken
   * @description 获取上传凭证
   * @author YQ
   * @date 2019-01-19
   * @returns
   */
  getOssToken() {
    return request.post('/upload/get_upload_token')
  },
  /**
   * editorUploadFile
   * @description 富文本上传文件
   * @author YQ
   * @date 2019-01-31
   * @returns
   */
  editorUploadFile(data, ossUrl) {
    if (ossUrl) {
      return request.post(ossUrl, data)
    } else {
      return request.post('/upload/file', data)
    }
  },
  /**
   * @description 获取阿里云vod视频上传令牌信息
   * @author YM
   * @date 2019-02-25
   * @returns
   */
  getVodToken(data) {
    return request.post('/upload/get_aliyun_vod_token', data)
  },

  /**
   * @description 钉钉配置
   * @returns {Promise}
   */
  getDingSetting() {
    return request.post('/common/get_ding_id', {})
  },

  /**
   * @description 钉钉配置
   * @returns {Promise}
   */
  getDingApp() {
    return request.post('/common/get_ding_app', {})
  },
  /**
   * @description 获取公钥
   * @returns {Promise}
   */
  getPubKey() {
    return request.post('/rsa/pub_key')

  },

  /**
   * 用户表列设置
   * @returns {Promise}
   */
  getUserColumnSettingList() {
    return request.post('/user_column_setting/list')
  },
  /**
   * 获取用户指定列表格设置
   * @param tableName
   * @returns {Promise}
   */
  getUserColumnSetting(tableName) {
    return request.post('/user_column_setting/get_info_by', { table_name: tableName })
  },
  /**
   * 保存用户指定列表格设置
   * @param tableName
   * @param user_columns
   * @param hash
   * @returns {Promise}
   */
  saveUserColumnSetting(tableName, user_columns, hash) {
    return request.post('/user_column_setting/store', { table_name: tableName, user_columns, hash })
  },
  /**
   * 删除用户指定列表格设置
   * @param id
   * @returns {Promise}
   */
  delUserColumnSetting(id) {
    return request.post('/user_column_setting/delete', { id: id })
  },

  /**
   * 我可申请的角色列表
   * @returns {Promise}
   */
  getMyApplyRoleList() {
    return request.post('/profile/getMyApplyRoleList', {})
  },
  /**
   * 与我相关的角色申请记录
   * @returns {Promise}
   */
  getMyRoleApplyRecords(searchCondition) {
    return request.post('/profile/getMyRoleApplyRecords', searchCondition)
  },
  /**
   * 提交申请
   * @param selectRole 申请的角色
   * @param applyLeader 申请对象-选择的上级领导
   * @returns {Promise}
   */
  postCommitApply(selectRole, applyLeader) {
    return request.post('/profile/commitApply', { apply_roles: selectRole, leaders: applyLeader })
  },
  /**
   * 撤销申请
   * @param id
   * @returns {Promise}
   */
  postRevokeApply(id) {
    return request.post('/profile/revokeApply', { id: id })
  },
  // 待办事项统计
  geTodoSummary(id) {
    return request.post('/profile/todoSummary', { id: id })
  },
  /**
   * 通知审批角色申请
   * @param id
   * @returns {Promise}
   */
  notifyLeaderApply(id) {
    return request.post('/profile/notifyLeader', { id: id })
  },
}
