import * as request from '@/utils/request'

export default {

    /**
     * @description 获取数据列表
     * @param {*} data
     * @returns
     */
    getArtistScheduleList(data) {
        return request.post('/artist_schedule/list', data)
    },
    /**
     * @description 获取数据集合
     * @param {*} data
     * @returns
     */
    getArtistScheduleDataMap(data) {
        return request.post('/artist_schedule/data_map', data)
    },

    /**
     * @description 保存
     * @param {*} data
     * @returns
     */
    saveArtistSchedule(data) {
        return request.post('/artist_schedule/store', data)
    },
    /**
     * @description 标记排期进度
     * @param {*} data
     * @returns
     */
    tagStatusArtistSchedule(data) {
        return request.post('/artist_schedule/tag_status', data)
    },
    /**
     * @description 保存-单元格样式
     * @param {*} data
     * @returns
     */
    saveArtistScheduleCellStyle(data) {
        return request.post('/artist_schedule/save_style', data)
    },
    /**
     * @description 获取详情
     *
     * @param {*} id
     * @returns
     */
    getArtistSchedule(id) {
        let data = { id: id }
        return request.post('/artist_schedule/get_info', data)
    },
    /**
     * @description 获取详情
     *
     * @returns
     * @param artist_id
     * @param plan_date
     * @param platform_code
     */
    getASchedule(artist_id, plan_date, platform_code) {
        let data = { artist_id: artist_id, plan_date: plan_date, platform_code: platform_code }
        return request.post('/artist_schedule/get_a_info', data)
    },
    /**
     * @description 根据id删除单条信息
     *
     * @returns
     */
    delArtistSchedule(id) {
        let data = { id: id }
        return request.post('/artist_schedule/delete', data)
    },

    /**
     * 导出
     * @param data
     * @returns {Promise}
     */
    exportArtistSchedules(data) {
        return request.postBlob('/artist_schedule/export', data)
    },
    /**
     * @description 操作日志
     * @param data
     * @returns {Promise}
     */
    getArtistScheduleLog(data) {
        return request.post('/artist_schedule/log', data)
    },

    /**
     * @description 获取隐藏的行
     * @param data
     * @returns {Promise}
     */
    getArtistScheduleHideRows(data) {
        return request.post('/artist_schedule/get_hide_settings', data)
    }, /**
     * @description 隐藏一行
     * @param data
     * @returns {Promise}
     */
    AddArtistScheduleHideRow(data) {
        return request.post('/artist_schedule/hide_row', data)
    },
    /**
     * @description 隐藏一行
     * @param data
     * @returns {Promise}
     */
    delArtistScheduleHideRow(data) {
        return request.post('/artist_schedule/del_hide_setting', data)
    },

    /**
     * @description 延期
     * @param data
     * @returns {*}
     */
    delayInfoArtistSchedule(data) {
        return request.post('/artist_schedule/delay_info', data)
    },

    /**
     * @description 保存-平台排序
     * @param data
     * @returns {*}
     */
    saveArtistScheduleSort(data) {
        return request.post('/artist_schedule/save_sort', data)
    },

    /**
     * @description 保存-平台排序
     * @param data
     * @returns {*}
     */
    getArtistScheduleSort(data) {
        return request.post('/artist_schedule/get_sort', data)
    },
}
