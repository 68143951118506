/**
 * 自动生成 router代码【模块名：仓库】
 * 生成日期：2024/04/24 20:30:36
 * 生成路径: src/router/warehouse.js
 * 生成人：超级管理员
 * 需要在文件 src/router/router.js 引入当前文件
 * import warehouse from './warehouse'
 // 路由详情
 const routes = [
 {
 path: '/',
 component: () => import('@/layouts/BasicLayout'),
 name: 'BasicLayout',
 meta: { title: '首页' },
 redirect: '/myNav',
 children: [
 ...
 warehouse,//仓库
 ]
 */

export default  {
  path: 'warehouse/list',
  name: 'warehouse',
  component: () => import('@/pages/warehouse/WarehouseList'),
  meta: { title: '仓库管理' },
}
