import * as request from '@/utils/request'

export default {
  /**
   * @description 获取列表
   * @param {*} data
   * @returns 
   */
  getTeamKoLSituationList(data) {
    return request.post('/team_kol_situation/list', data)
  },
  /**
   * 小组年数据一栏
   * @param data
   * @returns {Promise}
   */
  getTeamKoLSituationOverview(data) {
    return request.post('/team_kol_situation/overview', data)
  },
  /**
   * 查看【红人签约/解约情况一览】详情
   * @param data
   * @returns {Promise}
   */
  getTeamKoLSituationViewDetail(data) {
    return request.post('/team_kol_situation/view_detail', data)
  },
}