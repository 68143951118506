import * as request from '@/utils/request'

export default {
    /**
     * @description 获取列表
     * @param {*} data
     * @returns
     */
    getSignInfoList(data) {
        return request.post('/sign_info/list', data)
    },
    /**
     * @description 获取签页人列表
     * @param {*} data
     * @returns
     */
    getSignInfoSignatoryList(data) {
        return request.post('/sign_info/get_signatory_list', data)
    },
    /**
     * @description 保存
     * @param {*} data
     * @returns
     */
    saveSignInfo(data) {
        return request.post('/sign_info/store', data)
    },
    /**
     * 修改红人解约时间
     * @param data
     * @returns {Promise}
     */
    editSignFiredTime(data) {
        return request.post('/sign_info/change_fired_time', data)
    },
    /**
     * @description 获取详情
     *
     * @param {*} id
     * @returns
     */
    getSignInfo(id) {
        let data = { id: id }
        return request.post('/sign_info/get_info', data)
    },
    /**
     * @description 删除签约信息
     *
     * @param {*} id
     * @returns
     */
    deleteSignInfo(id) {
        let data = { id: id }
        return request.post('/sign_info/delete', data)
    },
    /**
     * @description 转组
     * @param {*} data
     * @returns
     */
    signInfoGroupTransfer(data) {
        return request.post('/sign_info/group_transfer', data)
    },
    /**
     * @description 转组记录
     * @param {*} data
     * @returns
     */
    signArtistGroupList(data) {
        return request.post('/sign_info/artist_groups_list', data)
    },
    /**
     * @description 保存
     * @param {*} data
     * @returns
     */
    signInfoFired(data) {
        return request.post('/sign_info/fired_sign', data)
    },
    /**
     * @description 搜索：签约人
     * @param qs
     * @returns
     */
    searchSignInfo(qs) {
        let data = { search: qs }
        return request.post('/sign_info/search', data)
    },
    /**
     * @description 导出
     * @param {*} data
     * @returns
     */
    exportSignInfoList(data) {
        return request.postBlob('/sign_info/export', data)
    },
    /**
     * @description 签约情况一览
     * @param qs
     * @returns
     */
    signInfoOverview(data) {

        return request.post('/sign_info/summary', data)
    },
    /**
     * 签约情况一览：明细
     * @param data
     * @returns {Promise}
     */
    getSignSummaryDetail(data) {
        return request.post('/sign_info/summary_detail', data)
    },

    /**
     * 签约部门读取
     * @param data
     * @returns {Promise}
     */
    getSignatorySetting(data) {
        return request.post('/sign_info/signatory_setting', data)
    },

}
