import * as request from '@/utils/request'

/**
 * 自动生成 api代码【模块名：示例】
 * 生成日期：2024/03/06 18:48:23
 * 生成路径: src/api/demo.js
 * 生成人：超级管理员
 * 需要在文件src/api/index.js 引入当前文件
 * import demo from './demo'
 * export default {
 * ...
 * //物流信息订阅
 *    ...demo,
 * }
 */

export default {
    /**
     * @description 获取列表
     * @param {*} data
     * @returns
     */
    getDemoList(data) {
        return request.post('/demo/list', data)
    },
    /**
     * @description 保存
     * @param {*} data
     * @returns
     */
    saveDemo(data) {
        return request.post('/demo/store', data)
    },
    /**
     * @description 获取详情
     * @param {*} id
     * @returns
     */
    getDemoInfo(id) {
        let data = { id: id }
        return request.post('/demo/get_info', data)
    },
    /**
     * @description 根据id删除单条信息
     * @param {*} data
     * @returns
     */
    deleteDemo(id) {
        let data = { id: id }
        return request.post('/demo/delete', data)
    },
    /**
     * 导入
     * @param data
     * @returns {Promise}
     */
    importDemos(data) {
        return request.post('/demo/import', data)
    },
    /**
     * 导出
     * @param data
     * @returns {Promise}
     */
    exportDemos(data) {
        return request.postBlob('/demo/export', data)
    },

}