import * as request from '@/utils/request'

export default {
  /**
   * @description 获取列表
   * @param {*} data
   * @returns 
   */
  getEcpMerchantQuarterList(data) {
    return request.post('/ecp_merchant_quarter/list', data)
  },

  /**
   * @description 保存
   * @param {*} data
   * @returns 
   */
  saveEcpMerchantQuarter(data) {
    return request.post('/ecp_merchant_quarter/store', data)
  },
  /**
   * @description 获取详情
   * @param {*} id
   * @returns 
   */
  getEcpMerchantQuarterInfo(id) {
    let data = {id:id}
    return request.post('/ecp_merchant_quarter/get_info', data)
  },
  /**
   * @description 根据id删除单条信息
   * @param {*} data
   * @returns
   */
  deleteEcpMerchantQuarter(ids) {
    let data = {ids:ids}
    return request.post('/ecp_merchant_quarter/delete',data)
  },
  /**
   * 获取季度汇总数据
   * @param searchCondition
   * @returns {Promise}
   */
  getEcpMerchantQuarterSummary(searchCondition) {
    return request.post('/ecp_merchant_quarter/quarter_summary', searchCondition)
  },
}