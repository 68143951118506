import * as request from '@/utils/request'

/**
 * 自动生成 api代码【模块名：仓库】
 * 生成日期：2024/04/24 20:30:36
 * 生成路径: src/api/warehouse.js
 * 生成人：超级管理员
 * 需要在文件src/api/index.js 引入当前文件
 * import warehouse from './warehouse'
 * export default {
 * ...
 * // 仓库
 *    ...warehouse,
 * }
 */

export default {
  /**
   * @description 获取列表
   * @param {*} data
   * @returns
   */
  getWarehouseList(data) {
    return request.post('/warehouse/list', data)
  },
  /**
   * @description 保存
   * @param {*} data
   * @returns
   */
  saveWarehouse(data) {
    return request.post('/warehouse/store', data)
  },
  /**
   * @description 获取详情
   * @param {*} id
   * @returns
   */
  getWarehouseInfo(id) {
    let data = { id: id }
    return request.post('/warehouse/get_info', data)
  },
  /**
   * @description 根据id删除单条信息
   * @param {*} data
   * @returns
   */
  deleteWarehouse(id) {
    let data = { id: id }
    return request.post('/warehouse/delete', data)
  },
  /**
   * 导入
   * @param data
   * @returns {Promise}
   */
  importWarehouses(data) {
    return request.post('/warehouse/import', data)
  },
  /**
   * 导出
   * @param data
   * @returns {Promise}
   */
  exportWarehouses(data) {
    return request.postBlob('/warehouse/export', data)
  },
  /**
   * 下拉仓库选项列表
   * @returns {Promise}
   */
  getWarehouseOption() {
    return request.post('/warehouse/get_option')
  },

}