import passport from './passport'
import common from './common'
import migration from './migration'
import user_setting from './user_setting'
import home from './home'
import system_db from './system_db'
import statistics from './statistics'
import menu from './menu'
import permissions from './permissions'
import roles from './roles'
import user from './user'
import wx_user from './wx_user'
import setting from './setting'
import category from './category'
import article from './article'
import attachment from './attachment'
import sysLog from './sys_log'
import artist from './artist'
import trendDatum from './trend_datum'
import revenueDatum from './revenue_datum'
import revenueItem from './revenue_item'
import departments from './departments'
import mcn_group from './mcn_group'
import platform from './platform'
import notify from './notify'
import performance from './performance'
import sign_info from './sign_info'
import live_info from './live_info'
import shop_info from './shop_info'
import artist_schedule from './artist_schedule'
import case_record from './case_record'
import download_file from './download_file'
import upload_file from './upload_file'
import business_plan from './business_plan'
import network_ip from './network_ip'
import dashboard from './dashboard'
import perf_dept_info from './perf_dept_info'
import plc from './plc'
import notice from './notice'
import lb_schedule from './lb_schedule'
import lb_notify_task from './lb_notify_task'
import product from './product'
import sys_notice from './sys_notice'
import artist_commission from './artist_commission'
import monitor from './monitor'
import crontab from './crontab'
import extern_kol from '@/api/extern_kol'
import oa_manage from '@/api/oa_manage'
import oa_template from '@/api/oa_template'
import inquiry_project from '@/api/inquiry_project'
import sys_post from '@/api/sys_post'
import settlement from '@/api/settlement'
import kolModel from '@/api/kolModel'
import product_audit from './product_audit'
import ecp_kol_mapper from './ecp_kol_mapper'
import ecp_month from './ecp_month'
import ecp_quarter from './ecp_quarter'
import ecp_merchant_month from './ecp_merchant_month'
import ecp_merchant_quarter from './ecp_merchant_quarter'
import ecp_bonus_month from './ecp_bonus_month'
import ecp_bonus_member from './ecp_bonus_member'
import labor_cost from './labor_cost'
import investment from './investment'
import rebate from './rebate'
import mcn_promotion from './mcn_promotion'
import live_funding from './live_funding'
import team_kol_change from './team_kol_change'
import team_kol_situation from './team_kol_situation'
import team_member from './team_member'
import team_change from './team_change'
import dept from './dept'
import dingtalk_event from './dingtalk_event'
import brand from './brand'
import system_oper_log from './system_oper_log'
import sys_dept from './sys_dept'
import ding_user_info from './ding_user_info'
import balance from './balance'
import kol_archive from './kol_archive'
import product_dress from './product_dress'
import dress_brand from './dress_brand'
import lb_dress_selection from './lb_dress_selection'
import beisen from './beisen'
import logistics from './logistics'
import product_specimen from './product_specimen'
import logistic_subscribe from './logistic_subscribe'
import logistic_subscribe_message from './logistic_subscribe_message'
import region from './region'
import demo from './demo'
import role_apply_record from './role_apply_record'
import warehouse from './warehouse'
import warehouse_location from '@/api/warehouse_location'
import product_qty from '@/api/product_qty'
import product_qty_record from '@/api/product_qty_record'
import custom_barcode from '@/api/custom_barcode'
import product_whl_code_qty from '@/api/product_whl_code_qty'
import express_take_over from '@/api/express_take_over'
import brand_join_record from './brand_join_record'

export default {
  // 通行证
  ...passport,
  //操作日志
  ...system_oper_log,
  //数据仓库
  ...system_db,
  //钉钉成员名单
  ...ding_user_info,
  //系统部门
  ...sys_dept,
  // 通用
  ...common,
  // 表迁移记录
  ...migration,
  // 用户设置
  ...user_setting,
  // 首页
  ...home,
  // 统计数据
  ...statistics,
  // 菜单
  ...menu,
  // 权限
  ...permissions,
  // 角色
  ...roles,
  // 用户
  ...user,
  // 微信用户
  ...wx_user,
  // 基础配置
  ...setting,
  // 分类管理
  ...category,
  // 文章管理
  ...article,
  // 附件管理
  ...attachment,
  // 系统日志管理
  ...sysLog,
  // 红人管理
  ...artist,
  //涨粉数据
  ...trendDatum,
  //营收数据
  ...revenueDatum,
  ...revenueItem,
  //部门
  ...departments,
  //分组
  ...mcn_group,
  //平台管理
  ...platform,
  //消息通知
  ...notify,
  //绩效数据
  ...performance,
  //签约信息
  ...sign_info,
  //直播数据
  ...live_info,
  //店铺数据
  ...shop_info,
  //档期管理
  ...artist_schedule,
  //（抖音视频）案例记录
  ...case_record,
  //下载记录
  ...download_file,
  //商务管理
  ...business_plan,
  //网络IP
  ...network_ip,
  //仪表盘
  ...dashboard,
  //总监绩效
  ...perf_dept_info,
  // 刊例
  ...plc,
  //公告管理
  ...notice,
  //直播排期
  ...lb_schedule,
  ...lb_notify_task,
  //产品库
  ...product,
  //导航-公告
  ...sys_notice,
  //红人分成
  ...artist_commission,
  //系统监控
  ...monitor,
  //定时任务
  ...crontab,
  //红人资源库
  ...extern_kol,
  //oa审批管理
  ...oa_manage,
  //oa模板管理
  ...oa_template,
  //询单系统
  ...inquiry_project,
  //岗位管理
  ...sys_post,
  //上传记录
  ...upload_file,
  //项目结款
  ...settlement,
  //红人模型
  ...kolModel,
  //产品审核
  ...product_audit,
  // 电商绩效
  ...ecp_kol_mapper,
  ...ecp_month,
  ...ecp_quarter,
  ...ecp_merchant_month,
  ...ecp_merchant_quarter,
  ...ecp_bonus_month,
  ...ecp_bonus_member,
  //人工成本
  ...labor_cost,
  //直播-招商
  ...investment,
  //返点费用
  ...rebate,
  //MCN推广费用
  ...mcn_promotion,
  //直播投放金
  ...live_funding,
  // 团队-红人分析
  ...team_kol_situation,
  ...team_kol_change,
  // 团队-成员一览
  ...team_member,
  //组织成员异动一览
  ...team_change,
  //部门详情相关接口
  ...dept,
  //钉钉事件通知
  ...dingtalk_event,
  //品牌库
  ...brand,
  //账号余额
  ...balance,
  //KOL归档记录
  ...kol_archive,
  // 服饰产品库
  ...product_dress,
  ...dress_brand,
  ...lb_dress_selection,
  ...beisen,
  ...logistics,
  //寄样管理
  ...product_specimen,
  //物流信息订阅
  ...logistic_subscribe,
  ...logistic_subscribe_message,
  ...demo,
  // 行政区域
  ...region,
  ...role_apply_record,
  // 仓库
  ...warehouse,
  // 库位
  ...warehouse_location,
  // 库存查询
  ...product_qty,
  // 进销存管理
  ...product_qty_record,
  // 条码管理
  ...custom_barcode,
  // 产品库位库存
  ...product_whl_code_qty,
  // 寄样快递库位
  ...express_take_over,
  //品牌接入
  ...brand_join_record,
}



