import * as request from '@/utils/request'

export default {
    /**
     * @description 获取部门表
     * @author YM
     * @date 2019-01-17
     * @returns promise
     */
    getDepartmentsList() {
        return request.post('/ding/departments')
    },
    /**
     *
     * @returns {Promise}
     */
    getDingDeptList(data) {
        return request.post('/ding/dept_list',data)
    },
    /**
     * @description 获取部门全部信息-未树型格式化
     * @returns {Promise}
     */
    getDepartmentAll(type) {
        return request.post('/ding/department_all',{scope_type:type})
    },
    /**
     * @description 获取部门信息
     * @returns {Promise}
     */
    getDeptInfo(dept_id) {
        return request.post('/ding/department_info',{dept_id:dept_id})
    },
    /**
     * @description 部门排序
     * @param {*} ids
     * @returns
     */
    orderDepartment(ids) {
        let data = { ids: ids }
        return request.post('/ding/department_order', data)
    },
    /**
     * @description 同步部门表
     * @returns promise
     */
    syncDpt() {
        return request.post('/ding/sync_dpt')
    },

    /**
     * @description 获取部门下的用户表
     * @returns promise
     */
    getDptUsers(data) {
        return request.post('/ding/get_users', data)
    },

    /**
     * @description 最后同步时间
     * @returns promise
     */
    lastSyncTime() {
        return request.post('/ding/last_sync_time')
    },
    // refresh_user_info

    /**
     * 更新用户信息
     * @param data
     * @returns {Promise}
     */
    refreshUserInfo(data) {
        return request.post('/ding/refresh_user_info', data)
    }

}
