export default {
  path: 'settlement',
  name: 'settlement',
  component: () => import('@/pages/settlement/index'),
  meta: { title: '项目结款' },
  children: [
    {
      path: ':settlement_id/detail',
      name: 'settlement_detail',
      component: () => import('@/pages/settlement/detail.vue'),
      meta: { title: '项目详情' }
    }
  ]
}
