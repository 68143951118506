import * as request from '@/utils/request'

export default {
  /**
   * 获取服务器信息
   * @returns
   */
  getServerInfo() {
    return request.post('/server/monitor')
  },
  /**
   * 缓存信息
   * @returns {Promise}
   */
  getCacheInfo() {
    return request.post('/cache/monitor')
  },
  /**
   * Redis缓存-指定库的缓存名称
   * @returns {Promise}
   */
  getCacheDbKeys(db) {
    return request.post('/cache/db_keys', { db: db })
  },
  /**
   * 查看缓存
   * @returns {Promise}
   */
  viewCacheInfo(key, db) {
    return request.post('/cache/view', { key: key, db: db })
  },
  /**
   * 删除缓存
   * @returns {Promise}
   */
  delCacheKey(key, db) {
    return request.post('/cache/delete', { key: key, db: db })
  },
  /**
   * 清空缓存
   * @returns {Promise}
   */
  clearCache(db) {
    return request.post('/cache/clear', { db: db })
  }
}