import * as request from '@/utils/request'

export default {
    /**
     * 获取红人
     * @returns
     */
    liveFundingGetKolList(data) {
        return request.post('/kol_analy_live_put/list', data)
    },
    /**
     * 修改数据
     * @returns
     */
    liveFundingEdit(data) {
        return request.post('/kol_analy_live_put/save', data)
    },
    /**
     * 修改数据
     * @returns
     */
    liveFundingDel(data) {
        return request.post('/kol_analy_live_put/delete', data)
    },
    /**
     * 获取导入列表
     * @returns
     */
    liveFundinggetImportList(data) {
        return request.post('/kol_analy_live_put/list', data)
    },
    /**
     * 撤回导入列表
     * @returns
     */
    liveFundingDelImport(data) {
        return request.post('/kol_analy_live_put/delete_import', data)
    },
    /**
     * @description 导出模板
     * @param {*} data
     * @returns
     */
    exportliveFundingTemplate() {
        return request.postBlob('/kol_analy_live_put/download')
    },

}
