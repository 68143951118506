export default {
  path: 'inquiries',
  name: 'inquiries',
  component: () => import('@/pages/inquiries/index'),
  meta: { title: '询单' },
  children: [
    {
      path: 'list',
      name: 'inquiriesList',
      component: () => import('@/pages/inquiries/inquiryList'),
      meta: { title: '全部询单' }
    },
    {
      path: 'myinquir',
      name: 'myInquir',
      component: () => import('@/pages/inquiries/myInquiry'),
      meta: { title: '我的询单' }
    },
    {
      //inquiryApplyList
      path: 'applylist',
      name: 'inquiryApplyList',
      component: () => import('@/pages/inquiries/inquiryApplyList'),
      meta: { title: '报名列表' }
    },
    {
      path: ':id/inquiriesDetail',
      name: 'inquiriesDetail',
      component: () => import('@/pages/inquiries/InfoDetail'),
      meta: { title: '项目详情' }
    },
    {
      path: ':id/applyDetail',
      name: 'applyDetail',
      component: () => import('@/pages/inquiries/applyDetail'),
      meta: { title: '项目详情' }
    }
    ,
    {
      path: 'chargingStandard',
      name: 'chargingStandard',
      component: () => import('@/pages/inquiries/chargingStandard'),
      meta: { title: '授权费用管理' }
    }
    //  applyDetail
  ]
}
