import * as request from '@/utils/request'

export default {
  /**
   * 查询公告列表
   * @param data
   * @returns {*}
   */
  listNotice( data ) {
    return request.post('/notice/list', data)
  },
  /**
   * 查询公告详细
   * @param noticeId
   * @returns {*}
   */
  getNotice( noticeId ) {
    return request.post('/notice/get_info', {id: noticeId})
  },

  /**
   * 新增公告
   * @param data
   * @returns {*}
   */
  addNotice( data ) {
    return request.post('/notice/store', data)
  },

  /**
   * 修改公告
   * @param data
   * @returns {*}
   */
  updateNotice( data ) {
    return request.post('/notice/store', data)
  },
  /**
   * 删除公告
   * @param noticeId
   * @returns {*}
   */
  delNotice( noticeId ) {
    return request.post('/notice/delete', {id: noticeId})
  },
  /**
   * 最新的一条公告
   *
   * @returns {Promise}
   */
  lastNotice() {
    return request.post('/notice/last_notice', {})
  }

}
