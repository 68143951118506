export default {
    path: 'case_record',
    name: 'CaseRecordList',
    component: () => import('@/pages/case_record/Index'),
    meta: {title: '视频案例'},
    children: [
        {
            path: 'tiktok_data',
            name: 'CaseRecordDataList',
            component: () => import('@/pages/case_record/DataList'),
            meta: {title: '列表'},
        }
    ]
}
