import * as request from '@/utils/request'

export default {
  /**
   * @description 获取列表
   * @param {*} data
   * @returns
   */
  getRevenueDataList(data) {
    return request.post('/revenue_datum/list', data)
  },
  /**
   * @description 导出
   * @param {*} data
   * @returns
   */
  exportRevenueDataList(data) {
    return request.postBlob('/revenue_datum/export_data', data)
  },
  /**
   * @description 保存
   * @author YM
   * @date 2019-02-06
   * @param {*} data
   * @returns
   */
  saveRevenueData(data) {
    return request.post('/revenue_datum/store', data)
  },
  /**
   * 同步营收数据：来自星广汇
   * @param data
   * @returns {Promise}
   */
  syncRevenueData(data) {
    return request.post('/revenue_datum/sync', data)
  },
  /**
   * @description 获取详情
   *
   * @param {*} id
   * @returns
   */
  getRevenueDataInfo(id) {
    let data = { id: id }
    return request.post('/revenue_datum/get_info', data)
  },
  /**
   * @description 根据id删除单条信息
   * @param {*} data
   * @returns
   */
  deleteRevenueData(id) {
    let data = { id: id }
    return request.post('/revenue_datum/delete', data)
  },

  /**
   * @description 记录操作日志
   * @param data
   * @returns {Promise}
   */
  getRevenueDataLog(data) {
    return request.post('/revenue_datum/log', data)
  },

  /**
   * 榜单数据
   * @param data
   * @returns {Promise}
   */
  getRevenueDatumRankData(data) {
    return request.post('/revenue_datum/rank_data', data)
  },

  /**
   * 导出
   * @param data
   * @returns {Promise}
   */
  downloadRevenueDatum(data) {
    return request.postBlob('/revenue_datum/download', data)
  },
  /**
   * 订单信息汇总(广告系统)
   * @param data
   * @returns {Promise}
   */
  getRevenueOrderStatistics(data) {
    return request.post('/revenue_datum/order_statistics', data)
  },
  getRevenueOrderStatisticsV2(data) {
    return request.post('/revenue_datum/order_statistics_v2', data)
  },
  //星广汇红人
  getAdKol(data) {
    return request.post('/revenue_datum/get_ad_kol', data)
  },
  /**
   * 订单信息汇总(广告系统)
   * @param data
   * @returns {Promise}
   */
  getRevenueOrderDetails(data) {
    return request.post('/revenue_datum/order_details', data)
  },

  /**
   * 孵化分析列表
   * @param data
   * @returns {Promise}
   */
  getArtistGrowthList(data) {
    return request.post('/kol_growth/list', data)
  },

  /**
   * 孵化分析列表
   * @param data
   * @returns {Promise}
   */
  getArtistGrowthExport(data) {
    return request.postBlob('/kol_growth/list', data)
  }
//
}
