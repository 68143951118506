import * as request from '@/utils/request'

export default {
  /**
   * @description 获取团队在职成员一览:分页
   * @param {*} data
   * @returns
   */
  getTeamMemberList(data) {
    return request.post('/team_member/list', data)
  },
  /**
   * @description 获取团队在职成员一览
   * @param {*} data
   * @returns
   */
  getTeamMemberOnJobMembers(data) {
    return request.post('/team_member/onJobMembers', data)
  },
  /**
   * 调离成员一览
   * @param data
   * @returns {Promise}
   */
  getTeamMemberRemovalMembers(data) {
    return request.post('/team_member/removalMembers', data)
  },
  /**
   * 从钉钉同步用户信息
   * @param data
   * @returns {Promise}
   */
  syncTeamMember(data) {
    return request.post('/team_member/sync', data)
  },
  /**
   * 团队人效一览数据
   * @param data
   * @returns {Promise}
   */
  getTeamMemberEfficiencyData(data) {
    return request.post('/team_member/efficiency_data', data)
  },
  /**
   * 批量入职
   * @param data
   * @returns {Promise}
   */
  entryTeamMemberUser(data) {
    return request.post('/team_member/batch_store', data)
  },
  /**
   * 获取团队离职人员列表
   * @param data
   * @returns {Promise}
   */
  leaveTeamMemberUserList(data) {
    return request.post('/team_member/leave_list', data)
  },
  /**
   * 批量离职
   * @param data
   * @returns {Promise}
   */

  teamMemberBatchLeave(data) {
    return request.post('/team_member/batch_leave', data)
  },
  /**
   * 删除用户
   * @param data
   * @returns {Promise}
   */

  departTeamDelUser(data) {
    return request.post('/team_member/batch_delete', data)

  },
  /**
   * 同步用户数据
   * @param data
   * @returns {Promise}
   */

  departTeamRefreshUser(data) {
    return request.post('/team_member/store', data)
  },
  /**
   * 批量转组
   * @param data
   * @returns {Promise}
   */

  departTeamTransferUser(data) {
    return request.post('team_member/batch_transfer', data)

  },
  /**
   * 团队在职情况详情-指定月份
   * @param params
   * @returns {Promise}
   */
  getTeamMemberViewDetail(params) {

    return request.post('team_member/view_detail', {
      year: params['year'],
      month: params['month'],
      dept_id: params['dept_id']
    })
  }

}
