import moment from 'moment'

export function fixedZero(val) {
    return val * 1 < 10 ? `0${val}` : val
}

// 日期格式化
export function parseTime(time, pattern) {
    if (arguments.length === 0 || !time) {
        return null
    }
    const format = pattern || '{y}-{m}-{d} {h}:{i}:{s}'
    let date
    if (typeof time === 'object') {
        date = time
    } else {
        if ((typeof time === 'string') && (/^[0-9]+$/.test(time))) {
            time = parseInt(time)
        } else if (typeof time === 'string') {
            time = time.replace(new RegExp(/-/gm), '/')
        }
        if ((typeof time === 'number') && (time.toString().length === 10)) {
            time = time * 1000
        }
        date = new Date(time)
    }
    const formatObj = {
        y: date.getFullYear(),
        m: date.getMonth() + 1,
        d: date.getDate(),
        h: date.getHours(),
        i: date.getMinutes(),
        s: date.getSeconds(),
        a: date.getDay()
    }
    const time_str = format.replace(/{(y|m|d|h|i|s|a)+}/g, (result, key) => {
        let value = formatObj[key]
        // Note: getDay() returns 0 on Sunday
        if (key === 'a') {
            return ['日', '一', '二', '三', '四', '五', '六'][value]
        }
        if (result.length > 0 && value < 10) {
            value = '0' + value
        }
        return value || 0
    })
    return time_str
}

export function getTimeDistance(type) {
    const now = new Date()
    const oneDay = 1000 * 60 * 60 * 24

    if (type === 'today') {
        now.setHours(0)
        now.setMinutes(0)
        now.setSeconds(0)
        return [moment(now), moment(now.getTime() + (oneDay - 1000))]
    }

    if (type === 'week') {
        let day = now.getDay()
        now.setHours(0)
        now.setMinutes(0)
        now.setSeconds(0)

        if (day === 0) {
            day = 6
        } else {
            day -= 1
        }

        const beginTime = now.getTime() - day * oneDay

        return [moment(beginTime), moment(beginTime + (7 * oneDay - 1000))]
    }

    if (type === 'month') {
        const year = now.getFullYear()
        const month = now.getMonth()
        const nextDate = moment(now).add(1, 'months')
        const nextYear = nextDate.year()
        const nextMonth = nextDate.month()

        return [
            moment(`${year}-${fixedZero(month + 1)}-01 00:00:00`),
            moment(moment(`${nextYear}-${fixedZero(nextMonth + 1)}-01 00:00:00`).valueOf() - 1000)
        ]
    }

    if (type === 'year') {
        const year = now.getFullYear()

        return [moment(`${year}-01-01 00:00:00`), moment(`${year}-12-31 23:59:59`)]
    }
}

export function getPlainNode(nodeList, parentPath = '') {
    const arr = []
    nodeList.forEach(node => {
        const item = node
        item.path = `${parentPath}/${item.path || ''}`.replace(/\/+/g, '/')
        item.exact = true
        if (item.children && !item.component) {
            arr.push(...getPlainNode(item.children, item.path))
        } else {
            if (item.children && item.component) {
                item.exact = false
            }
            arr.push(item)
        }
    })
    return arr
}

/**
 * @description 将数值金额转换成大写金额
 * @date 2019-01-29
 * @export
 * @param {*} n 数值金额
 * @returns string
 */
export function digitUppercase(n) {
    const fraction = ['角', '分']
    const digit = ['零', '壹', '贰', '叁', '肆', '伍', '陆', '柒', '捌', '玖']
    const unit = [['元', '万', '亿'], ['', '拾', '佰', '仟']]
    let num = Math.abs(n)
    let s = ''
    fraction.forEach((item, index) => {
        s += (digit[Math.floor(num * 10 * 10 ** index) % 10] + item).replace(/零./, '')
    })
    s = s || '整'
    num = Math.floor(num)
    for (let i = 0; i < unit[0].length && num > 0; i += 1) {
        let p = ''
        for (let j = 0; j < unit[1].length && num > 0; j += 1) {
            p = digit[num % 10] + unit[1][j] + p
            num = Math.floor(num / 10)
        }
        s = p.replace(/(零.)*零$/, '').replace(/^$/, '零') + unit[0][i] + s
    }

    return s
        .replace(/(零.)*零元/, '元')
        .replace(/(零.)+/g, '零')
        .replace(/^整$/, '零元整')
}

/**
 * @description 判断两个字符串关系
 * @date 2019-01-29
 * @param {*} str1
 * @param {*} str2
 * @returns number
 */
function getRelation(str1, str2) {
    if (str1 === str2) {
        console.warn('Two path are equal!') // eslint-disable-line
    }
    const arr1 = str1.split('/')
    const arr2 = str2.split('/')
    if (arr2.every((item, index) => item === arr1[index])) {
        return 1
    } else if (arr1.every((item, index) => item === arr2[index])) {
        return 2
    }
    return 3
}

function getRenderArr(routes) {
    let renderArr = []
    renderArr.push(routes[0])
    for (let i = 1; i < routes.length; i += 1) {
        let isAdd = false
        // 是否包含
        isAdd = renderArr.every(item => getRelation(item, routes[i]) === 3)
        // 去重
        renderArr = renderArr.filter(item => getRelation(item, routes[i]) !== 1)
        if (isAdd) {
            renderArr.push(routes[i])
        }
    }
    return renderArr
}

/**
 * Get router routing configuration
 * { path:{name,...param}}=>Array<{name,path ...param}>
 * @param {string} path
 * @param {routerData} routerData
 */
export function getRoutes(path, routerData) {
    let routes = Object.keys(routerData).filter(
        routePath => routePath.indexOf(path) === 0 && routePath !== path
    )
    // Replace path to '' eg. path='user' /user/name => name
    routes = routes.map(item => item.replace(path, ''))
    // Get the route to be rendered to remove the deep rendering
    const renderArr = getRenderArr(routes)
    // Conversion and stitching parameters
    const renderRoutes = renderArr.map(item => {
        const exact = !routes.some(route => route !== item && getRelation(route, item) === 1)
        return {
            exact,
            ...routerData[`${path}${item}`],
            key: `${path}${item}`,
            path: `${path}${item}`
        }
    })
    return renderRoutes
}

/**
 * 数字格式化
 * @param number
 * @param decimals
 * @param decPoint
 * @param thousandsSep
 * @param roundtag
 * @returns {string}
 */
export function numberFormat(number, decimals, decPoint, thousandsSep, roundtag) {
    /*
    * 参数说明：
    * number：要格式化的数字
    * decimals：保留几位小数
    * dec_point：小数点符号
    * thousands_sep：千分位符号
    * roundtag:舍入参数，默认 'ceil' 向上取,'floor'向下取,'round' 四舍五入
    * */
    number = (number + '').replace(/[^0-9+-Ee.]/g, '')
    roundtag = roundtag || 'ceil' // 'ceil','floor','round'
    var n = !isFinite(+number) ? 0 : +number
    var prec = !isFinite(+decimals) ? 0 : Math.abs(decimals)
    var sep = (typeof thousandsSep === 'undefined') ? ',' : thousandsSep
    var dec = (typeof decPoint === 'undefined') ? '.' : decPoint
    var s = ''
    var toFixedFix = function(n, prec) {
        var k = Math.pow(10, prec)
        return '' + parseFloat(Math[roundtag](parseFloat((n * k).toFixed(prec * 2))).toFixed(prec * 2)) / k
    }
    s = (prec ? toFixedFix(n, prec) : '' + Math.round(n)).split('.')
    var re = /(-?\d+)(\d{3})/
    while (re.test(s[0])) {
        s[0] = s[0].replace(re, '$1' + sep + '$2')
    }

    if ((s[1] || '').length < prec) {
        s[1] = s[1] || ''
        s[1] += new Array(prec - s[1].length + 1).join('0')
    }
    return s.join(dec)
}

/**
 * 获取月份天数
 * @param year
 * @param month
 * @returns {number}
 */
export function getMonthDays(year, month) {
    let startDate = new Date(year, month - 1, 1),
        endData = new Date(year, month, 1)
    let days = (endData - startDate) / (1000 * 60 * 60 * 24)
    return days
}

/* eslint no-useless-escape:0 */
const reg = /(((^https?:(?:\/\/)?)(?:[-;:&=\+\$,\w]+@)?[A-Za-z0-9.-]+|(?:www.|[-;:&=\+\$,\w]+@)[A-Za-z0-9.-]+)((?:\/[\+~%\/.\w-_]*)?\??(?:[-\+=&;%@.\w_]*)#?(?:[\w]*))?)$/g

// 验证url是否正确
export function isUrl(path) {
    return reg.test(path)
}

// 正则规则整理
export const regular = {
    // 验证自然数
    naturalNumber: /^(([0-9]*[1-9][0-9]*)|(0+))$/,
    // 英文
    english: /^.[A-Za-z]+$/,
    // 验证是否是座机号
    telephone: /^\d{3}-\d{7,8}|\d{4}-\d{7,8}$/,
    // 手机号
    mobile: /^1[123456789]\d{9}$/,
    // 银行卡号码
    bankCard: /^[1-9]\d{9,19}$/,
    // 证件号码
    IDNumber: /^[a-z0-9A-Z]{0,50}$/,
    // 身份证号码,包括15位和18位的
    IDCard: /(^[1-9]\d{5}(18|19|([23]\d))\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$)|(^[1-9]\d{7}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{3}$)/,
    // QQ号码
    qq: /^[1-9]\d{4,11}$/,
    // 网址, 仅支持http和https开头的
    url: /^(http|https):\/\/[\w\-_]+(\.[\w\-_]+)+([\w\-.,@?^=%&:/~+#]*[\w\-@?^=%&/~+#])?$/,
    // 0到20位的英文字符和数字
    enNum0to20: /^[a-z0-9A-Z]{0,20}$/,
    // 3到20位的英文字符和数字
    enNum3to20: /^[a-z0-9A-Z]{3,20}$/,
    // 2到100位的中英文字符和空格，可以是中文、英文、空格
    cnEnSpace2to100: /^[a-zA-Z\u4E00-\u9FA5\s*]{2,100}$/,
    // 数字和换行符
    numLinefeed: /^[0-9\n*]+$/,
    // 255位以内的字符
    char0to255: /^.{0,255}$/
}

/**
 * 将一个数组分割成多个数组，其中每个数组的单元数目由 length 决定
 * @param array
 * @param size
 * @returns {*[]}
 */
export function array_chunk(array, size) {
    let [start, end, result] = [null, null, []]
    for (let i = 0; i < Math.ceil(array.length / size); i++) {
        start = i * size
        end = start + size
        result.push(array.slice(start, end))
    }
    return result
}

function getDates(d, t = 0) {
    const curDate = new Date(d.replace(/-/g, '/'))
    const curMonth = curDate.getMonth()
    curDate.setMonth(curMonth + t + 1)
    curDate.setDate(0)
    return new Array(curDate.getDate()).fill(0).map((item, key) => {
        return key + 1
    })
}

function getWeek(d) {
    let curDate = new Date(d.replace(/-/g, '/'))
    // curDate.setDate(1)
    return curDate.getDay()
}

/**
 * 根据给到的日期，计算出当前日历的范围
 * @param d 日期：2022-06-01
 * @returns {*[]}
 */
export function calcCalendarDates(d) {
    let curDates = getDates(d)
    let preDates = getDates(d, -1)
    let nexDates = getDates(d, 1)
    const curWeek = getWeek(d)
    let year = /(^\d{4})-(\d{1,2})-/.exec(d)[1]
    let month = /(^\d{4})-(\d{1,2})-/.exec(d)[2]
    let preMonth = month - 1
    let nextMonth = month + 1
    let preYear = year
    let nextYear = year
    if (Number(month) === 12) {
        nextMonth = 1
        nextYear = nextYear + 1
    } else if (Number(month) === 1) {
        preMonth = 12
        preYear = preYear - 1
    }

    // eslint-disable-next-line
    curDates = curDates.map((i) => {
        return {
            num: i,
            fullDate: /(^\d{4})-(\d{1,2})-/.exec(d)[0] + i,
            show: 1,
            class_name: 'current',
            week: getWeek(/(^\d{4})-(\d{1,2})-/.exec(d)[0] + i)
        }
    })
    // let index = []

    preDates = preDates.map(i => {
        return {
            num: i,
            show: 0,
            class_name: 'prev',
            week: getWeek(preYear + '-' + preMonth + '-' + i)

        }
    })

    nexDates = nexDates.map(i => {
        return {
            num: i,
            show: 0,
            class_name: 'next',
            week: getWeek(nextYear + '-' + nextMonth + '-' + String(i))
        }
    })
    const preCurDates = preDates.slice(preDates.length - (curWeek === 0 ? 6 : curWeek - 1), preDates.length).concat(curDates)

    return preCurDates.concat(nexDates.slice(0, 42 - preCurDates.length))
}


//加密电话号码
export function encryptMobile(phone) {
    phone = '' + phone
    let reg = /(\d{3})\d{4}(\d{4})/ //正则表达式

    return phone.replace(reg, '$1****$2')
}

//获取当前季度
export function getCurrentQuarter() {
    // 获取当前季度：
    let currMonth = new Date().getMonth() + 1
    return Math.floor((currMonth % 3 === 0 ? (currMonth / 3) : (currMonth / 3 + 1)))
}

//获取当前年月
export function getCurrentMonth() {
    // 获取当前季度：
    const date = new Date()
    let currYear = date.getFullYear()
    let currMonth = date.getMonth() + 1
    return currYear + '-' + currMonth
}

//获取上个月的年月
export function getLastMonth() {
    let date = new Date()
    //分页页面-默认上月
    date.setMonth(date.getMonth() - 1)
    let year = date.getFullYear() + ''
    let month = date.getMonth() + 1
    return year + '-' + month
}

// 获取WS地址
export function getWsUrl(path) {
    let url = ''
    if (process.env.NODE_ENV === 'development') {
        url = `${process.env.VUE_APP_WS_URL}${path}`
    } else {
        const wsProtocol = window.location.protocol === 'http:' ? 'ws:' : 'wss:'
        const hostname = window.location.hostname
        url = `${wsProtocol}//${hostname}${process.env.VUE_APP_WS_URL}${path}`
    }
    return url
}

// 计算季度奖金包
export function calcBonusQuarter(total_profit) {
    let bonusQuarter = 0
    // 计算一季度奖金包
    if (total_profit <= 0) {
        bonusQuarter = 5000

    } else if (total_profit <= 900000) {
        bonusQuarter = 20000
    } else if (total_profit <= 1500000) {
        bonusQuarter = 30000
    } else if (total_profit <= 2400000) {
        bonusQuarter = 40000
    } else if (total_profit <= 3300000) {
        bonusQuarter = 60000
    } else {
        bonusQuarter = 80000
    }

    return bonusQuarter
}

// 获取host根域名
export function getRootHost(host) {
    let domainList = host.split('.')
    // domainList.unshift()
    domainList.shift()
    return domainList.join('.')
}

export function isDing() {
    const ua = window.navigator.userAgent
    return ua.indexOf('DingTalk') !== -1 // true or false
}

export function jumpPage(_this, routeName, menu) {
    let currHost = window.location.host
    // let currProtocol = window.location.protocol
    let url = ''
    let page_name = menu.display_name || ''
    if (menu.system_name) {
        //站外链接：
        if (routeName.indexOf('http') > -1) {
            // console.log('current protocol', currProtocol)
            const urlObj = new URL(routeName)
            // currProtocol = urlObj.protocol
            // console.log('jump root host', this.getRootHost(urlObj.host))
            // console.log('current root host', this.getRootHost(currHost))
            if (this.getRootHost(currHost) === this.getRootHost(urlObj.host)) {
                //域名和当前域名（生产环境），直接跳转
                // window.location.href = urlObj.href
                url = urlObj.href
            } else {
                //【域名不同的情况处理】测试环境或本地、站外链接
                const sub_domain = menu.sub_domain ? menu.sub_domain['beta'] : null
                if (sub_domain)
                    url = `${sub_domain}${urlObj.pathname}`
                else
                    url = urlObj.href
                // window.location.href = url
            }
        }
        // console.log('url', url)
        // window.location.href = url
        _this.$confirm(`即将跳转到【${page_name}页面】：${url}, 是否继续?`, '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
        })
            .then(() => {
                // if (this.isDing()) {
                //     menu.is_blank ? window.open(url) : window.location.href = url
                // } else
                window.open(url, menu.target || '_blank')
                // this.$notify.success(`即将跳转到审批页面：${url}`)
                //          window.location.href = url
                // console.log(menu)
                return false
            })
            .catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消跳转'
                })
            })
    } else {
        _this.$router.push({ path: routeName })
    }

}

//字节转换
export function byteToSize(byte) {
    let size = ''
    if (byte > Math.pow(2, 40)) {
        size = numberFormat(byte / Math.pow(2, 40), 2) + ' TB'
    } else if (byte > Math.pow(2, 30)) {
        size = numberFormat(byte / Math.pow(2, 30), 2) + ' GB'
    } else if (byte > Math.pow(2, 20)) {
        size = numberFormat(byte / Math.pow(2, 20), 2) + ' MB'
    } else if (byte > Math.pow(2, 10)) {
        size = numberFormat(byte / Math.pow(2, 10), 2) + ' KB'
    } else {
        size = numberFormat(byte, 2) + ' B'
    }
    return size

}

export function formatterYorN(row, column) {
    if (row[column.property]) {
        return row[column.property] === 'Y' ? '是' : '否'
    } else return ''
}
