/**
 * 自动生成 router代码【模块名：库存记录】
 * 生成日期：2024/04/25 15:40:42
 * 生成路径: src/router/product_qty_record.js
 * 生成人：超级管理员
 * 需要在文件 src/router/router.js 引入当前文件
 * import product_qty_record from './product_qty_record'
 // 路由详情
 const routes = [
 {
 path: '/',
 component: () => import('@/layouts/BasicLayout'),
 name: 'BasicLayout',
 meta: { title: '首页' },
 redirect: '/myNav',
 children: [
 ...
 product_qty_record,//库存记录
 ]
 */

export default  {
  path: 'product_qty_record/list',
  name: 'product_qty_record',
  component: () => import('@/pages/product_qty_record/ProductQtyRecordList'),
  meta: { title: '进销存管理' },
}
