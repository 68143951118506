import * as request from '@/utils/request'

export default {
  /**
   * @description 获取岗位列表
   * @returns promise
   */
  getPostList(data) {
    return request.post('/sys_post/list', data)
  },
  /**
   * 保存岗位
   * @param data
   * @returns {Promise}
   */
  saveSysPost(data) {
    return request.post('/sys_post/store', data)
  },
  /**
   * 删除岗位
   * @returns {Promise}
   * @param id
   */
  deleteSysPost(id) {
    return request.post('/sys_post/delete', { id:id} )
  },
}