import * as request from '@/utils/request'

/**
 * 自动生成 api代码【模块名：角色申请】
 * 生成日期：2024/04/12 19:09:32
 * 生成路径: src/api/role_apply_record.js
 * 生成人：超级管理员
 * 需要在文件src/api/index.js 引入当前文件
 * import role_apply_record from './role_apply_record'
 * export default {
 * ...
 * // 角色申请
 *    ...role_apply_record,
 * }
 */

export default {
  /**
   * @description 获取列表
   * @param {*} data
   * @returns
   */
  getRoleApplyRecordList(data) {
    return request.post('/role_apply_record/list', data)
  },
  /**
   * @description 保存
   * @param {*} data
   * @returns
   */
  saveRoleApplyRecord(data) {
    return request.post('/role_apply_record/store', data)
  },
  /**
   * @description 获取详情
   * @param {*} id
   * @returns
   */
  getRoleApplyRecordInfo(id) {
    let data = { id: id }
    return request.post('/role_apply_record/get_info', data)
  },
  /**
   * @description 根据id删除单条信息
   * @param {*} data
   * @returns
   */
  deleteRoleApplyRecord(id) {
    let data = { id: id }
    return request.post('/role_apply_record/delete', data)
  },
  /**
   * 导入
   * @param data
   * @returns {Promise}
   */
  importRoleApplyRecords(data) {
    return request.post('/role_apply_record/import', data)
  },
  /**
   * 导出
   * @param data
   * @returns {Promise}
   */
  exportRoleApplyRecords(data) {
    return request.postBlob('/role_apply_record/export', data)
  },
  /**
   * 审批角色申请
   * @param data
   * @returns {Promise}
   */
  approvalRoleApply(data) {
    return request.post('/role_apply_record/approvalApply', data)
  },
  handleRejRoleApply(data) {
    return request.post('/role_apply_record/handleRej', data)
  },
  handlePassRoleApply(data) {
    return request.post('/role_apply_record/handlePass', data)
  }
}
