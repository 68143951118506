/**
 * 自动生成 router代码【模块名：自定义条码】
 * 生成日期：2024/05/07 17:19:01
 * 生成路径: src/router/custom_barcode.js
 * 生成人：超级管理员
 * 需要在文件 src/router/router.js 引入当前文件
 * import custom_barcode from './custom_barcode'
 // 路由详情
 const routes = [
 {
 path: '/',
 component: () => import('@/layouts/BasicLayout'),
 name: 'BasicLayout',
 meta: { title: '首页' },
 redirect: '/myNav',
 children: [
 ...
 custom_barcode,//自定义条码
 ]
 */

export default  {
  path: 'custom_barcode/list',
  name: 'custom_barcode',
  component: () => import('@/pages/custom_barcode/CustomBarcodeList'),
  meta: { title: '条码管理' },
}
