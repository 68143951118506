import * as request from '@/utils/request'

/**
 * 自动生成 api代码【模块名：自定义条码】
 * 生成日期：2024/05/07 17:24:29
 * 生成路径: src/api/custom_barcode.js
 * 生成人：超级管理员
 * 需要在文件src/api/index.js 引入当前文件
 * import custom_barcode from './custom_barcode'
 * export default {
 * ...
 * // 自定义条码
 *    ...custom_barcode,
 * }
 */

export default {
  /**
   * @description 获取列表
   * @param {*} data
   * @returns
   */
  getCustomBarcodeList(data) {
    return request.post('/custom_barcode/list', data)
  },
  /**
   * @description 保存
   * @param {*} data
   * @returns
   */
  saveCustomBarcode(data) {
    return request.post('/custom_barcode/store', data)
  },
  /**
   * @description 获取详情
   * @param {*} id
   * @returns
   */
  getCustomBarcodeInfo(id) {
    let data = { id: id }
    return request.post('/custom_barcode/get_info', data)
  },
  /**
   * @description 获取详情
   * @param  where
   * @returns
   */
  queryUserSettingInfo(where) {
    return request.post('/user_setting/query', where)
  },
  /**
   * @description 根据id删除单条信息
   * @param {*} data
   * @returns
   */
  deleteCustomBarcode(id) {
    let data = { id: id }
    return request.post('/custom_barcode/delete', data)
  },
  /**
   * 预览批量新增
   * @param data
   * @returns {Promise}
   */
  previewBarcodeBatchAdd(data) {
    return request.post('/custom_barcode/previewBatchAdd', data)
  },
}