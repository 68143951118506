import * as request from '@/utils/request'

export default {

  /**
   * 必填筛选条件
   * @description 获取直播排期数据列表
   * @param {*} data
   * @returns
   */
  getLbScheduleList(data) {
    return request.post('/lb_schedule/list', data)
  },
  /**
   * 回收站
   * @param data
   * @returns {Promise}
   */
  getLbScheduleRecycle(data) {
    return request.post('/lb_schedule/recycle', data)
  },
  /**
   * 恢复已删除排期
   * @param data
   * @returns {Promise}
   */
  restoreLbSchedule(data) {
    return request.post('/lb_schedule/restore', data)
  },
  /**
   * 直播排期的产品手卡
   * @returns {Promise}
   * @param params
   */
  exportLbProductPpt(params) {

    return request.postBlob('/lb_schedule/export_ppt', params)
  },
  /**
   * 人员日常统计
   * @param data
   * @returns {Promise}
   */
  getLbScheduleOptStatistics(data) {
    return request.post('/lb_schedule/opt_statistics', data)
  },
  /**
   * 人员日常统计-导出
   * @param data
   * @returns {Promise}
   */
  exportLbScheduleOptStatistics(data) {
    return request.postBlob('/lb_schedule/export_opt_statistics', data)
  },
  /**
   * 直播排期统计
   * @param data
   * @returns {Promise}
   */
  getLbScheduleStatistics(data) {
    return request.post('/lb_schedule/lb_statistics', data)
  },
  /**
   * 直播排期统计-导出
   * @param data
   * @returns {Promise}
   */
  exportLbScheduleStatistics(data) {
    return request.postBlob('/lb_schedule/export_lb_statistics', data)
  },

  /**
   * 排期日志
   * @param data
   * @returns {Promise}
   */
  getLbScheduleLog(data) {
    return request.post('/lb_schedule/log', data)
  },
  getLbScheduleLogDetail(id) {
    return request.post('/lb_schedule/log_detail', { id: id })
  },
  /**
   * 排期整月数据
   * @param data
   * @returns {Promise}
   */
  getLbScheduleMonthList(data) {
    return request.post('/lb_schedule/month_list', data)
  },

  /**
   * 保存
   * @description 保存直播排期数据
   * @param {*} data
   * @returns
   */
  saveLbSchedule(data) {
    return request.post('/lb_schedule/store', data)
  },

  /**
   * 获取排期详情
   * @param {*} id
   * @returns {Promise}
   */
  getLbScheduleInfo(id) {
    let data = { id: id }
    return request.post('/lb_schedule/get_info', data)
  },

  /**
   * 删除排期
   * @param id
   * @returns {Promise}
   */
  delLbSchedule(id) {
    let data = { id: id }
    return request.post('/lb_schedule/delete', data)
  },
  /**
   * 发起需求
   * @param id
   * @returns {Promise}
   */
  launchLbSchedule(id) {
    let data = { id: id }
    return request.post('/lb_schedule/launch', data)
  },
  /**
   * 发送消息通知
   * @param notifyUsers
   * @param id
   * @returns {Promise}
   */
  sendNotifyLbSchedule(notifyUsers, id) {
    let data = { notify_users: notifyUsers, id: id }
    return request.post('/lb_schedule/send_notify', data)
  },
  /**
   *
   * @returns {Promise}
   */
  getLbScheduleNotifyUser() {
    let data = {}
    return request.post('/lb_schedule/get_notify_users', data)
  },
  /**
   * 初选商品
   * @param id
   * @returns {Promise}
   */
  originalLbSchedule(id) {
    let data = { id: id }
    return request.post('/lb_schedule/original', data)
  },
  /**
   * 标记初选选品为“确认选品”
   * @returns {Promise}
   * @param ids
   */
  tagLbScheduleProducts(data) {
    return request.post('/lb_schedule/tag', data)
  },
  cancelTagLbScheduleProducts(data) {

    return request.post('/lb_schedule/cancel_tag', data)
  },
  /**
   * 未选上及其原因
   * @param ids
   * @param cancel_reason
   * @returns {Promise}
   */
  cancelSelectionScheduleProducts(data) {
    // let data = { ids: ids, cancel_reason: cancel_reason }
    return request.post('/lb_schedule/cancel_selection', data)
  },
  /**
   * 机制确认
   * @param ids
   * @param cancel_reason
   * @returns {Promise}
   */
  changeConfirmScheduleProducts(ids, confirm_status) {
    let data = { ids: ids, confirm_status: confirm_status }
    return request.post('/lb_schedule/change_confirm', data)
  },

  /**
   * 确认选品
   * @param id
   * @returns {Promise}
   */
  ackLbSchedule(id) {
    let data = { id: id }
    return request.post('/lb_schedule/ack', data)
  },
  /**
   * 锁定表格
   * @param id
   * @returns {Promise}
   */
  lockLbSchedule(id) {
    let data = { id: id }
    return request.post('/lb_schedule/lock', data)
  },
  /**
   * 获取排期对应的选品
   * @param data
   * @returns {Promise}
   */
  // getLbScheduleProducts(data) {
  //
  //   return request.post('/lb_schedule/get_products', data)
  // },
  /**
   * 获取已确认的选品
   * @returns {Promise}
   */
  getLbScheduleAckProducts(data) {
    return request.post('/lb_schedule/get_products', data)
  },
  /**
   * 红人选品会-查询
   * @param data
   * @returns {Promise}
   */
  getLbScheduleKolSelection(data) {
    return request.post('/lb_schedule/get_kol_selection', data)
  },
  /**
   * 选品列表
   * @param data
   * @returns {Promise}
   */
  getLbScheduleProductList(data) {
    return request.post('/lb_schedule/lb_products_list', data)
  },
  /**
   * 选品交接
   * @param data
   * @returns {Promise}
   */
  handOverLbScheduleProducts(data) {
    return request.post('/lb_schedule/handover_lb_products', data)
  },

  /**
   * 保存选品
   * @param data
   * @returns {Promise}
   */
  saveLbScheduleProducts(data) {

    return request.post('/lb_schedule/save_products', data)
  },
  /**
   * 指定字段修改选品信息
   * @param id
   * @param field
   * @param val
   * @returns {Promise}
   */
  singleSaveLbScheduleProduct(id, field, val) {
    let data = { id: id, field: field, val: val }
    return request.post('/lb_schedule/single_save_product', data)
  },
  /**
   * 获取指定选品的信息
   * @param id
   * @returns {Promise}
   */
  getLbScheduleProductInfo(id) {
    let data = { id: id }
    return request.post('/lb_schedule/get_lb_product_info', data)
  },
  /**
   * 修改产品机制（招商）
   * @param data
   * @returns {Promise}
   */
  saveLbScheduleProductInfo(data) {
    return request.post('/lb_schedule/save_product', data)
  },
  /**
   * 追加直播选品
   * @param data
   * @returns {Promise}
   */
  appendLbScheduleProducts(data) {

    return request.post('/lb_schedule/append_products', data)
  },
  /**
   * 查询待选品（排查已选）
   * @param data
   * @returns {Promise}
   */
  getProductListExclude(data) {
    return request.post('/lb_schedule/get_products_await', data)
  },
  /**
   * 删除直播选品
   * @param id
   * @returns {*}
   */
  delLbScheduleProducts(id) {
    return request.post('/lb_schedule/del_lb_products', { id: id })
  },

  /**
   * 检验是否30内重复
   * @param data
   * @returns {Promise}
   */
  saveLbScheduleProductsCheckRepeat(data) {

    return request.post('/lb_schedule/check_repeat', data)
  },

  /**
   * 导出
   * @param data
   * @returns {Promise}
   */
  exportLbScheduleSelection(data) {
    return request.postBlob('/lb_schedule/export_selection', data)
  },


  /**
   * 排期操作行为通知
   * @param data
   * @returns {Promise}
   */
  dingTalkNotifyLbScheduleAction(data) {
    return request.post('/lb_schedule/notify', data)
  },
  /**
   * 排期任务查询
   * @param data
   * @returns {Promise}
   */
  dingTalkNotifyLbScheduleTaskList(data) {
    return request.post('/lb_schedule/task_list', data)
  },
  /**
   * 工作排期列表
   */
  getTaskScheduleList(data) {
    return request.post('/work_schedule/list', data)
  },
  /**
   * 新增工作排期
   */
  addTaskSchedule(data) {
    return request.post('/work_schedule/create', data)
  },
  /**
   * 修改工作排期
   */
  updateTaskSchedule(data) {
    return request.post('/work_schedule/update', data)
  },
  /**
   * 删除工作排期
   */
  deleteTaskSchedule(data) {
    return request.post('/work_schedule/delete', data)
  },
  /**
   * 导出工作排期
   */
  exportTaskSchedule(data) {
    return request.postBlob('/work_schedule/export', data)
  },
  /**
   * 获取部门列表
   */
  getTaskdept(data) {
    return request.post('/work_schedule/dept', data)
  },
  //H获取红人了组别列表/artist/group_artists
  /**
   * 获取部门列表
   */
  getTaskdeptArtists(data) {
    return request.post('/artist/group_artists', data)
  },
  /**
   *
   * @param data
   * @returns {Promise}
   */
  exportLbScheduleDemand(data) {
    return request.postBlob('/lb_demand/export?t=20231030', data)
  },
  /**
   * 需求明细导入记录
   */
  getLbScheduleDemandList(data) {
    return request.post('/lb_demand/list', data)
  },
  /**
   * 查询-初选选品负责人下拉
   */
  getLbLeaderLdOptions(data) {
    return request.post('/lb_schedule/get_leader_options', data)
  },
  /**
   * 保存-确认选品排序
   */
  saveScheduleOrder(data) {
    return request.post('/lb_schedule/order', data)
  },
  //需求明细CURD
  saveLbScheduleDemandItem(data) {
    return request.post('/lb_schedule/save_demand_item', data)
  },
  getLbScheduleDemandItemList(data) {
    return request.post('/lb_schedule/list_demand_item', data)
  },
  /**
   *
   * @param id
   * @returns {Promise}
   */
  getLbScheduleDemandItemInfo(id) {
    let data = { id: id }
    return request.post('/lb_schedule/info_demand_item', data)
  },
  //单元格数据保存
  cellSaveLbScheduleDemandItemInfo(data) {
    return request.post('/lb_schedule/single_save_demand', data)
  },
  // commitLbScheduleDemandItemFeedback(id, feedback) {
  //   let data = { id: id, feedback: feedback }
  //   return request.post('/lb_schedule/commit_feedback', data)
  // },
  /**
   *
   * @param id
   * @returns {Promise}
   */
  delLbScheduleDemandItem(id) {
    let data = { id: id }
    return request.post('/lb_schedule/del_demand_item', data)
  },
  /**
   *批量删除
   * @param ids
   * @param lb_id
   * @returns {Promise}
   */
  delBatchLbScheduleDemandItem(ids, lb_id) {
    let data = { ids: ids,lb_id: lb_id }
    return request.post('/lb_schedule/del_batch_demand_item', data)
  },
  /**
   * 导出红人选品会明细
   * @param data
   * @returns {Promise}
   */
  exportLbScheduleDemandItem(data) {
    return request.postBlob('/lb_schedule/export_demand_item', data)
  },
  /**
   * 修改选品列表上播状态
   * @param data
   * @returns {Promise}
   */
  updataKolSelectionState(data) {
    return request.post('/lb_schedule/update_state', data)
  }


}
