export default {
    path: 'sign',
    name: 'sign',
    component: () => import('@/pages/sign_info/Index'),
    meta: { title: '签约管理' },
    children: [
        {
            path: 'info',
            name: 'sign_info',
            component: () => import('@/pages/sign_info/SignList'),
            meta: { title: '签约信息' },
        },
        {
            path: 'create',
            name: 'sign_create',
            component: () => import('@/pages/sign_info/CreateEdit'),
            meta: { title: '签约录入' },
        },
        {
            path: ':sign_id/detail',
            name: 'sign_detail',
            component: () => import('@/pages/sign_info/Detail'),
            meta: { title: '签约详情' },
        },
        {
            path: ':sign_id/edit',
            name: 'sign_edit',
            component: () => import('@/pages/sign_info/CreateEdit'),
            meta: { title: '签约编辑' },
        },
        {
            path: 'transfer_group',
            name: 'transfer_group',
            component: () => import('@/pages/sign_info/TransferGroup'),
            meta: { title: '红人转组' },
        },
        {
            path: 'sign_fired',
            name: 'sign_fired',
            component: () => import('@/pages/sign_info/SignFired'),
            meta: { title: '红人解约' },
        },

        {
            path: 'sign_overview',
            name: 'sign_overview',
            component: () => import('@/pages/sign_info/SignOverview'),
            meta: { title: '签约一览' },
        },
    ]
}
