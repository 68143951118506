import * as request from '@/utils/request'

/**
 * 总监绩效
 */
export default {

    /**
     * @description 初始化
     * @param {*} data
     * @returns
     */
    initPerfDeptInfo(data) {
        return request.post('/perf_dept_info/init', data)
    },
    /**
     * @description 部门半年度数据
     * @param data
     * @returns {Promise}
     */
    getPerfDeptInfoHalfYearData(data) {
        return request.post('/perf_dept_info/half_year_data', data)
    },
    /**
     * @description 部门半年度数据-操作日志
     * @param data
     * @returns {Promise}
     */
    getPerfDeptInfoLog(data) {
        return request.post('/perf_dept_info/log', data)
    },
    /**
     * @description 导出- 部门半年度数据
     * @param data
     * @returns {Promise}
     */
    exportPerfDeptInfoHalfYearData(data) {
        return request.postBlob('/perf_dept_info/export', data)
    },

    /**
     * @description 保存部门半年度数据
     * @param data
     * @returns {Promise}
     */
    savePerfDeptInfo(data) {
        return request.post('/perf_dept_info/store', data)
    },
    /**
     * @description 获取一条部门半年度数据
     * @param id
     * @returns {Promise}
     */
    getPerfDeptInfo(id) {
        let data = { id: id }
        return request.post('/perf_dept_info/get_info', data)
    },
    /**
     * @description 删除部门半年度数据
     * @param id
     * @returns {Promise}
     */
    delPerfDeptInfo(id) {
        let data = { id: id }
        return request.post('/perf_dept_info/delete', data)
    },
    /**
     * @description 保存部门半年度-绩效补充说明
     * @param data
     * @returns {Promise}
     */
    savePerfDeptNote(data) {
        return request.post('/perf_dept_info/save_note', data)
    },
    /**
     * @description 获取一条部门半年度-绩效补充说明
     * @param data
     * @returns {Promise}
     */
    getPerfDeptNote(data) {
        return request.post('/perf_dept_info/get_note', data)
    },
}
