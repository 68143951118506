<template>
  <!--  <keep-alive>-->
  <router-view />
  <!--  </keep-alive>-->

  <!--  <keep-alive :include="cachedViews">-->
  <!--    <router-view :key="key" />-->
  <!--  </keep-alive>-->
</template>
<script>

export default {
  name: 'App',
  // computed: {
  //   cachedViews() {
  //     return this.$store.state.tagsView.cachedViews
  //   },
  //   key() {
  //     return this.$route.path
  //   }
  // },
  data() {
    return {
      active: 'home',
      wsMessage: null,//消息即时通讯
      msgList: [],//消息列表
      msg: false
    }
  },
  components: {},
  // watch: {
  //   cachedViews(val){
  //     console.log('cachedViews',val)
  //   }
  // },
  created() {
    // this.connectionMessage()
  },
  methods: {}

}
</script>

<style lang="scss">

body {
  margin: 0;
  padding: 0;
  font-family: "Helvetica Neue", Helvetica, "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "微软雅黑", Arial, sans-serif;
  font-size: 14px;
  line-height: 1.15;
  color: #303133;
  background-color: #f0f2f5;
  overflow-x: hidden;
}

.el-button--medium {
    padding: 8px 10px;
}

.el-button--mini {
    padding: 7px 8px;
}

ul, li {
    padding: 0;
    margin: 0;
    list-style: none
}
</style>
