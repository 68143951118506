
export default {
    path: 'logistics',
    component: () => import('@/pages/logistics/Index'),
    meta: { title: '物流信息' },
    children: [
        {
            path: 'company',
            name: 'logistics_company',
            component: () => import('@/pages/logistics/CompanyList.vue'),
            meta: { title: '快递公司管理' }
        },
        {
            path: 'list',
            name: 'logistics_list',
            component: () => import('@/pages/logistics/Logistics.vue'),
            meta: { title: '物流信息管理' }
        }
    ]
}
