import * as request from '@/utils/request'

export default {
    /**
     * @description 北森自己报表数据
     * @param {*} data
     * @returns
     */
    getBeiSenReportList(data) {
        return request.post('/beiSen_report/list', data)
    },
    /**
     * 保存数据
     * @param data
     * @returns {Promise}
     */
    saveBeiSenReportData(data) {
        return request.post('/beiSen_report/store', data)
    },
    getBeiSenReportOption(data) {
        return request.post('/beiSen_report/option', data)
    },
    /**
     * 报表数据
     * @param id
     * @returns {Promise}
     */
    getBeiSenReportInfo(id) {
        return request.post('/beiSen_report/get_info', { id: id })
    },

    // 获取[招聘报表]基本信息
    getBeiSenReport(data) {
        return request.post('/beiSen_report/get_report', data)
    },
    /**
     *
     * @param data
     * @returns {Promise}
     */
    getBeiSenRecruitReportList(data) {
        return request.post('/beiSen_recruit_report/list', data)
    },
    /**
     * 汇总数据获取
     * @param data
     * @returns {Promise}
     */
    getBeiSenRecruitReportSummaryData(data) {
        return request.post('/beiSen_recruit_report/get_summary_data', data)
    },
    getBeiSenRecruitReportChatData(data) {
        return request.post('/beiSen_recruit_report/get_chart_data', data)
    },
    /**
     * 报表
     * @param data
     * @returns {Promise}
     */
    getBeiSenReportRecordList(data) {
        return request.post('/beiSen_report_record/list', data)
    },
    //获取报表表格表头
    getBeiSenReportGridHeader(id, force) {
        return request.post('/beiSen_report_record/get_grid_header', { id: id, _force: force ? force : 'N' })
    },
    // 获取报表表格数据
    getBeiSenReportGridData(data) {
        return request.post('/beiSen_report_record/get_grid_data', data)
    }
}