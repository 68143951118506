export default {
  path: 'labor_cost',
  component: () => import('@/pages/labor_cost/index'),
  meta: { title: '人工成本' },
  children: [
    {
      path: ':id/labor_cost_import',
      name: 'labor_cost_import',
      component: () => import('@/pages/labor_cost/importTable.vue'),
      meta: { title: '人工成本导入列表' }
    }
  ]
}

