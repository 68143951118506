
export default {
    path: 'oaManage',
    name: 'oaManage',
    component: () => import('@/pages/oaManage/indexList'),
    meta: { title: 'OA审批管理' },
    children: [
        {
            path: ':id/table',
            name: 'oaTable',
            component: () => import('@/pages/oaManage/oaTable.vue'),
            meta: { title: '绩效目标一览' }
        },
        {
            path: 'setting',
            name: 'oaSetting',
            component: () => import('@/pages/oaManage/Setting.vue'),
            meta: { title: '审批人设置' }
        },

    ]
}
